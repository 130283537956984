import React from 'react'
import { Layout,SidebarContainer,MainContent } from '../../../Components/UI/StyledComponents'
import Sidebar from '../../../Components/Center/Sidebar'
import StudyCertificateComponent from '../../../Components/Center/StudyCertificateComponent'
import NavComponent from '../../../Components/Center/NavComponent';

const StudyCertificate = ({isDuplicate}) => {
  return (
    <>
    <NavComponent/>
    <Layout>
    <SidebarContainer>
      <Sidebar/>
    </SidebarContainer>
    <MainContent>
      <StudyCertificateComponent isDuplicate={isDuplicate}/>
    </MainContent>
  </Layout>
  </>
  )
}

export default StudyCertificate
