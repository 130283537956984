import React from 'react'
import Sidebar from "../../../Components/Student/Sidebar";
import ViewResultComponent from '../../../Components/Student/ViewResultComponent';
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";

const ViewResults = () => {
    return (
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <ViewResultComponent />
            </MainContent>
        </Layout>
    )
}

export default ViewResults