import Sidebar from "../../../Components/Staff/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import CertificateBonafideComponent from "../../../Components/Staff/CertificateBonafideComponent";
import NavComponent from "../../../Components/Staff/NavComponent";

const CertificateBonafide = () => {
    return (
        <>
      <NavComponent />
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <CertificateBonafideComponent />
            </MainContent>
        </Layout>
        </>
    )
}

export default CertificateBonafide