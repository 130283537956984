import { Route, Routes } from "react-router-dom";
import Login from "../Components/Common/Login";
import Dashboard from "../Pages/Student/Dashboard/Dashboard";
import Course from "../Pages/Student/Course/Course";
import ChangePassword from "../Pages/Student/Password/ChangePassword";
// import Certificate from "../Pages/Student/Certificate/Certificate";
import StudentHallTicket from "../Pages/Student/HallTicket/StudentHallTicket";
import ViewHallTicket from "../Pages/Student/HallTicket/ViewHallTicket";
import Results from "../Pages/Student/Results/Results";
import ViewResults from "../Pages/Student/Results/ViewResults";
import StudentLoggedIn from "../Components/Student/StudentLoggedIn";
import StudentLoggedOut from "../Components/Student/StudentLoggedOut";
import Error from "../Pages/Student/Error/Error";

const studentRoute = () => {
  return (
    <Routes>
      <Route path="" element={<StudentLoggedOut />}>
        <Route path="/" element={<Login />} />
      </Route>
      <Route path="" element={<StudentLoggedIn />}>
        <Route path="profile" element={<Dashboard />} />
        <Route path="course" element={<Course />} />
        <Route path="changePassword" element={<ChangePassword />} />
        {/* <Route path="certificate" element={<Certificate />} /> */}
        <Route path="hallticket" element={<StudentHallTicket />} />
        <Route path="viewHallticket/:id" element={<ViewHallTicket />} />
        <Route path="results" element={<Results />} />
        <Route path="viewResults/:id" element={<ViewResults />} />
      </Route>
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default studentRoute;
