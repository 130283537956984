import Sidebar from "../../../Components/Staff/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import CertificateStudyComponent from "../../../Components/Staff/CertificateStudyComponent";
import NavComponent from "../../../Components/Staff/NavComponent";

const CertificateStudy = () => {
    return (
        <>
      <NavComponent />
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <CertificateStudyComponent />
            </MainContent>
        </Layout>
        </>
    )
}

export default CertificateStudy