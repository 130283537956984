import Sidebar from "../../../Components/Admin/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import CertificateSSLCComponent from "../../../Components/Admin/CertificateSSLCComponent";
import NavComponent from "../../../Components/Admin/NavComponent";

const CertificateSSLC = () => {
    return (
        <>
            <NavComponent />
            <Layout>
                <SidebarContainer>
                    <Sidebar />
                </SidebarContainer>
                <MainContent>
                    <CertificateSSLCComponent />
                </MainContent>
            </Layout>
        </>
    )
}

export default CertificateSSLC