import React, { useState, useEffect } from "react";
import {
  student,
  centers,
  updateStudentStatus,
  updateStudentRollNumber,
} from "../../Api/admin";
import {
  PageWrapper,
  StyledTable,
  FilterWrapper,
  PageHeader,
  PageTitle,
  PageSubtitle,
  FilterSelect,
  FilterLabel,
  SearchWrapper,
  SearchInput,
  Form,
  TableWrapper,
  NavButton,
  NavWrapper,
  PageIndicator,
  AddButton
} from "../UI/StyledComponents";
import { useNavigate } from "react-router-dom";

const StudentComponent = () => {
  const [students, setStudents] = useState([]);
  const [center, setCenter] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCenter, setSelectedCenter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [rollNumberInput, setRollNumberInput] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const navigate=useNavigate();

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const res = await student();
        if (res.data.success) {
          setStudents(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudentData();
  }, []);

  useEffect(() => {
    const fetchCenterData = async () => {
      try {
        const res = await centers();
        if (res.data.success) {
          setCenter(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCenterData();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredStudents = students.filter((val) => {
    const matchesSearchQuery =
      val.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.roll_number?.toString().includes(searchQuery.toString()) ||
      val.mobile_number.toString().includes(searchQuery.toString());
    const matchesCenter =
      selectedCenter === "" ||
      (val.center_id && val.center_id.toString() === selectedCenter);
    const matchesStatus =
      selectedStatus === "" || val.admission_status === selectedStatus;

    return matchesSearchQuery && matchesCenter && matchesStatus;
  });

  const handleCenterFilter = (centerId) => {
    setSelectedCenter(centerId);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentStudents = filteredStudents.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleStatusChange = async (studentId, newStatus) => {
    try {
      const res = await updateStudentStatus(studentId, newStatus);
      if (res.data.success) {
        setStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.id === studentId
              ? { ...student, admission_status: newStatus }
              : student
          )
        );
      } else {
        console.error(res.data.message);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleAddRollNumber = (studentId) => {
    setSelectedStudentId(studentId);
    setRollNumberInput("");
  };

  const handleRollNumberSubmit = async (studentId) => {
    try {
      if (rollNumberInput) {
        const res = await updateStudentRollNumber(studentId, rollNumberInput);
        if (res.data.success) {
          setStudents((prevStudents) =>
            prevStudents.map((student) =>
              student.id === studentId
                ? { ...student, roll_number: rollNumberInput }
                : student
            )
          );
          setSelectedStudentId(null);
          setRollNumberInput("");
        } else {
          console.error(res.data.message);
        }
      }
    } catch (error) {
      console.error("Error updating roll number:", error);
    }
  };

  const getCenterName = (centerId) => {
    const centerInfo = center.find((c) => c.id === centerId);
    return centerInfo ? centerInfo.center_name : "N/A";
  };

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  const handleViewStudent = async (id) => {
    try {
      navigate(`/admin/viewStudent/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = async () => {
    try {
      navigate("/staff/addStudent");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Students list</PageTitle>
        <PageSubtitle>View all students</PageSubtitle>
      </PageHeader>
      <Form>
        <FilterWrapper>
          <FilterLabel>Filter by Center</FilterLabel>
          <FilterSelect
            id="centerFilter"
            onChange={(e) => handleCenterFilter(e.target.value)}
          >
            <option value="">All</option>
            {center.map((val) => (
              <option key={val.id} value={val.id}>
                {val.center_name}
              </option>
            ))}
          </FilterSelect>
        </FilterWrapper>

        <SearchWrapper>
          <SearchInput
            placeholder="Search by name or roll number"
            required
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchWrapper>

        <AddButton type="button" onClick={handleAdd} className="mr-5">
          Add Student
        </AddButton>

        <FilterWrapper>
          <FilterLabel>Filter by Admission Status</FilterLabel>
          <FilterSelect
            id="statusFilter"
            onChange={(e) => handleStatusFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="PENDING">PENDING</option>
            <option value="ACCEPTED">ACCEPTED</option>
            <option value="REJECTED">REJECTED</option>
          </FilterSelect>
        </FilterWrapper>
      </Form>

      {students.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No students to display.</p>
        </div>
      ) : currentStudents.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No results found.</p>
        </div>
      ) : (
        <>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th>Roll Number</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>Center Name</th>
                  <th>Admission Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentStudents
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((val, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      <td>
                        {val.roll_number ? val.roll_number : "Not assigned"}
                      </td>
                      <td>{val.name}</td>
                      <td>{val.email}</td>
                      <td>{val.mobile_number}</td>
                      <td>{getCenterName(val.center_id)}</td>
                      <td>
                        <select
                          value={val.admission_status}
                          onChange={(e) =>
                            handleStatusChange(val.id, e.target.value)
                          }
                          className={`px-2 py-1 border rounded ${val.admission_status === "PENDING"
                            ? "text-blue-500"
                            : val.admission_status === "ACCEPTED"
                              ? "text-green-500"
                              : val.admission_status === "REJECTED"
                                ? "text-red-500"
                                : ""
                            }`}
                        >
                          <option value="PENDING" className="text-gray-500">
                            PENDING
                          </option>
                          <option value="ACCEPTED" className="text-gray-500">
                            ACCEPTED
                          </option>
                          <option value="REJECTED" className="text-gray-500">
                            REJECTED
                          </option>
                        </select>
                      </td>
                      <td>
                        {val.admission_status === "ACCEPTED" &&
                          !val.roll_number && (
                            <>
                              {selectedStudentId === val.id ? (
                                <>
                                  <input
                                    type="text"
                                    value={rollNumberInput}
                                    onChange={(e) =>
                                      setRollNumberInput(e.target.value)
                                    }
                                    placeholder="Enter roll number"
                                    className="px-2 py-1 border rounded"
                                  />
                                  <button
                                    onClick={() =>
                                      handleRollNumberSubmit(val.id)
                                    }
                                    className="ml-2 px-4 py-1 bg-blue-600 text-white rounded"
                                  >
                                    Submit
                                  </button>
                                </>
                              ) : (
                                <button
                                  onClick={() => handleAddRollNumber(val.id)}
                                  className="px-4 py-1 bg-green-600 text-white rounded"
                                >
                                  Add Roll Number
                                </button>
                              )}
                            </>
                          )}
                        <button
                          onClick={() => handleViewStudent(val.id)}
                          className="ml-2 px-4 py-1 bg-yellow-600 text-white rounded"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default StudentComponent;