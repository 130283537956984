import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getCertificate, fetchStudent, fetchSessionByCourseId, fetchSubjectsOfCourses } from '../../Api/admin';
import './CertificateSSLCComponent.css';
import { fetchResultsByRollNumber } from '../../Api/student';

const CertificateSSLCComponent = () => {
  const { id, printNumber } = useParams();
  const printRef = useRef();
  const [certificate, setCertificate] = useState();
  const [rollNumber, setRollNumber] = useState('');
  const [student, setStudent] = useState();
  const [courseId, setCourseId] = useState('');
  const [session, setSession] = useState();
  const [result, setResult] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const handlePrint = () => {
    const content = printRef.current.innerHTML;
    const printWindow = window.open('', '', 'width=900,height=650');
    printWindow.document.write(`
      <html>
        <head>
          <title>SSLC Certificate</title>
          <style>
            @page {
              margin: 0; /* Remove margins */
            }
            @media print {
              body { font-family: Arial, sans-serif; margin: 0; padding: 20px; }
              .certificate { text-align: center; margin: 20px; }
              .certificate-header { font-size: 20px; text-align: center; }
              .certificate-details { margin-top: 20px; }
              table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              th, td { padding: 8px 12px; border: 1px solid black; text-align: center; }
              .certificate-footer { margin-top: 30px; text-align: left; }
            }
          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };
  
  useEffect(() => {
    const fetchPrintRequest = async () => {
      try {
        const numPrintNumber = Number(printNumber);
        let requestType;
        if (numPrintNumber === 0) {
          requestType = "Original";
        } else if (numPrintNumber > 0) {
          requestType = "Duplicate";
        }
        if (id) {
          const res = await getCertificate(id, requestType);
          if (res.data.success) {
            setCertificate(res.data.data.certificate);
            setRollNumber(res.data.data.certificate.roll_number);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchPrintRequest();
  }, [id, printNumber]);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        if (rollNumber) {
          const res = await fetchStudent(rollNumber);
          if (res.data.success) {
            setStudent(res.data.data);
            setCourseId(res.data.data.course_id)
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudentData();
  }, [rollNumber]);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        if (courseId) {
          const res = await fetchSessionByCourseId(courseId)
          if (res.data.success) {
            setSession(res.data.data.session)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchSessionData()
  }, [courseId])

  useEffect(() => {
    const fetchSubjectData = async () => {
      try {
        if (courseId) {
          const res = await fetchSubjectsOfCourses(courseId)
          if (res.data.success) {
            setSubjects(res.data.data)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchSubjectData()
  }, [courseId])

  useEffect(() => {
    const fetchResultData = async () => {
      try {
        if (rollNumber) {
          const res = await fetchResultsByRollNumber(rollNumber)
          if (res.data.success) {
            setResult(res.data.data.result)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchResultData()
  }, [rollNumber])

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const calculateOverallPercentage = () => {
    const totalMaxMarks = subjects.reduce((sum, subject) => sum + (subject?.max_marks || 0), 0);
    const totalObtainedMarks = result[0]?.results.reduce((total, val) => total + (val?.totalMarks || 0), 0);
    return ((totalObtainedMarks / totalMaxMarks) * 100).toFixed(2);
  };

  const getResultClassification = (percentage) => {
    if (percentage >= 85) {
      return "Distinction";
    } else if (percentage >= 60) {
      return "First Class";
    } else if (percentage >= 50) {
      return "Second Class";
    } else if (percentage >= 35) {
      return "Pass";
    } else {
      return "Fail";
    }
  };

  const overallPercentage = calculateOverallPercentage();
  const resultClassification = getResultClassification(overallPercentage);

  const languageLabels = ['First Language', 'Second Language', 'Third Language'];

  return (
    <>
      <div className="certificate" ref={printRef}>
        <div className="certificate-header">
          <h1>KARNATAKA STATE COUNCIL OF INTERMEDIATE AND HIGHER EDUCATION</h1>
          <h2>
            {certificate?.printNumber === 0
              ? "STATEMENT OF MARKS"
              : "DUPLICATE STATEMENT OF MARKS"}
          </h2>
          <h3>{result[0]?.Exam?.exam_name}</h3>
        </div>

        <div className="certificate-details">
          <p><strong>Roll No :</strong> {student?.roll_number}</p>
          <p><strong>Student Name :</strong> {student?.name?.toUpperCase()}</p>
          <p><strong>Father's Name :</strong> {student?.father_name?.toUpperCase()}</p>
          <p><strong>Mother's Name :</strong> {student?.mother_name?.toUpperCase()}</p>
          <p><strong>Date of Birth :</strong> {formatDate(student?.date_of_birth)}</p>
          <p><strong>Session:</strong> {session?.session_month} - {session?.session_year}</p>
          <p><strong>Result:</strong> {resultClassification}</p>
        </div>

        <table className="marks-table">
          <thead>
            <tr>
              <th>Subject</th>
              <th>Max Marks</th>
              <th>Min Marks</th>
              <th>Marks Obtained</th>
            </tr>
          </thead>
          <tbody>
            {subjects.map((subject, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'left' }}>
                  {index < 3
                    ? `${languageLabels[index]} :  ${subject?.subject_name}`
                    : subject?.subject_name}
                </td>
                <td>{subject?.max_marks}</td>
                <td>{subject?.min_marks}</td>
                <td>{result[0]?.results[index]?.totalMarks}</td>
              </tr>
            ))}

            <tr>
              <td colSpan="3"><strong>Total Marks</strong></td>
              <td>
                {result[0]?.results.reduce((total, val) => total + (val?.totalMarks || 0), 0)}
              </td>
            </tr>

            <tr>
              <td colSpan="3"><strong>Overall Percentage</strong></td>
              <td>
                {(() => {
                  const totalMaxMarks = subjects.reduce((sum, subject) => sum + (subject?.max_marks || 0), 0);
                  const totalObtainedMarks = result[0]?.results.reduce((total, val) => total + (val?.totalMarks || 0), 0);
                  return ((totalObtainedMarks / totalMaxMarks) * 100).toFixed(2);
                })()}
                %
              </td>
            </tr>
          </tbody>
        </table>

        <div className="certificate-footer">
          <p><strong>Place: Mysore</strong> </p>
          <p><strong>Issue Date: {formatDate(session?.issue_date)}</strong></p>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={handlePrint}
          style={{
            padding: "10px 20px",
            backgroundColor: "#FBD6BD",
            color: "black",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Print
        </button>
      </div>
    </>
  );
};

export default CertificateSSLCComponent;