import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { fetchTimetableByCourseId } from "../../Api/student";
import {
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  StyledTable,
  NavButton,
  PageIndicator,
  NavWrapper,
  TableWrapper,
} from "../UI/StyledComponents";

const HallticketComponent = () => {
  const { studentInfo } = useSelector((state) => state.auth);
  const [timetable, setTimetable] = useState([]);
  const [course_id, setCourseId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const decoding = () => {
      if (studentInfo) {
        const decodedToken = jwtDecode(studentInfo);
        if (decodedToken && decodedToken.user && decodedToken.user.course_id) {
          setCourseId(decodedToken.user.course_id);
        }
      }
    };
    decoding();
  }, [studentInfo]);

  useEffect(() => {
    const fetchTimetableData = async () => {
      try {
        if (course_id) {
          const res = await fetchTimetableByCourseId(course_id);
          if (res.data.success && res.data.data.timetable) {
            setTimetable(res.data.data.timetable);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTimetableData();
  }, [course_id]);

  const handleView = async (id) => {
    try {
      navigate(`/viewHallticket/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTimetable = timetable.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(timetable.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Hall Tickets </PageTitle>
        <PageSubtitle>View and manage hall tickets</PageSubtitle>
      </PageHeader>
      {timetable.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No Timetable to display.</p>
        </div>
      ) : (
        <div>
          <TableWrapper>
            <StyledTable className="student-table min-w-full">
              <thead>
                <tr>
                  <th>Sl no</th>
                  <th>Examination</th>
                  <th>Session</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentTimetable
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((val, index) => (
                    <tr key={index} className="hover:bg-gray-100 border-b">
                      <td>{index + 1}</td>
                      <td>{val?.exam?.exam_name}</td>
                      <td>
                        {val?.session?.session_month} -{" "}
                        {val?.session?.session_year}
                      </td>
                      <td>
                        <button title="View hall ticket" onClick={() => handleView(val.id)}>
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>

              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>

              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}{" "}
        </div>
      )}
    </PageWrapper>
  );
};

export default HallticketComponent;
