import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { exams } from "../../Api/admin";
import {
  PageHeader,
  PageSubtitle,
  PageWrapper,
  PageTitle,
  Form,
  SearchInput,
  SearchWrapper,
  AddButton,
  NavButton,
  NavWrapper,
  PageIndicator,
  StyledTable,
  TableWrapper,
} from "../UI/StyledComponents";

const ExamComponent = () => {
  const [exam, setExam] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const res = await exams();
        if (res.data.success) {
          setExam(res.data.data.exams);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleEdit = (id) => async () => {
    try {
      navigate(`/admin/editExam/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = async () => {
    try {
      navigate("/admin/addExam");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); 
  };

  const filteredExams = exam.filter((val) => {
    const matchesSearchQuery = val.exam_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return matchesSearchQuery;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentExams = filteredExams.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredExams.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Exams</PageTitle>
        <PageSubtitle>View and Manage Exams</PageSubtitle>
      </PageHeader>

      {exam.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No Exams to display.</p>
          <AddButton type="button" onClick={handleAdd}>
            Add Exam
          </AddButton>
        </div>
      ) : (
        <>
          <div>
            <Form>
              <SearchWrapper>
                <SearchInput
                  placeholder="Search by exam name"
                  required
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </SearchWrapper>
              <AddButton type="button" onClick={handleAdd}>
                Add Exam
              </AddButton>
            </Form>
          </div>

          {filteredExams.length === 0 ? (
            <div className="text-center">
              <p className="text-gray-700 mb-4">No matching exams found.</p>
            </div>
          ) : (
            <>
              <TableWrapper>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>Sl No</th>
                      <th>Exam Name</th>
                      <th>Date Added</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentExams
                      .sort(
                        (a, b) => new Date(b.created_at) - new Date(a.created_at)
                      )
                      .map((val, index) => (
                        <tr key={index}>
                          <td>{index+1}</td>
                          <td>{val.exam_name}</td>
                          <td>{formatDate(val.created_at)}</td>
                          <td>
                            <button onClick={handleEdit(val.id)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </StyledTable>
              </TableWrapper>

              {totalPages > 1 && (
                <NavWrapper>
                  <NavButton
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </NavButton>
                  <PageIndicator className="text-xs">
                    Page {currentPage} of {totalPages}
                  </PageIndicator>
                  <NavButton
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </NavButton>
                </NavWrapper>
              )}
            </>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default ExamComponent;
