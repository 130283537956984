import Sidebar from "../../../Components/Staff/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import EditSubjectComponent from "../../../Components/Staff/EditSubjectComponent";
import NavComponent from "../../../Components/Staff/NavComponent";

const EditSubject = () => {
  return (
    <>
      <NavComponent />
    <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <EditSubjectComponent />
            </MainContent>
        </Layout>
        </>
  )
}

export default EditSubject