import React, { useRef, useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../Assets/logo.png";
import { useParams } from 'react-router-dom';
import UserImage from '../../Assets/userImage.png';
import { fetchTimetable, fetchCourse, fetchSubjectsOfCourses, fetchSubject } from "../../Api/student";
import { fetchStudent, fetchCenter } from "../../Api/center";

const ViewHallticketComponent = () => {
    const hallTicketRef = useRef();
    const { timetableId, userId } = useParams();
    const [timetable, setTimetable] = useState([]);
    const [student, setStudent] = useState();
    const [studentImage, setStudentImage] = useState('');
    const [centerId, setCenterId] = useState('');
    const [center, setCenter] = useState();
    const [subjects, setSubjects] = useState([]);
    const [subjectNames, setSubjectNames] = useState({});
    const [allSubjects, setAllSubjects] = useState([]);
    const [course_id, setCourseId] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (userId) {
                    const res = await fetchStudent(userId)
                    if (res.data.success) {
                        setStudent(res.data.data.student)
                        setCenterId(res.data.data.student.center_id);
                        setCourseId(res.data.data.student.course_id);
                        const imageUrl = res.data.data.student.image || UserImage;
                        loadImage(imageUrl);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchUserData()
    }, [userId])

    const loadImage = (url) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = url;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            setStudentImage(dataURL);
        };
        img.onerror = (err) => {
            setStudentImage(UserImage);
        };
    };

    useEffect(() => {
        const fetchCenterData = async () => {
            try {
                if (centerId) {
                    const res = await fetchCenter(centerId);
                    if (res.data.success) {
                        setCenter(res.data.data)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchCenterData()
    }, [centerId])

    useEffect(() => {
        const fetchTimetableData = async () => {
            try {
                if (timetableId) {
                    const res = await fetchTimetable(timetableId);
                    if (res.data.success) {
                        const timetableData = res.data.data.timetable;
                        setTimetable(timetableData);
                        setSubjects(timetableData.subjects);
                        const fetchAllSubjectNames = async (subjects) => {
                            const subjectNamePromises = subjects.map((subject) =>
                                getSubjectName(subject.subject_id)
                            );
                            const subjectNamesArr = await Promise.all(subjectNamePromises);
                            const names = subjects.reduce((acc, subject, index) => {
                                acc[subject.subject_id] = subjectNamesArr[index];
                                return acc;
                            }, {});

                            setSubjectNames(names);
                        };

                        await fetchAllSubjectNames(timetableData.subjects);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchTimetableData();
    }, [timetableId]);

    const getSubjectName = async (id) => {
        try {
            const res = await fetchSubject(id);
            if (res.data.success) {
                return res.data.data.subject.subject_name;
            } else {
                console.log("Failed to fetch the subject name or subject not found");
                return "Unknown Subject";
            }
        } catch (error) {
            console.log(error);
            return "Error fetching subject";
        }
    };

    const handlePrint = () => {
        const input = hallTicketRef.current;
        html2canvas(input, {
            backgroundColor: "#ffffff",
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
            pdf.save("hall_ticket.pdf");
        });
    };

    function convertTo12HourFormat(time24) {
        let [hour, minute] = time24.split(':');
        hour = parseInt(hour);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12 || 12;
        return `${hour}:${minute} ${ampm}`;
    }

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                if (course_id) {
                    const res = await fetchCourse(course_id);
                    if (res.data.success) {
                        const courseData = res.data.data.course;
                        const subjectIds = courseData?.subjectIds;
                        const matchedSubjects = subjectIds
                            .map((id) => allSubjects.find((subject) => subject.id === id))
                            .filter((subject) => subject);

                        setSubjects(matchedSubjects);
                    }
                }
            } catch (error) {
                console.log(error)
            }

        };
        fetchCourseData();
    }, [course_id, allSubjects]);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                if (course_id) {
                    const res = await fetchSubjectsOfCourses(course_id);
                    if (res.data.success) {
                        setSubjects(res.data.data);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchSubjects();
    }, [course_id]);

    console.log(subjectNames, setAllSubjects)

    return (
        <div className="flex justify-center items-center flex-col p-5 bg-white">
            <div
                ref={hallTicketRef}
                className="bg-white border border-gray-400 w-[210mm] h-[297mm] p-8 shadow-none"
            >
                <div className="flex justify-between items-center">
                    <img
                        src={logo}
                        alt="Logo"
                        className="w-16 h-16"
                    />
                    <div className="text-center">
                        <h1 className="text-lg font-bold uppercase">
                            Karnataka State Council of Intermediate and Higher Education
                        </h1>
                        <h2 className="text-md font-semibold uppercase">Hall Ticket</h2>
                        <h3 className="text-md"> {timetable?.exam?.exam_name && timetable?.session?.session_month && timetable?.session?.session_year
                            ? `${timetable.exam.exam_name}, ${timetable.session.session_month}-${timetable.session.session_year}`
                            : 'Loading...'}</h3>
                    </div>
                </div>

                <div className="flex justify-between items-start mt-8">
                    <div className="mt-8 space-y-2">
                        <p>
                            <strong>Student Name:</strong> {student?.name}
                        </p>
                        <p>
                            <strong>Registration Number:</strong> {student?.roll_number}
                        </p>
                        <p>
                            <strong>Exam Center:</strong> {center?.center_name}
                        </p>
                    </div>
                    {studentImage ? (
                        <img
                            src={studentImage}
                            alt="Student"
                            className="w-24 h-32 rounded border border-gray-400 object-cover"
                        />
                    ) : (
                        <p>Loading image...</p>
                    )}
                </div>

                <div className="overflow-x-auto w-full table-wrapper mt-10">
                    <table className="min-w-full border-collapse border border-gray-300">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="border border-gray-300 p-2 text-left">Subject</th>
                                <th className="border border-gray-300 p-2 text-left">Date</th>
                                <th className="border border-gray-300 p-2 text-left">Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                subjects.map((subject, index) => (
                                    <tr key={index} className="hover:bg-gray-100">
                                        <td className="border border-gray-300 p-2">{subject.subject_name}</td>
                                        <td className="border border-gray-300 p-2">{timetable.subjects[index]?.exam_date}</td>
                                        <td className="border border-gray-300 p-2">{convertTo12HourFormat(timetable.subjects[index]?.from_time)} to {convertTo12HourFormat(timetable.subjects[index]?.to_time)}</td> {/* Time from timetable */}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                <div className="mt-8">
                    <p className="font-semibold">Note:</p>
                    <ul className="space-y-2">
                        <li className="flex items-start">
                            <span className="font-semibold mr-2">1.</span>
                            <span>
                                Without identification card candidate shall not be allowed to
                                write the examination.
                            </span>
                        </li>
                        <li className="flex items-start">
                            <span className="font-semibold mr-2">2.</span>
                            <span>
                                Carrying mobile phone into the examination is strictly prohibited.
                            </span>
                        </li>
                        <li className="flex items-start">
                            <span className="font-semibold mr-2">3.</span>
                            <span>
                                Attend the examination as per the dates mentioned above.
                            </span>
                        </li>
                    </ul>
                </div>

                <div className="flex justify-between items-center mt-12">
                    <div className="text-left">
                        <p className="font-semibold">Student's Signature:</p>
                        <p className="mt-4">___________________</p>
                    </div>

                    <div className="text-right">
                        <p className="font-semibold">Sd/-</p>
                        <p>Secretary</p>
                    </div>
                </div>
            </div>

            <button
                onClick={handlePrint}
                className="mt-5 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
            >
                Print Hall Ticket
            </button>
        </div>
    )
}

export default ViewHallticketComponent