import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom";

const StaffLoggedOut = () => {
    const { staffInfo } = useSelector((state) => state.auth);
    return (
        staffInfo ? < Navigate to='/staff/dashboard' /> : < Outlet/>
    )
}

export default StaffLoggedOut