import HallTicketComponent from "../../../Components/Center/HallTicketComponent";
import Sidebar from "../../../Components/Center/Sidebar";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from '../../../Components/Center/NavComponent';

const Hallticket = () => {
  return (
    <>
    <NavComponent/>
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <HallTicketComponent />
      </MainContent>
    </Layout>
        </>
  );
};

export default Hallticket;
