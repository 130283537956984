import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Login from "../Components/Common/Login";
import Dashboard from "../Pages/Staff/Dashboard/Dashboard";
import Task from "../Pages/Staff/Task/Task";
import Password from "../Pages/Staff/Password/Password";
import StaffLoggedIn from "../Components/Staff/StaffLoggedIn";
import StaffLoggedOut from "../Components/Staff/StaffLoggedOut";
import Error from "../Pages/Staff/Error/Error";
import Center from "../Pages/Staff/Center/Center";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import Sessions from "../Pages/Staff/Sessions/Sessions";
import Courses from "../Pages/Staff/Courses/Course";
import Timetable from "../Pages/Staff/Timetable/Timetable";
import Subjects from "../Pages/Staff/Subjects/Subjects";
import Exams from "../Pages/Staff/Exams/Exams";
import Results from "../Pages/Staff/Results/Results";
import Corrections from "../Pages/Staff/Corrections/Corrections";
import PrintRequest from "../Pages/Staff/Request/PrintRequest";
import DuplicatePrintRequest from "../Pages/Staff/Request/DuplicatePrintRequest";
import AddCenter from "../Pages/Staff/Center/AddCenter";
import EditCenter from "../Pages/Staff/Center/EditCenter";
import Students from "../Pages/Staff/Students/Students";
import AddTimetable from "../Pages/Staff/Timetable/AddTimetable";
import ViewTimetable from "../Pages/Staff/Timetable/ViewTimetable";
import EditTimetable from "../Pages/Staff/Timetable/EditTimetable";
import AddSession from "../Pages/Staff/Sessions/AddSession";
import EditSession from "../Pages/Staff/Sessions/EditSession";
import AddSubject from "../Pages/Staff/Subjects/AddSubject";
import EditSubject from "../Pages/Staff/Subjects/EditSubject";
import AddCourse from "../Pages/Staff/Courses/AddCourse";
import EditCourse from "../Pages/Staff/Courses/EditCourse";
import AddExam from "../Pages/Staff/Exams/AddExam";
import EditExam from "../Pages/Staff/Exams/EditExam";
import CertificateMigration from "../Pages/Staff/Certificate/CertificateMigration";
import CertificateStudy from "../Pages/Staff/Certificate/CertificateStudy";
import CertificateBonafide from "../Pages/Staff/Certificate/CertificateBonafide";
import CertificateSSLC from "../Pages/Staff/Certificate/CertificateSSLC";
import CertificatePUC from "../Pages/Staff/Certificate/CertificatePUC";
import ViewStudent from "../Pages/Staff/Students/ViewStudent";
import AddStudent from "../Pages/Staff/Students/AddStudent";
import ViewCenter from "../Pages/Staff/Center/ViewCenter";

const StaffRoute = () => {
  const { staffInfo } = useSelector((state) => state.auth);
  const [privilegesArray, setPrivilegesArray] = useState([]);

  useEffect(() => {
    const decoding = async () => {
      if (staffInfo) {
        const decodedToken = jwtDecode(staffInfo);
        if (decodedToken) {
          const privileges = decodedToken?.user?.privileges;
          const privilegesArray = privileges
            .split(",")
            .map((privilege) => privilege.trim());
          setPrivilegesArray(privilegesArray);
        }
      }
    };
    decoding();
  }, [staffInfo]);

  return (
    <Routes>
      <Route path="" element={<StaffLoggedOut />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="" element={<StaffLoggedIn />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="tasks" element={<Task />} />
        {privilegesArray.includes("Center Management") && (
          <>
          <Route path="center" element={<Center />} />
          <Route path='viewcenter/:id' element={<ViewCenter />} />
          <Route path='addCenter' element={<AddCenter />} />
          <Route path='editCenter/:id' element={<EditCenter />} />
          </>
        )}
        {privilegesArray.includes("Student Management") && (
          <>
            <Route path="student" element={<Students />} />
            <Route path='viewStudent/:id' element={<ViewStudent />} />
            <Route path='addStudent' element={<AddStudent />} />

          </>
        )}
        {privilegesArray.includes("Session Management") && (
          <>
            <Route path="session" element={<Sessions />} />
            <Route path="addSession" element={<AddSession />} />
            <Route path="editSession/:id" element={<EditSession />} />
          </>
        )}
        {privilegesArray.includes("Course Management") && (
          <>
            <Route path="course" element={<Courses />} />
            <Route path="addCourse" element={<AddCourse />} />
            <Route path="editCourse/:id" element={<EditCourse />} />
          </>
        )}
        {privilegesArray.includes("Timetable Management") && (
          <>
            <Route path="timetable" element={<Timetable />} />
            <Route path="addTimetable" element={<AddTimetable />} />
            <Route path="viewTimetable/:id" element={<ViewTimetable />} />
            <Route path='editTimetable/:id' element={<EditTimetable />} />
          </>
        )}
        {privilegesArray.includes("Subject Management") && (
          <>
            <Route path="subject" element={<Subjects />} />
            <Route path="addSubject" element={<AddSubject />} />
            <Route path="editSubject/:id" element={<EditSubject />} />
          </>
        )}
        {privilegesArray.includes("Exam Management") && (
          <>
            <Route path="exam" element={<Exams />} />
            <Route path="addExam" element={<AddExam />} />
            <Route path="editExam/:id" element={<EditExam />} />
          </>
        )}
        {privilegesArray.includes("Corrections") && (
          <Route path="corrections" element={<Corrections />} />
        )}
        {privilegesArray.includes("Result Updation") && (
          <Route path="results" element={<Results />} />
        )}
        {privilegesArray.includes("Printing Requests") && (
          <>
            <Route path="requests/original" element={<PrintRequest />} />
            <Route
              path="requests/duplicate"
              element={<DuplicatePrintRequest />}
            />
            <Route path='viewMigrationCertificate/:id/:printNumber' element={<CertificateMigration />} />
            <Route path='viewStudyCertificate/:id/:printNumber' element={<CertificateStudy />} />
            <Route path='viewBonafideCertificate/:id/:printNumber' element={<CertificateBonafide />} />
            <Route path='viewSslcCertificate/:id/:printNumber' element={<CertificateSSLC />} />
            <Route path='viewPucCertificate/:id/:printNumber' element={<CertificatePUC />} />
          </>
        )}
        <Route path="changePassword" element={<Password />} />
      </Route>
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default StaffRoute;