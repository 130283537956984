import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const LogoWrapper = styled.div`
  margin: 0 0 2rem 0.5rem;
  img {
    width: 100px;
    margin-bottom: 0.5rem;
  }
  h1 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  h2 {
    font-size: 1rem;
    font-family: "Baloo Tamma 2", cursive;
  }
`;

export const Layout = styled.div`
  display: flex;
  min-width: 1280px;
`;

export const SidebarContainer = styled.div`
  @media (min-width: 640px) {
    width: 250px;
  }
  background-color: #eff1f3;
  padding: 1rem;
`;

export const MainContent = styled.div`
  flex: 1;
  padding: 1rem;
  background-color: #eff1f3;
  transition: margin-left 0.3s ease;
  margin-left: ${(props) =>
    props.openSidebar ? "16rem" : "0"}; /* Adjust based on sidebar width */

  min-height: 100vh;
`;

export const PageWrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1.5rem;
`;

export const AddComponentForm = styled.form`
  width: 100%;
  margin: auto;
  background: #ffffff;
  padding: 2rem;
  border-radius: 1rem;

  label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4a5568;
    margin-bottom: 0px;
  }

  input:not([type="checkbox"]):not([type="radio"]) {
    display: block;
    width: 100%;
    padding: 6px;
    border-bottom: 1px solid #d1d5db;
    ${'' /* border-radius: 0.5rem; */}
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;
    background: #f9f9f9;

    &:focus {
      outline: none;
      border-color: #3b82f6;
      box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
      background: #f9f9f9;
    }

    &:disabled {
      background-color: #f1f1f1;
      border: 1px solid #f1f1f1;
    }
  }

  button {
    color: #000;
    background-color: #fbd6bd;
    border: solid 1px #cacaca;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 10px;
    line-height: 3rem;
    padding: 0 2rem;
    margin-top: 1rem;
    width: 100%;
    &:hover {
      background-color: #ffefe4;
    }
  }

  textarea {
    display: block;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    resize: none;

    &:focus {
      outline: none;
      border-color: #3b82f6;
      box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
    }
  }
  select {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-bottom: 1px solid #d1d5db;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    background: #f9f9f9;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #3b82f6;
      box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
    }
  }
  option {
    padding: 0.5rem;
  }
`;

export const AddSubjectButton = styled.button`
  color: #000;
  background-color: #green;
  border: solid 1px #cacaca;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  line-height: 3rem;
  padding: 0 2rem;
  margin-top: 1rem;
  width: 100%;
  &:hover {
    background-color: #ffefe4;
  }
`;

export const CheckBoxContainer = styled.div`
  padding: 1rem;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px; // Optional: Add space at the bottom
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const CheckBoxInput = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: #007bff;
    border-color: #007bff;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 9px;
    width: 6px;
    height: 15px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:disabled {
    border-color: #ddd;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  &:disabled:checked {
    background-color: #bbb;
    border-color: #bbb;
  }

  &:disabled:checked::after {
    border-color: #eee;
  }
`;

export const CheckBoxLabel = styled.label`
  margin-left: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  margin-bottom: 0 !important;
  padding: 0.5rem 0;
`;

export const Spacing20 = styled.div`
  height: 20px;
`;

export const Spacing10 = styled.div`
  height: 10px;
`;

export const DividerLine = styled.div`
  border-bottom: 1px solid #efefef;
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const SearchInput = styled.input`
  ${"" /* flex-grow: 1; */}
  width: 25vw;
  line-height: 3rem;
  border-radius: 1.5rem;
  padding: 0 20px;
  margin-right: 1rem;
`;

export const SearchButton = styled.button`
  color: #000;
  background-color: #fbd6bd;
  border: solid 1px #cacaca;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  line-height: 3rem;
  padding: 0 2rem;
  &:hover {
    background-color: #ffefe4;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterLabel = styled.p`
  margin-right: 8px;
`;

export const FilterSelect = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 16px;
  line-height: 3rem;
  height: 3rem;
`;

export const AddButton = styled.button`
  color: #000;
  background-color: #fbd6bd;
  border: solid 1px #cacaca;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  line-height: 2.5rem;
  padding: 0 2rem;
  margin-left: 1rem;
  &:hover {
    background-color: #ffefe4;
  }
`;

export const AddSubButton = styled.button`
  color: #000;
  background-color: #a1d6b2;
  border: solid 1px #cacaca;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  line-height: 3rem;
  padding: 0 2rem;
  margin-left: 1rem;
  &:hover {
    background-color: #ffefe4;
  }
`;

export const RemoveButton = styled.button`
  color: #000;
  background-color: #55679c;
  border: solid 1px #cacaca;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  line-height: 3rem;
  padding: 0 2rem;
  margin-left: 1rem;
  &:hover {
    background-color: #ffefe4;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const NoDataWrapper = styled.div`
  text-align: center;
`;

export const NoDataText = styled.p`
  color: #4a5568;
  margin-bottom: 1rem;
`;
export const NavButton = styled.button`
  padding: 8px 16px;
  background-color: #fbd6bd;
  border: none;
  border-radius: 10px;
  line-height: 2.5rem;
  padding: 0 1rem;
  margin: 0 8px;
  font-weight: 500;
  cursor: pointer;
  border: solid 1px #cacaca;
  &:hover {
    background-color: #ffefe4;
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const PageIndicator = styled.div`
  color: grey;
  font-weight: bold;
  margin: 0 8px;
`;

export const StyledTable = styled.table`
  line-height: 1rem;
  text-align: left;
  border-radius: 10px;
  background-color: #fff;
  min-width: 100%;
  

  thead {
    background-color: #fffbfb;
    line-height: 2rem;
    font-size: 1rem;
    

  }


  thead th {
    color: #333;
    padding: 1rem;
    
  }

  tr {
    border-bottom: 1px solid #e5e7eb;
    
  }

  tbody tr:hover {
    background-color: #f7fafc;
  }

  tbody td {
    color: #808080;
    padding: 1rem;
    
  }

  tbody td.text-blue-500 {
    color: #007bff;
  }

  tbody td.text-green-500 {
    color: #28a745;
  }

  tbody td.text-red-500 {
    color: #dc3545;
  }

  button {
    color: #007bff;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    &:hover {
      color: #0056b3;
    }
  }

  ${"" /* Depriceate this add button, i don't think it is useful */}
  addbutton {
    color: #000;
    background-color: #fbd6bd;
    border: solid 1px #cacaca;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 10px;
    line-height: 3rem;
    padding: 0.75rem 2rem;
    margin-left: 1rem;
    &:hover {
      background-color: #ffefe4;
    }
  }
`;

export const SubHeading = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

export const ProfileCard = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin: 1rem 0;
  position: relative;
`;
export const StaffImageWrapper = styled.div`
margin-top:5px;
  position: relative;
  top: 100%;
  left: 0%;
  bottom:5%
  transform: translate(-50%, -50%);
  img {
    width: 4rem;
    height: 5rem;
    border-radius: 50%;
    padding: 0.25rem;
    background-color: #fbd6bd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
`;
export const AdminImageWrapper = styled.div`
margin-top:4px;
margin-left:1rem;

  position: relative;
  top: 100%;
  left: 0%;
  bottom:5%
  transform: translate(-50%, -50%);
  img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    padding: 0.25rem;
    background-color: #fbd6bd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
`;

export const ProfileImageWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    padding: 0.25rem;
    background-color: #fbd6bd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
`;

export const ProfileInfo = styled.div`
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileName = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 1.5rem;
`;

export const ProfileAddress = styled.p`
  color: #6b7280;
`;

export const StudentDetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0rem;
  margin-top: 2rem;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const InfoCard = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  flex: 1;
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
`;

export const InfoTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const InfoListItem = styled.li`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #6b7280;
`;

export const TableWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

export const PageHeader = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 3.5rem;
`;

export const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 400;
  padding-bottom: 0.25rem;
`;

export const PageSubtitle = styled.p`
  font-size: 1rem;
  color: #6b7280;
`;

export const FormContainer = styled.form`
  margin-top: 1rem;
  @media (min-width: 1024px) {
    margin-top: 1.25rem;
  }
  & > div + div {
    margin-top: 1rem;
    @media (min-width: 768px) {
      margin-top: 1.25rem;
    }
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1f2937;
`;

export const Input = styled.input`
  background-color: #f9fafb;
  border: 1px solid #d1d5db;
  color: #111827;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  padding: 0.625rem;
  width: 100%;
  &:focus {
    outline: none;
    ring: 2px;
    border-color: #2563eb;
  }
`;
export const LoginButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: black;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #fbd6bd;
  border: solid 1px #cacaca;
  &:hover {
    background-color: #ffefe4;
  }

  &:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
    outline-color: #4f46e5;
  }
`;

export const WelcomeCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  background-color: #fff;
  min-width: 50rem;
`;

export const WelcomeContent = styled.div`
  flex: 1;
  padding: 40px;
`;

export const WelcomeHeading = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 20px;
`;

export const WelcomeParagraph = styled.p`
  font-size: 18px;
  font-style: italic;
  color: #5a5a5a;
`;

export const WelcomeImageContainer = styled.div`
  flex-shrink: 0;
  margin-right: 8px;
  margin-top: -12px;
`;

export const WelcomeStyledImage = styled.img`
  height: 200px;
  object-fit: contain;
`;

export const ToggleButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  color: #9ca3af;
  background-color: transparent;

  @media (min-width: 640px) {
    display: none;
  }

  &:hover {
    background-color: #f3f4f6; // Light gray background on hover
    color: #374151; // Darker gray text on hover
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #e5e7eb; // Focus ring effect
  }

  &:active {
    background-color: #e5e7eb; // Slightly darker background on click
    color: #111827; // Darker text on click
  }
`;

export const SidebarWrapper = styled.aside`
  padding-top: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 16rem;
  height: 100vh;
  background-color: #f9fafb;
  transition: transform 0.3s ease-in-out;

  transform: ${(props) =>
    props.openSidebar ? "translateX(0)" : "translateX(-100%)"};
  overflow-y: auto;
  @media (min-width: 640px) {
    transform: translateX(0);
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #94a3b8; 
`;

export const SidebarLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;  // Add padding to increase clickable area (vertical and horizontal)
  ${"" /* margin-bottom: 1rem; */}
  text-decoration: none;
  width: 100%;  // Ensure the link takes up the full width of its container
  border-radius: 0.5rem; // Add border-radius for a nice rounded effect

  img {
    height: 1rem;
    width: 1rem;
    object-fit: contain;
    margin-right: 1rem;
    
  }

  div {
    display: flex;
    flex-direction: column;
  }

  span {
    &:first-child {
      font-size: 0.75rem;
      font-weight: 600;
      white-space: nowrap;
    }

    &:last-child {
      font-size: 0.9rem;
      font-weight: 500;
      color: #6b7280;
    }
  }

  // Hover effect with a larger clickable area
  &:hover {
    background-color: #e5e7eb;  // Slightly darker gray background
    color: #111827;  // Darker text color on hover
    cursor: pointer;  // Ensure pointer cursor on hover

    img {
      filter: brightness(0.8);
    }
  }

  &.active {
    background: linear-gradient(90deg, #FBD6BD 0%, #FFF2E9 100%);
    color: #6B7280 ; 
    }
    img {
      filter: brightness(1);  // Reset image brightness for active
    }

    span {
      &:first-child {
        color: #6B7280;  // White text for the first span on active
      }

      &:last-child {
        color: #6B7280;  // Slightly dimmed white for second span on active
      }
    }
  }

`;

export const SidebarButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 2rem;                 
  text-align: left;                 
  color: #111827;                    
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;             
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out; // Smooth transitions
  font-size: .75rem; 
  font-weight: 500;  
  margin-bottom: .25rem;               

  ${"" /* &:hover {
    background-color: #f3f4f6;       
    color: #000;
  } */
  }

  img {
    width: 1rem;                  
    height: 1rem;                
    margin-right: 0.75rem;           
    color: #6b7280;                  
    transition: color 0.2s ease-in-out;
  }

  &:hover img {
    color: #111827;                 
  }

  span {SidebarButton
    color: #6b7280;                 
  }

  &:hover span {
    color: #111827;                  
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const LoginImageSection = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    width: 66.66667%;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .powered-by {
    position: absolute;
    bottom: 1.25rem;
    left: 2.5rem;
    right: 2.5rem;
    color: white;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: right;
  }
`;
export const LoginFormSection = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem;

  @media (min-width: 1024px) {
    padding: 3rem 2rem;
    width: 33.33333%;
  }
`;

export const LoginLogo = styled.img`
  display: block;
`;

export const LoginTitle = styled.h2`
  margin-top: 2.5rem;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: #1f2937;

  span {
    font-weight: bold;
  }
`;

export const LoginSubText = styled.p`
  font-size: 0.875rem;
  color: #4b5563;
`;

export const LoginFormWrapper = styled.div`
  margin-bottom: 1.5rem;

  label {
    display: block;
    font-size: 0.875rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 0.5rem;
  }

  input {
    display: block;
    width: 100%;
    padding: 0.75rem;
    margin-top: 0.5rem;
    border-radius: 0.375rem;
    border: 1px solid #d1d5db;
    color: #4b5563;
    background-color: #f9fafb;
    font-size: 0.875rem;
    line-height: 1.25rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    &:focus {
      outline: none;
      border-color: #6366f1;
      box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
    }
  }

  .password-wrapper {
    position: relative;
    margin-top: 0.5rem;
  }

  .toggle-password-icon {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    cursor: pointer;
    color: #9ca3af;
  }

  ${"" /* .login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #6366f1;
    color: white;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.375rem;
    text-align: center;
    cursor: pointer;
    margin-top: 1.5rem;

    &:hover {
      background-color: #4f46e5;
    }
  } */
  }
`;

// Form container to hold the form
export const PrintFormContainer = styled.div`
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
`;

// Welcome banner inside the form
export const PrintWelcomeBanner = styled.div`
  background-color: #fbd6bd;
  color: black;
  font-weight: 600;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

export const PrintFormWrapper = styled.div`
  margin-top: 20px;

  form {
    margin-top: 20px;
  }

  label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    display: block;
    margin-bottom: 0.5rem;
  }

  select {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    font-size: 1rem;
    color: #1f2937;
    margin-bottom: 20px;

    &:focus {
      outline: none;
      border-color: #6366f1;
      box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
    }
  }

  button {
    background-color: #fbd6bd;
    color: black;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &:hover {
      background-color: #ffefe4;
    }
  }
`;

export const CourseCheckBoxContainer = styled.div`
  max-height: 200px; // Set a maximum height for the scrollable container
  overflow-y: auto; // Enable vertical scroll
  border: 1px solid #ddd; // Optional: Add border for better visualization
  padding: 10px; // Optional: Add some padding
  margin-bottom: 20px; // Optional: Add space at the bottom
`;

const StyledAddForm = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;

  h1,
  h2,
  h3 {
    text-align: center;
    color: #333;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.2rem;
    color: #777;
  }

  h3 {
    font-size: 1.5rem;
    margin: 20px 0;
  }

  hr {
    border: 1px solid #ddd;
    margin: 20px 0;
  }

  label {
    font-size: 0.9rem;
    color: #555;
    margin-top: 10px;
    display: block;
  }

  input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;

    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }

  button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    margin-top: 20px;

    &:hover {
      background-color: #0056b3;
    }
  }

  .error {
    color: red;
    font-size: 0.8rem;
    margin: 5px 0;
  }
`;

export default StyledAddForm;