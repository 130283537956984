import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { fetchCourse, fetchSubjectsOfCourses } from "../../Api/student";
import {
  StyledTable,
  PageHeader,
  PageSubtitle,
  PageTitle,
  SubHeading,
  PageWrapper,
  TableWrapper,
} from "../UI/StyledComponents";

const CourseComponent = () => {
  const { studentInfo } = useSelector((state) => state.auth);
  const [course_id, setCourseId] = useState("");
  const [course, setCourse] = useState();
  const [subjects, setSubjects] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);

  useEffect(() => {
    const decoding = async () => {
      if (studentInfo) {
        const decodedToken = jwtDecode(studentInfo);
        if (decodedToken && decodedToken.id) {
          setCourseId(decodedToken.user.course_id);
        }
      }
    };
    decoding();
  }, [studentInfo]);

  useEffect(() => {
    const fetchCourseData = async () => {
      if (course_id) {
        const res = await fetchCourse(course_id);
        if (res.data.success) {
          const courseData = res.data.data.course;
          setCourse(courseData);
          const subjectIds = courseData?.subjectIds;
          const matchedSubjects = subjectIds
            .map((id) => allSubjects.find((subject) => subject.id === id))
            .filter((subject) => subject);

          setSubjects(matchedSubjects);
        }
      }
    };
    fetchCourseData();
  }, [course_id, allSubjects]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        if (course_id) {
          const res = await fetchSubjectsOfCourses(course_id);
          if (res.data.success) {
            setAllSubjects(res.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubjects();
  }, [course_id]);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Course Overview</PageTitle>
        <PageSubtitle>Learning Journey at a Glance</PageSubtitle>
      </PageHeader>
      <SubHeading>Course Details</SubHeading>
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <div>
          <p className="text-lg font-semibold">
            Course Name: {course?.course_name}
          </p>
        </div>
      </div>
      <SubHeading>Subjects</SubHeading>
      <TableWrapper>
        <StyledTable>
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Subject Code</th>
              <th>Subject Name</th>
            </tr>
          </thead>
          <tbody>
            {subjects.map((val, index) => (
              <tr key={val.id || index}>
                <td>{index + 1}</td>
                <td>{val.subject_code}</td>
                <td>{val.subject_name}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableWrapper>
    </PageWrapper>
  );
};

export default CourseComponent;