import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { fetchStaff, editStaff } from "../../Api/admin";
import {
  PageWrapper,
  PageHeader,
  PageSubtitle,
  PageTitle,
  DividerLine,
  Spacing20,
  AddComponentForm
} from "../UI/StyledComponents";

const allPrivileges = [
  { id: 1, name: "Center Management", selected: false },
    { id: 2, name: "Student Management", selected: false },
    { id: 3, name: "Exam Management", selected: false },
    { id: 4, name: "Course Management", selected: false },
    { id: 5, name: "Subject Management", selected: false },
    { id: 6, name: "Session Management", selected: false },
    { id: 7, name: "Timetable Management", selected: false },
    { id: 8, name: "Corrections", selected: false },
    { id: 9, name: "Printing Requests", selected: false },
    { id: 10, name: "Result Updation", selected: false },

];

const EditStaffComponent = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [full_name, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [privileges, setPrivileges] = useState(allPrivileges);
  const [searchQuery, setSearchQuery] = useState("");
  const { id } = useParams(); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStaffProfile = async () => {
      try {
        if (id) {
          const res = await fetchStaff(id);
          if (res.data.success) {
            const staffData = res.data.data.staff;
            setUsername(staffData.username);
            setEmail(staffData.email);
            setFullName(staffData.full_name);
            setMobileNumber(staffData.mobile_number);
            const staffPrivileges = staffData.privileges ? staffData.privileges.split(", ") : [];
            const updatedPrivileges = allPrivileges.map((privilege) => ({
              ...privilege,
              selected: staffPrivileges.includes(privilege.name) 
            }));

            setPrivileges(updatedPrivileges); 
          }
        }
      } catch (error) {
        console.error("Error fetching staff details:", error);
      }
    };
    fetchStaffProfile();
  }, [id]);

  const handlePrivilegeChange = (privilegeId) => {
    setPrivileges((prevPrivileges) =>
      prevPrivileges.map((privilege) =>
        privilege.id === privilegeId
          ? { ...privilege, selected: !privilege.selected } 
          : privilege
      )
    );
  };

  const filteredPrivileges = privileges.filter((privilege) =>
    privilege.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const selectedPrivileges = privileges
          .filter((privilege) => privilege.selected) 
          .map((privilege) => privilege.name);

        const privilegesString = selectedPrivileges.join(", "); 
        const res = await editStaff(
          id,
          username,
          email,
          full_name,
          mobileNumber,
          privilegesString 
        );
        if (res.data.success) {
          toast.success("Staff details updated successfully...");
          navigate("/admin/staff");
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.error("Error updating staff details:", error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Edit Staff Details</PageTitle>
        <PageSubtitle>View and manage staff details</PageSubtitle>
      </PageHeader>
      

        <AddComponentForm className="mt-4" onSubmit={handleSubmit}>
        <h2 className="text-xl mb-4">Edit Staff Details</h2>
        <DividerLine />
        <Spacing20 />

          <div className="mb-4">
            <label htmlFor="username" >
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
             
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            
            />
          </div>

          <div className="mb-4">
            <label htmlFor="full_name" >
              Full Name
            </label>
            <input
              type="text"
              id="full_name"
              name="full_name"
              value={full_name}
              onChange={(e) => setFullName(e.target.value)}
              required
           
            />
          </div>

          <div className="mb-4">
            <label htmlFor="mobileNumber" >
              Mobile Number
            </label>
            <input
              type="text"
              id="mobileNumber"
              name="mobileNumber"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="privileges" >
              Privileges
            </label>
            <input
              type="search"
              id="search-dropdown"
              placeholder="Search privileges"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <div className="max-h-32 border border-gray-300 p-2 rounded-lg overflow-y-auto">
              {filteredPrivileges.map((privilege) => (
                <div key={privilege.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={privilege.id}
                    name="privileges"
                    checked={privilege.selected}
                    onChange={() => handlePrivilegeChange(privilege.id)}
                    className="mr-2"
                  />
                  <label htmlFor={privilege.id}>{privilege.name}</label>
                </div>
              ))}
            </div>
          </div>

          <button
            type="submit"
          >
            Submit
          </button>
        </AddComponentForm>
    </PageWrapper>
  );
};

export default EditStaffComponent;