import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import {
  fetchTimetable,
  exams,
  editTimetable,
  sessions
} from "../../Api/admin";
import {
  PageWrapper,
  PageHeader,
  PageSubtitle,
  PageTitle
} from "../UI/StyledComponents";

const EditTimetableComponent = () => {
  const [examId, setExamId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [examsList, setExamsList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTimetableData = async () => {
      try {
        if (id) {
          const res = await fetchTimetable(id);
          if (res.data.success) {
            const data = res.data.data.timetable;
            setExamId(data.exam.id);
            setSessionId(data.session.id);
            setSubjects(data.subjects);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTimetableData();
  }, [id]);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const res = await exams();
        if (res.data.success) {
          setExamsList(res.data.data.exams);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamData();
  }, []);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const res = await sessions();
        if (res.data.success) {
          setSessionList(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSessionData();
  }, []);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSubjects = [...subjects];
    updatedSubjects[index][name] = value;
    setSubjects(updatedSubjects);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const payload = {
          examId,
          sessionId,
          subjects: subjects.map((item) => ({
            exam_date: item.exam_date,
            from_time: item.from_time,
            to_time: item.to_time,
          })),
        };
        const res = await editTimetable(id, payload);
        if (res.data.success) {
          toast.success("Updation successfull...");
          navigate("/admin/timetable");
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Edit Timetable</PageTitle>
        <PageSubtitle>Modify exam, session, and subject details</PageSubtitle>
      </PageHeader>

      <div className="max-w-3xl mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label htmlFor="exam" className="block text-sm font-medium text-gray-700 mb-1">
                Exam
              </label>
              <select
                id="exam"
                name="exam"
                value={examId}
                onChange={(e) => setExamId(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Exam</option>
                {examsList.map((exam) => (
                  <option key={exam.id} value={exam.id}>
                    {exam.exam_name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="session" className="block text-sm font-medium text-gray-700 mb-1">
                Session
              </label>
              <select
                id="session"
                name="session"
                value={sessionId}
                onChange={(e) => setSessionId(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Session</option>
                {sessionList.map((session) => (
                  <option key={session.id} value={session.id}>
                    {session.session_month} - {session.session_year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <table className="w-full mt-6 border border-gray-300 rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 border">Sl No</th>
                <th className="p-2 border">Date</th>
                <th className="p-2 border">From</th>
                <th className="p-2 border">To</th>
              </tr>
            </thead>
            <tbody>
              {subjects.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="p-2 text-center border">{index + 1}</td>
                  <td className="p-2 border">
                    <input
                      type="date"
                      name="exam_date"
                      value={item.exam_date || ""}
                      onChange={(e) => handleChange(index, e)}
                      className="block w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </td>
                  <td className="p-2 border">
                    <input
                      type="time"
                      name="from_time"
                      value={item.from_time || ""}
                      onChange={(e) => handleChange(index, e)}
                      className="block w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </td>
                  <td className="p-2 border">
                    <input
                      type="time"
                      name="to_time"
                      value={item.to_time || ""}
                      onChange={(e) => handleChange(index, e)}
                      className="block w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            type="submit"
            className="mt-6 w-full px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg shadow-md hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            Submit
          </button>
        </form>
      </div>
    </PageWrapper>
  );
};

export default EditTimetableComponent;