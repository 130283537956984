import { createSlice } from "@reduxjs/toolkit";

const parseJSON = (value) => {
  try {
    return value ? JSON.parse(value) : null;
  } catch (e) {
    console.error("Error parsing JSON:", e);
    return null;
  }
};

const initialState = {
  studentInfo: parseJSON(localStorage.getItem("studentInfo")),
  adminInfo: parseJSON(localStorage.getItem("adminInfo")),
  staffInfo: parseJSON(localStorage.getItem("staffInfo")),
  centerInfo: parseJSON(localStorage.getItem("centerInfo")),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setStudentCredentials: (state, action) => {
      state.studentInfo = action.payload;
      localStorage.setItem("studentInfo", JSON.stringify(action.payload));
    },
    studentLogout: (state) => {
      state.studentInfo = null;
      localStorage.removeItem("studentInfo");
    },
    setAdminCredentials: (state, action) => {
      state.adminInfo = action.payload;
      localStorage.setItem("adminInfo", JSON.stringify(action.payload));
    },
    adminLogout: (state) => {
      state.adminInfo = null;
      localStorage.removeItem("adminInfo");
    },
    setStaffCredentials: (state, action) => {
      state.staffInfo = action.payload;
      localStorage.setItem("staffInfo", JSON.stringify(action.payload));
    },
    staffLogout: (state) => {
      state.staffInfo = null;
      localStorage.removeItem("staffInfo");
    },
    setCenterCredentials: (state, action) => {
      state.centerInfo = action.payload;
      localStorage.setItem("centerInfo", JSON.stringify(action.payload));
    },
    centerLogout: (state) => {
      state.centerInfo = null;
      localStorage.removeItem("centerInfo");
    },
  },
});

export const {
  setStudentCredentials,
  setAdminCredentials,
  setStaffCredentials,
  setCenterCredentials,
  studentLogout,
  adminLogout,
  staffLogout,
  centerLogout,
} = authSlice.actions;

export default authSlice.reducer;
