import React, { useEffect, useState } from "react";
import {
  AddButton,
  StyledTable,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  SearchWrapper,
  SearchInput,
  TableWrapper,
  NavButton,
  NavWrapper,
  PageIndicator
} from "../UI/StyledComponents";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addTask, employees } from "../../Api/admin";
import { getAllTasks } from "../../Api/admin"; 

const TaskComponent = () => {
  const [tasks, setTasks] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [staffMembers, setStaffMembers] = useState([]);
  const [staffId, setStaffId] = useState("");
  const [title, setTitle] = useState("");
  const [priority, setPriority] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const res = await employees();
        if (res.data.success) {
          const staffEmployees = res.data.data.staff.filter(
            (val) => val.role?.role_name === "STAFF"
          );
          setStaffMembers(staffEmployees);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmployeeData();
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const res = await getAllTasks();
        if (res.data.success) {
          setTasks(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTasks();
  }, [status]);

  const handleOpenAddTaskModal = () => {
    setIsEditing(false);
    setOpen(true);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleAddTask = async (e) => {
    e.preventDefault();
    try {
      const res = await addTask(title, staffId, priority, dueDate, description);
      if (res?.data?.success) {
        toast.success("Task added successfully");
        setOpen(false);
        setStatus(!status);
        setTitle("");
        setPriority("");
        setStaffId("");
        setDueDate("");
        setDescription("");
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.error("Error adding task:", error);
      toast.error("Something went wrong");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filteredTasks = tasks.filter((val) => {
    const matchesSearchQuery =
      val.title.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesSearchQuery;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = filteredTasks.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Tasks</PageTitle>
        <PageSubtitle>View and Manage all task details</PageSubtitle>
      </PageHeader>
      <div className="flex justify-end mb-6">
      <SearchWrapper>
              <SearchInput
                placeholder="Search"
                required
                value={searchQuery}
                onChange={handleSearch}
              />
            </SearchWrapper>
        <AddButton onClick={handleOpenAddTaskModal}>Add Task</AddButton>
      </div>

      {currentTasks.length > 0 ? (
        <TableWrapper>
          <StyledTable className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr>
                <th>Task</th>
                <th>Priority</th>
                <th>Assigned</th>
                <th>Due Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentTasks?.map((task) => {
                const assignedStaff = staffMembers.find(
                  (staff) => staff.id === task.users.id
                );
                return (
                  <tr
                    key={task.id}
                    className="border-b hover:bg-gray-50 cursor-pointer"
                  >
                    <td className="p-4">{task.title}</td>
                    <td
                      className={`p-4 ${
                        task.priority === "High"
                          ? "text-red-500"
                          : task.priority === "Low"
                          ? "text-green-500"
                          : "text-gray-500"
                      }`}
                    >
                      {task.priority}
                    </td>
                    <td className="p-4">
                      {assignedStaff ? assignedStaff.full_name : "N/A"}
                    </td>
                    <td className="p-4">{task.due_date}</td>
                    <td
                      className={`p-4 ${
                        task.status === "In Progress"
                          ? "text-blue-500"
                          : task.status === "Completed"
                          ? "text-green-500"
                          : "text-gray-500"
                      }`}
                    >
                      {task.status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </TableWrapper>
      ) : (
        <div className="text-center text-gray-700">No results found.</div>
      )}

      {totalPages > 1 && (
        <NavWrapper>
          <NavButton
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </NavButton>
          <PageIndicator>
            Page {currentPage} of {totalPages}
          </PageIndicator>
          <NavButton
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </NavButton>
        </NavWrapper>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{isEditing ? "Edit Task" : "Add Task"}</DialogTitle>
        <DialogContent>
          <div className="mb-4">
            <FormControl fullWidth margin="normal">
              <TextField
                label="Title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth margin="normal">
              <InputLabel id="assigned-label">Assigned</InputLabel>
              <Select
                labelId="assigned-label"
                name="assigned"
                value={staffId}
                onChange={(e) => setStaffId(e.target.value)}
                label="Assigned"
              >
                {staffMembers.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>
                    {staff.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel id="priority-label">Priority</InputLabel>
              <Select
                labelId="priority-label"
                label="Priority"
                name="priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Normal">Normal</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth margin="normal">
              <TextField
                label="Due Date"
                type="date"
                name="dueDate"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </FormControl>
          </div>

          <div className="mb-4">
            <FormControl fullWidth margin="normal">
              <TextField
                label="Details"
                name="details"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddTask} color="primary">
            Save Task
          </Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  );
};

export default TaskComponent;