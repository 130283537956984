import React from 'react'
import { SidebarContainer, MainContent, Layout } from '../../../Components/UI/StyledComponents'
import PrintRequestComponent from '../../../Components/Admin/PrintRequestComponent'
import Sidebar from '../../../Components/Admin/Sidebar'
import NavComponent from '../../../Components/Admin/NavComponent'

const PrintRequest = () => {
  return (
    <>
      <NavComponent />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <PrintRequestComponent />
        </MainContent>
      </Layout>
    </>
  )
}

export default PrintRequest
