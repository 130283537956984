import Sidebar from "../../../Components/Student/Sidebar";
import HallticketComponent from "../../../Components/Student/HallticketComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";

const StudentHallTicket = () => {
  return (
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <HallticketComponent />
      </MainContent>
    </Layout>
  );
};

export default StudentHallTicket;
