import Sidebar from "../../../Components/Staff/Sidebar";
import DashboardComponent from "../../../Components/Staff/DashboardComponent";
import Welcome from "../../../Components/Admin/Welcome";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from "../../../Components/Staff/NavComponent";

const Dashboard = () => {
  return (
    <>
      <NavComponent />
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <Welcome />
        <DashboardComponent />
      </MainContent>
    </Layout>
    </>
  );
};

export default Dashboard;
