import Api from "../Service/axios";
import StaffEndpoint from "../Service/endpoints/staffEndpoints";
import AuthEndpoint from "../Service/endpoints/authEndpoints";

const handleApiRequest = async (method, url, data = null) => {
  try {
    return data ? await Api[method](url, data) : await Api[method](url);
  } catch (error) {
    console.error(`Error with API request: ${error}`);
    throw error;
  }
};

export const getTasksByStaffId = (staffId) =>
  handleApiRequest("get", `${StaffEndpoint.task}/${staffId}`);

export const updateTaskStatus = (id, newStatus) =>
  handleApiRequest("put", `${StaffEndpoint.task}/${id}`, { newStatus });

export const staffDashboard = (staffId) =>
  handleApiRequest("get", `${StaffEndpoint.staff}/dashboard/${staffId}`);

export const addCenter = (centerDetails) =>
  handleApiRequest("post", StaffEndpoint.center, centerDetails);

export const centers = () => handleApiRequest("get", StaffEndpoint.center);

export const disableCenter = (id) =>
  handleApiRequest("patch", `${StaffEndpoint.center}/${id}`);

export const fetchCenter = (id) =>
  handleApiRequest("get", `${StaffEndpoint.center}/${id}`);

export const editCenter = (id, centerDetails) =>
  handleApiRequest("put", `${StaffEndpoint.center}/${id}`, centerDetails);

export const changePasswordByAdmin = (id, passwordDetails) =>
  handleApiRequest(
    "put",
    `${AuthEndpoint.changePasswordByAdmin}/${id}`,
    passwordDetails
  );

export const student = () => handleApiRequest("get", StaffEndpoint.student);

export const updateStudentStatus = (studentId, status) =>
  handleApiRequest(
    "put",
    `${StaffEndpoint.student}/changeStatus/${studentId}`,
    { status }
  );

export const updateStudentRollNumber = (studentId, rollNumber) =>
  handleApiRequest(
    "put",
    `${StaffEndpoint.student}/assignRollNumber/${studentId}`,
    { rollNumber }
  );
