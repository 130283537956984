import React from 'react'
import Sidebar from "../../../Components/Admin/Sidebar";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import ViewStudentComponent from '../../../Components/Admin/ViewStudentComponent';
import NavComponent from "../../../Components/Admin/NavComponent";
const ViewStudent = () => {
  return (
    <>
    <NavComponent/>
    <Layout>
    <SidebarContainer>
      <Sidebar />
    </SidebarContainer>
    <MainContent>
      <ViewStudentComponent />
    </MainContent>
  </Layout>
  </>
  )
}
export default ViewStudent