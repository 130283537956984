import Sidebar from "../../../Components/Staff/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import AddSessionComponent from "../../../Components/Staff/AddSessionComponent";
import NavComponent from "../../../Components/Staff/NavComponent";

const AddSession = () => {
    return (
        <>
      <NavComponent />
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <AddSessionComponent />
            </MainContent>
        </Layout>
        </>
    )
}

export default AddSession