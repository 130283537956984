import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { courses, addStudent, centers } from "../../Api/admin";
import {
  AddComponentForm,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  Spacing20,
  SubHeading,
  DividerLine,
} from "../UI/StyledComponents";

const AddStudentByStaffComponent = () => {
  const [name, setName] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [father_name, setFatherName] = useState("");
  const [mother_name, setMotherName] = useState("");
  const [date_of_birth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [course_id, setCourseId] = useState("");
  const [center_id, setCenterId] = useState("");
  const [course, setCourses] = useState([]);
  const [centersList, setCentersList] = useState([]);
  const [filteredCenters, setFilteredCenters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [image, setImage] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const res = await courses();
        if (res.data.success) {
          setCourses(res.data.data.courses);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchCenterData = async () => {
      try {
        const res = await centers();
        if (res.data.success) {
          setCentersList(res.data.data);
          setFilteredCenters(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCourseData();
    fetchCenterData();
  }, []);

  useEffect(() => {
    setPassword(mobile_number);
  }, [mobile_number]);

  useEffect(() => {
    const results = centersList.filter((center) =>
      center.center_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCenters(results);
  }, [searchTerm, centersList]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleCenterSelect = (id, name) => {
    setCenterId(id);
    setSearchTerm(name); // Set search term to selected center name
    setDropdownVisible(false); // Hide the dropdown after selection
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setDropdownVisible(true); // Show dropdown when typing in search field
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const role_name = "STUDENT";
      const formData = new FormData();
      formData.append("name", name);
      formData.append("mobile_number", mobile_number);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("father_name", father_name);
      formData.append("mother_name", mother_name);
      formData.append("date_of_birth", date_of_birth);
      formData.append("gender", gender);
      formData.append("address", address);
      formData.append("course_id", course_id);
      formData.append("center_id", center_id);
      formData.append("role_name", role_name);
      formData.append("studentImage", image);
      const res = await addStudent(formData);
      if (res.data.success) {
        toast.success("Successfully added student...");
        navigate("/staff/student");
      } else if (!res.data.success) {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Admin Student Registration</PageTitle>
        <PageSubtitle>Register and manage students for any center</PageSubtitle>
      </PageHeader>

      <AddComponentForm onSubmit={handleSubmit}>
        <SubHeading>Student Registration Form</SubHeading>
        <DividerLine />
        <Spacing20 />

        <div className="mb-4">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={mobile_number}
            onChange={(e) => setMobileNumber(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="fatherName">Father's Name</label>
          <input
            type="text"
            id="fatherName"
            name="fatherName"
            value={father_name}
            onChange={(e) => setFatherName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="motherName">Mother's Name</label>
          <input
            type="text"
            id="motherName"
            name="motherName"
            value={mother_name}
            onChange={(e) => setMotherName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="dateOfBirth">Date of Birth</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={date_of_birth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            name="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="address">Address</label>
          <textarea
            id="address"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            rows="2"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="course">Course</label>
          <select
            id="course"
            name="course"
            value={course_id}
            onChange={(e) => setCourseId(e.target.value)}
            required
          >
            <option value="">Select Course</option>
            {course.map((val) => (
              <option key={val.id} value={val.id}>
                {val.course_name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="centerSearch">Center</label>
          <input
            type="text"
            id="centerSearch"
            placeholder="Search center..."
            value={searchTerm}
            onChange={handleSearchTermChange}
            onFocus={() => setDropdownVisible(true)} // Show dropdown on input focus
          />
          {dropdownVisible && (
            <div
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                border: "1px solid #ccc",
                marginTop: "5px",
                borderRadius: "4px",
              }}
            >
              {filteredCenters.map((center) => (
                <div
                  key={center.id}
                  onClick={() => handleCenterSelect(center.id, center.center_name)}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    backgroundColor: center_id === center.id ? "#eee" : "#fff",
                  }}
                >
                  {center.center_name}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="studentImage">Student Image</label>
          <input
            type="file"
            id="studentImage"
            name="studentImage"
            onChange={handleImageChange}
            accept="image/*"
            required
          />
        </div>

        <button type="submit">Submit</button>
      </AddComponentForm>
    </PageWrapper>
  );
};

export default AddStudentByStaffComponent;
