import Sidebar from "../../../Components/Admin/Sidebar";
import EditSessionComponent from "../../../Components/Admin/EditSessionComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from '../../../Components/Admin/NavComponent'

const EditSession = () => {
  return (
    <>
      <NavComponent />
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <EditSessionComponent />
      </MainContent>
    </Layout>
    </>

  );
};

export default EditSession;
