import Sidebar from "../../../Components/Center/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import ViewHallticketComponent from "../../../Components/Center/ViewHallticketComponent";
import NavComponent from '../../../Components/Center/NavComponent';

const ViewHallticket = () => {
    return (
        <>
    <NavComponent/>
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <ViewHallticketComponent />
            </MainContent>
        </Layout>
        </>
    )
}

export default ViewHallticket