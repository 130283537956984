import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../../Api/auth";
import loginImage from "../../Assets/loginImage.png";
import logo from "../../Assets/logo.png";
import {
  setStudentCredentials,
  setCenterCredentials,
  setStaffCredentials,
  setAdminCredentials,
} from "../../Store/slice/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LoginButton,
  LoginFormSection,
  LoginFormWrapper,
  LoginImageSection,
  LoginLogo,
  LoginSubText,
  LoginTitle,
  LoginWrapper
} from "../UI/StyledComponents";
import { FaEyeSlash, FaEye } from "react-icons/fa";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        toast.error("Please enter a valid email address.");
        return false;
      } else if (password.trim().length < 6) {
        toast.error("Please enter a valid password !!");
        return;
      }
      const res = await login(email, password);
      if (res.data.success) {
        if (res.data.data.role === "ADMIN") {
          dispatch(setAdminCredentials(res.data.data.token));
          toast.success("Successfully logged in..");
          navigate("/admin/dashboard");
        } else if (res.data.data.role === "STAFF") {
          dispatch(setStaffCredentials(res.data.data.token));
          toast.success("Successfully logged in..");
          navigate("/staff/dashboard");
        } else if (res.data.data.role === "CENTER_MANAGER") {
          dispatch(setCenterCredentials(res.data.data.token));
          toast.success("Successfully logged in..");
          navigate("/center/student");
        } else if (res.data.data.role === "STUDENT") {
          dispatch(setStudentCredentials(res.data.data.token));
          toast.success("Successfully logged in..");
          navigate("/dashboard");
        }
      } else if (!res.data.success) {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <LoginWrapper>
      <LoginImageSection
        style={{ backgroundImage: `url(${loginImage})` }}
      >
        <div className="powered-by">
          POWERED BY SINOPE SOFTWARES
        </div>
      </LoginImageSection>
      <LoginFormSection className="ml-16">
        <div className="mt-10  sm:w-full sm:max-w-sm">
          <LoginLogo src={logo} alt="ksc logo" />
          <LoginTitle>
            Welcome to <span>KSC</span>
          </LoginTitle>
          <LoginSubText>
            Login Using Your Email And Password
          </LoginSubText>
        </div>
        <div className="mt-10  sm:w-full sm:max-w-sm">
          <LoginFormWrapper className="space-y-6">
            <div>
              <label htmlFor="email">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  required
                />
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="password">
                  Password
                </label>
              </div>
              <div className="relative mt-2">
                <input
                  id="inputChoosePassword"
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  value={password}
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                />
                <span
                  className="absolute right-3 top-3 cursor-pointer text-gray-500"
                  onClick={togglePasswordVisibility}
                >
                  {passwordShown ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
            <LoginButton onClick={handleSubmit} type="submit">
              Log in
            </LoginButton>
          </LoginFormWrapper>
        </div>
      </LoginFormSection>
    </LoginWrapper>
  );
};

export default Login;