import Sidebar from "../../../Components/Student/Sidebar";
import DashboardComponent from "../../../Components/Student/DashboardComponent";
import {
  Layout,
  MainContent,
  SidebarContainer,
} from "../../../Components/UI/StyledComponents";

const Dashboard = () => {
  return (
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <DashboardComponent />
      </MainContent>
    </Layout>
  );
};

export default Dashboard;
