import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../Assets/logo.png";
import logoutLogo from "../../Assets/logoutLogo.svg";
import studentLogo from "../../Assets/studentLogo.svg";
import analyticLogo from "../../Assets/reportLogo.svg";
import hallticketLogo from "../../Assets/hallticketLogo.svg";
import passwordLogo from "../../Assets/passwordLogo.svg";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { centerLogout } from "../../Store/slice/authSlice";
import { logout } from "../../Api/auth";
import {
  LogoWrapper,
  SidebarButton,
  SidebarLink,
  SidebarWrapper,
  ToggleButton,
} from "../UI/StyledComponents";
import printIcon from "../../Assets/printIcon.svg";
import correctionIcon from "../../Assets/correctionIcon.svg";

const Sidebar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isPrintMenuOpen, setPrintMenuOpen] = useState(false);
  const [isDuplicatePrintMenuOpen, setDuplicatePrintMenuOpen] = useState(false);

  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/center/duplicatePrint")) {
      setDuplicatePrintMenuOpen(true);
    }
    if (location.pathname.startsWith("/center/print")) {
      setPrintMenuOpen(true);
    }
  }, [location.pathname]);

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleLogout = async () => {
    const res = await logout();
    if (res?.data.success) {
      dispatch(centerLogout());
      toast.success("Logged out successfully..");
      navigate("/");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const togglePrintMenu = (e) => {
    e.preventDefault();
    setPrintMenuOpen(!isPrintMenuOpen);
  };

  const toggleDuplicatePrintMenu = () => {
    setDuplicatePrintMenuOpen(!isDuplicatePrintMenuOpen);
  };

  return (
    <>
      <ToggleButton
        aria-controls="logo-sidebar"
        type="button"
        onClick={() => setOpenSidebar(!openSidebar)}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </ToggleButton>

      <SidebarWrapper
        id="logo-sidebar"
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${openSidebar ? "" : "-translate-x-full"
          } sm:translate-x-0`}
        aria-label="Sidebar"
        openSidebar={openSidebar} onClick={handleCloseSidebar}
      >
        <LogoWrapper to="/center/dashboard" onClick={handleCloseSidebar}>
          <img src={logo} alt="ksc Logo" />
          <h1>Karnataka State Council</h1>
          <h2>ಕರ್ನಾಟಕ ಸ್ಟೇಟ್ ಕೌನ್ಸಿಲ್</h2>
        </LogoWrapper>

        {/* <SidebarLink to="/center/dashboard/">
          <img src={dashboardLogo} alt="Dashboard Icon" />
          <span>Dashboard</span>
        </SidebarLink> */}

        <SidebarLink to="/center/student/">
          <img src={studentLogo} alt="Student Icon" />
          <span>Students</span>
        </SidebarLink>

        <SidebarLink as="button" onClick={togglePrintMenu}>
          <img src={printIcon} alt="print Icon" />
          <span>Print</span>
        </SidebarLink>
        {isPrintMenuOpen && (
          <ul onClick={(e) => e.stopPropagation()}>
            <SidebarButton>
              <SidebarLink to="/center/print/marksCard/">
                <img src={printIcon} alt="print Icon" />
                <span> Marks Card</span>
              </SidebarLink>
            </SidebarButton>
            <SidebarButton>
              <SidebarLink to="/center/print/bonafiedCertificate/">
                <img src={printIcon} alt="print Icon" />
                <span> Bonafide Certificate</span>
              </SidebarLink>
            </SidebarButton>
            <SidebarButton>
              <SidebarLink to="/center/print/studyCertificate/">
                <img src={printIcon} alt="print Icon" />
                <span> Study Certificate</span>
              </SidebarLink>
            </SidebarButton>

            <SidebarButton>
              <SidebarLink to="/center/print/migrationCertificate/">
                <img src={printIcon} alt="print Icon" />
                <span> Migration Certificate</span>
              </SidebarLink>
            </SidebarButton>

          </ul>
        )}

        <SidebarLink as="button" onClick={toggleDuplicatePrintMenu}>
          <img src={analyticLogo} alt="Analytics Icon" />
          <span>Duplicate print</span>
        </SidebarLink>
        {isDuplicatePrintMenuOpen && (
          <ul onClick={(e) => e.stopPropagation()}>
            <SidebarButton>
              <SidebarLink to="/center/duplicatePrint/duplicatemarksCard/">
                <img src={analyticLogo} alt="Analytics Icon" />
                <span> Marks Card</span>
              </SidebarLink>
            </SidebarButton>
            <SidebarButton>
              <SidebarLink to="/center/duplicatePrint/duplicatebonafiedCertificate/">
                <img src={analyticLogo} alt="Analytics Icon" />

                <span> Bonafide Certificate</span>
              </SidebarLink>
            </SidebarButton>
            <SidebarButton>
              <SidebarLink to="/center/duplicatePrint/duplicatestudyCertificate/">
                <img src={analyticLogo} alt="Analytics Icon" />
                <span> Study Certificate</span>
              </SidebarLink>
            </SidebarButton>
            <SidebarButton>
              <SidebarLink to="/center/duplicatePrint/duplicatemigrationCertificate">
                <img src={analyticLogo} alt="Analytics Icon" />
                <span> Migration Certificate</span>
              </SidebarLink>
            </SidebarButton>
          </ul>
        )}

        {/* <SidebarLink as="button" onClick={toggleCorrectionsMenu}>
            <img src={correctionIcon} alt="correction Icon" />
            <span>Corrections</span>
          </SidebarLink>
          {isCorrectionsMenuOpen && (
            <ul onClick={(e) => e.stopPropagation()}>
              <SidebarButton>
                <SidebarLink to="/center/corrections/profileCorrection/">
                  <img src={correctionIcon} alt="correction Icon" />
                  <span> Profile Correction</span>
                </SidebarLink>
              </SidebarButton>
              <SidebarButton>
                <SidebarLink to="/center/corrections/resultCorrection/">
                  <img src={correctionIcon} alt="correction Icon" />
                  <span> Result Correction</span>
                </SidebarLink>
              </SidebarButton>
            </ul>
          )} */}
        <SidebarLink to="/center/corrections/">
          <img src={correctionIcon} alt="Hallticket Icon" />
          <span>Corrections</span>
        </SidebarLink>

        <SidebarLink to="/center/hallticket/">
          <img src={hallticketLogo} alt="Hallticket Icon" />
          <span>Hall tickets</span>
        </SidebarLink>

        <SidebarLink to="/center/changePassword/">
          <img src={passwordLogo} alt="Password Icon" />
          <span>Change password</span>
        </SidebarLink>

        <SidebarLink to="/login" onClick={handleLogout}>
          <img src={logoutLogo} alt="Logout Icon" />
          <span>Logout</span>
        </SidebarLink>
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
