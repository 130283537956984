import React, { useState, useEffect } from "react";
import { sessions, exams, addTimetable } from "../../Api/admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { PageWrapper, PageHeader, PageSubtitle, PageTitle, AddComponentForm, DividerLine, Spacing20 } from "../UI/StyledComponents";

const AddTimetableComponent = () => {
  const [session, setSessions] = useState([]);
  const [exam, setExam] = useState([]);
  const [timetable, setTimetable] = useState(
    Array(6).fill({ subject_id: "", exam_date: "", from_time: "", to_time: "" })
  );
  const [sessionId, setSessionId] = useState("");
  const [examId, setExamId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const res = await sessions();
        if (res.data.success) {
          setSessions(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSessionData();
  }, []);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const res = await exams();
        if (res.data.success) {
          setExam(res.data.data.exams);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamData();
  }, []);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTimetable = timetable.map((row, idx) =>
      idx === index ? { ...row, [name]: value } : row
    );

    setTimetable(updatedTimetable);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        sessionId,
        examId,
        subjects: timetable.map((item) => ({
          exam_date: item.exam_date,
          from_time: item.from_time,
          to_time: item.to_time,
        })),
      };
      const res = await addTimetable(payload);
      if (res.data.success) {
        toast.success("Successfully added timetable...");
        navigate("/admin/timetable");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Timetable Details</PageTitle>
        <PageSubtitle>View and manage Timetable details</PageSubtitle>
      </PageHeader>
        
        <AddComponentForm onSubmit={handleSubmit}>

        <h2 className="text-xl mb-4">Add New Timetable</h2>
        <DividerLine/>
        <Spacing20/>
          <div className="mb-4">
            <label
              htmlFor="exam"
            >
              Select Exam
            </label>
            <select
              id="exam"
              name="exam"
              value={examId}
              onChange={(e) => setExamId(e.target.value)}
              required
            >
              <option value="">Select an exam</option>
              {exam.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.exam_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="session"
            >
              Select Session
            </label>
            <select
              id="session"
              name="session"
              value={sessionId}
              onChange={(e) => setSessionId(e.target.value)}
              required
            >
              <option value="">Select a session</option>
              {session.map((session) => (
                <option key={session.id} value={session.id}>
                  {session.session_month} - {session.session_year}
                </option>
              ))}
            </select>
          </div>
          <table className="min-w-full bg-white border border-gray-300 text-left">
            <thead>
              <tr>
                <th className="p-4 border border-gray-300">Sl No</th>
                <th className="p-4 border border-gray-300">Exam Date</th>
                <th className="p-4 border border-gray-300">From</th>
                <th className="p-4 border border-gray-300">To</th>
              </tr>
            </thead>
            <tbody>
              {timetable.map((item, index) => (
                <tr key={index}>
                  <td className="p-4 border border-gray-300">{index + 1}</td>
                  <td className="p-4 border border-gray-300">
                    <input
                      type="date"
                      name="exam_date"
                      value={item.exam_date}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </td>
                  <td className="p-4 border border-gray-300">
                    <input
                      type="time"
                      name="from_time"
                      value={item.from_time}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </td>
                  <td className="p-4 border border-gray-300">
                    <input
                      type="time"
                      name="to_time"
                      value={item.to_time}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4">
            <button
              type="submit"
            >
              Submit
            </button>
          </div>
        </AddComponentForm>
    </PageWrapper>
  );
};

export default AddTimetableComponent;