import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { fetchStudentsByCenterId } from "../../Api/center";
import {
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  StyledTable,
  NavButton,
  PageIndicator,
  TableWrapper,
  NavWrapper
} from "../UI/StyledComponents";

const HallTicketComponent = () => {
  const { centerInfo } = useSelector((state) => state.auth);
  const [timetable, setTimetable] = useState([]);
  const [center_id, setCenterId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const decoding = () => {
      if (centerInfo) {
        const decodedToken = jwtDecode(centerInfo);
        if (decodedToken && decodedToken.user && decodedToken.user.id) {
          setCenterId(decodedToken.user.id);
        }
      }
    };
    decoding();
  }, [centerInfo]);

  useEffect(() => {
    const fetchTimetableData = async () => {
      try {
        if (center_id) {
          const res = await fetchStudentsByCenterId(center_id);
          if (res.data.success) {
            setTimetable(res.data.data.timetables)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchTimetableData()
  }, [center_id])

  const handleView = async (timetableId, studentId) => {
    try {
      navigate(`/center/viewHallticket/${timetableId}/${studentId}`);
    } catch (error) {
      console.log(error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTimetable = timetable.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(timetable.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Hall Tickets </PageTitle>
        <PageSubtitle>View and manage hall tickets</PageSubtitle>
      </PageHeader>
      {timetable.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No Hallticket to display.</p>
        </div>
      ) : (
        <div>
          <TableWrapper>
            <StyledTable className="student-table min-w-full">
              <thead>
                <tr>
                  <th>Roll Number</th>
                  <th>Student Name</th>
                  <th>Examination</th>
                  <th>Session</th>
                  <th>Course</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentTimetable
                  .sort((a, b) => new Date(b.timetables[0]?.createdAt) - new Date(a.timetables[0]?.createdAt))
                  .map((studentTimetable, studentIndex) => (
                    studentTimetable.timetables.map((timetable, timetableIndex) => (
                      <tr key={`${studentIndex}-${timetableIndex}`} className="hover:bg-gray-100 border-b">
                        <td>{studentTimetable.rollNumber || 'N/A'}</td>
                        <td>{studentTimetable.student}</td>
                        <td>{timetable.exam.exam_name}</td>
                        <td>{timetable.session.session_month} - {timetable.session.session_year}</td>
                        <td>{studentTimetable.courseName}</td>
                        <td>
                          <button onClick={() => handleView(timetable.id, studentTimetable.studentId)}>
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        </td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>

              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>

              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}{" "}
        </div>
      )
      }
    </PageWrapper >
  );
};

export default HallTicketComponent;