import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const StudentSelectModal = ({ open, handleClose, handleSubmit, students, selectedStudent, setSelectedStudent }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          padding: 20,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          height: 450,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <Typography className="py-6" id="modal-modal-title" variant="h6" component="h2">
          Select a Student
        </Typography>

        <Autocomplete
          options={students}
          getOptionLabel={(option) => `${option.roll_number} - ${option.name}`}
          onChange={(event, newValue) => {
            setSelectedStudent(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="Search Student" />}
          ListboxProps={{
            style: {
              maxHeight: '200px',
              overflow: 'auto',
            },
          }}
        />

        <div style={{ marginTop: '50px' }}>
          <Button variant="contained" onClick={handleSubmit} disabled={!selectedStudent}>
            Submit
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default StudentSelectModal;
