import Sidebar from "../../../Components/Admin/Sidebar";
import TaskComponent from "../../../Components/Admin/TaskComponent";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";

const Task = () => {
    return (
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <TaskComponent />
            </MainContent>
        </Layout>
    )
}

export default Task