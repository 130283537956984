import Sidebar from "../../../Components/Student/Sidebar";
import CourseComponent from "../../../Components/Student/CourseComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";

const Course = () => {
  return (
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <CourseComponent />
      </MainContent>
    </Layout>
  );
};

export default Course;
