import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from "../../Assets/logo.png";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { fetchCenter, fetchResultById, fetchSubject } from '../../Api/student';
import { AddButton } from '../UI/StyledComponents';

const ViewResultComponent = () => {
  const { studentInfo } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [result, setResult] = useState([]);
  const [student, setStudent] = useState();
  const [centerId, setCenterId] = useState('');
  const [center, setCenter] = useState();
  const [subjectNames, setSubjectNames] = useState({});
  const resultRef = useRef();

  useEffect(() => {
    const decoding = () => {
      if (studentInfo) {
        const decodedToken = jwtDecode(studentInfo);
        if (decodedToken && decodedToken.id) {
          setStudent(decodedToken.user);
          setCenterId(decodedToken.user.center_id);
        }
      }
    };
    decoding();
  }, [studentInfo]);

  useEffect(() => {
    const fetchCenterData = async () => {
      try {
        if (centerId) {
          const res = await fetchCenter(centerId);
          if (res.data.success) {
            setCenter(res.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCenterData();
  }, [centerId]);

  useEffect(() => {
    const fetchSubjectNames = async () => {
      const subjectIdsToFetch = result?.results?.filter(subject => !subjectNames[subject.subjectId]);
      for (const subject of subjectIdsToFetch) {
        try {
          const subjectData = await getSubjectData(subject.subjectId);
          if (subjectData) {
            setSubjectNames(prevNames => ({
              ...prevNames,
              [subject.subjectId]: {
                name: subjectData.subject_name,
                maxMarks: subjectData.max_marks,
              },
            }));
          }
        } catch (error) {
          console.log(`Error fetching subject data for ID ${subject.subjectId}:`, error);
        }
      }
    };
    if (result?.results?.length > 0) {
      fetchSubjectNames();
    }
  }, [result, subjectNames]);

  const getSubjectData = async (subjectId) => {
    try {
      const res = await fetchSubject(subjectId);
      return res.data.data.subject;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchResultData = async () => {
      try {
        if (id) {
          const res = await fetchResultById(id);
          if (res.data.success) {
            let fetchedResult = res.data.data.result;
            if (typeof fetchedResult.results === 'string') {
              fetchedResult.results = JSON.parse(fetchedResult.results);
            }

            setResult(fetchedResult);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchResultData();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handlePrint = () => {
    const input = resultRef.current;
    html2canvas(input, {
      backgroundColor: "#ffffff",
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      pdf.save("result.pdf");
    });
  };

  return (
    <>
      <div
        ref={resultRef}
        className="a4-page bg-white border border-gray-400 mx-auto p-8 shadow-none mb-4"
      >
        <div className="flex justify-between items-center mb-4">
          <img src={logo} alt="Logo" className="w-16 h-16" />
          <div className="text-center flex-grow">
            <h1 className="text-lg font-bold uppercase">
              Karnataka State Council of Intermediate and Higher Education
            </h1>
            <h2 className="text-md font-medium uppercase">{result?.Exam?.exam_name}</h2>
            <h2 className="text-md font-semibold uppercase">Exam Results</h2>
          </div>
        </div>
        <div className="mb-4">
          <p className="text-md">Student Name : {student?.name}</p>
          <p className="text-md">Roll Number : {student?.roll_number}</p>
          <p className="text-md">Date of Birth : {formatDate(student?.date_of_birth)}</p>
          <p className="text-md">Center : {center?.center_name}</p>
        </div>

        <table className="w-full border-collapse mt-4 text-center a4-content">
          <thead>
            <tr>
              <th className="border border-gray-400 px-4 py-2">Subject</th>
              <th className="border border-gray-400 px-4 py-2">Theory Marks</th>
              <th className="border border-gray-400 px-4 py-2">Practical Marks</th>
              <th className="border border-gray-400 px-4 py-2">Maximum Marks</th>
              <th className="border border-gray-400 px-4 py-2">Total Marks</th>
              <th className="border border-gray-400 px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {result?.results?.map((subject, index) => (
              <tr key={index}>
                <td className="border border-gray-400 px-4 py-2">
                  {subjectNames[subject.subjectId]?.name || subject.subjectId}
                </td>
                <td className="border border-gray-400 px-4 py-2">{subject.theoryMarks}</td>
                <td className="border border-gray-400 px-4 py-2">{subject.practicalMarks}</td>
                <td className="border border-gray-400 px-4 py-2">
                  {subjectNames[subject.subjectId]?.maxMarks || 'N/A'}
                </td>
                <td className="border border-gray-400 px-4 py-2">{subject.totalMarks}</td>
                <td
                  className="border border-gray-400 px-4 py-2 font-bold"
                  style={{
                    color: subject.status === 'Pass' ? 'green' : subject.status === 'Fail' ? 'red' : 'black',
                  }}
                >
                  {subject.status}
                </td>
              </tr>
            ))}
            {result?.results?.length > 0 && (
              <>
                <tr>
                  <td colSpan="4" className="border border-gray-400 px-4 py-2 font-bold">
                    Total Marks
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {result?.results?.reduce((acc, subject) => acc + (subject.totalMarks || 0), 0)}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="4" className="border border-gray-400 px-4 py-2 font-bold">
                    Percentage
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {(() => {
                      const totalMarksObtained = result?.results?.reduce((acc, subject) => acc + (subject.totalMarks || 0), 0);
                      const totalMaxMarks = result?.results?.reduce(
                        (acc, subject) => acc + (subjectNames[subject.subjectId]?.maxMarks || 100), 0
                      );

                      return ((totalMarksObtained / totalMaxMarks) * 100).toFixed(2);
                    })()}%
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="4" className="border border-gray-400 px-4 py-2 font-bold">
                    Overall Status
                  </td>
                  <td
                    className="border border-gray-400 px-4 py-2 font-bold"
                    style={{
                      color: result.overallStatus === 'Pass' ? 'green' : 'red',
                    }}
                  >
                    {result.overallStatus}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <AddButton
        onClick={handlePrint}
      >
        Print Results
      </AddButton>
    </>
  );
};

export default ViewResultComponent;
