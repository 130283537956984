import React from 'react'
import { SidebarContainer, MainContent, Layout } from '../../../Components/UI/StyledComponents'
import DuplicatePrintRequestComponent from '../../../Components/Admin/DuplicatePrintRequestComponent'
import Sidebar from '../../../Components/Admin/Sidebar'
import NavComponent from '../../../Components/Admin/NavComponent'

const DuplicatePrintRequest = () => {
    return (
        <>
        <NavComponent/>
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <DuplicatePrintRequestComponent />
            </MainContent>
        </Layout>
        </>
    )
}

export default DuplicatePrintRequest