import Api from '../Service/axios'
import AuthEndpoint from '../Service/endpoints/authEndpoints'

export const login = async (email, password) => {
    try {
        const res = await Api.post(AuthEndpoint.login, { email, password });
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const logout=async()=>{
    try{
        const res = await Api.post(AuthEndpoint.logout)
        return res
    }catch(error){
        console.log(error);
    }
}