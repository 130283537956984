import React from 'react'
import MigrationCertificateComponent from '../../../Components/Center/MigrationCertificateComponent'
import Sidebar from '../../../Components/Center/Sidebar'
import { SidebarContainer, Layout, MainContent } from '../../../Components/UI/StyledComponents'
import NavComponent from '../../../Components/Center/NavComponent';

const MigrationCertificate = ({ isDuplicate }) => {
  return (
    <>
      <NavComponent />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <MigrationCertificateComponent isDuplicate={isDuplicate} />
        </MainContent>
      </Layout>
    </>
  )
}

export default MigrationCertificate
