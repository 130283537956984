import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { staffDashboard } from "../../Api/staff";

const DashboardComponent = () => {
  const { staffInfo } = useSelector((state) => state.auth);
  const [dashboard, setDashboard] = useState(null);
  const [staffId, setStaffId] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const decoding = async () => {
      if (staffInfo) {
        const decodedToken = jwtDecode(staffInfo);
        if (decodedToken && decodedToken.id) {
          setStaffId(decodedToken.id);
        }
      }
    };
    decoding();
  }, [staffInfo]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        if (staffId) {
          const res = await staffDashboard(staffId);
          if(res.data.success){
            setDashboard(res.data.data.dashboard)
          }
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError("Failed to load dashboard data");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [staffId]);

  if (loading) {
    return <p>Loading dashboard data...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="p-6 grid grid-cols-4 gap-6">
      <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
        <div>
          <h3 className="text-sm font-semibold text-gray-500">Total Tasks</h3>
          <p className="text-2xl font-bold text-gray-800">
            {dashboard ? dashboard.totalTasks : 0}
          </p>
          <p className="text-sm text-gray-500">My total tasks</p>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
        <div>
          <h3 className="text-sm font-semibold text-gray-500">
            Assigned Tasks
          </h3>
          <p className="text-2xl font-bold text-gray-800">
            {dashboard ? dashboard.assignedTasks : 0}
          </p>
          <p className="text-sm text-gray-500">My Assigned tasks</p>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
        <div>
          <h3 className="text-sm font-semibold text-gray-500">Pending Tasks</h3>
          <p className="text-2xl font-bold text-gray-800">
            {dashboard ? dashboard.pendingTasks : 0}
          </p>
          <p className="text-sm text-gray-500">My pending tasks</p>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
        <div>
          <h3 className="text-sm font-semibold text-gray-500">
            Completed Tasks
          </h3>
          <p className="text-2xl font-bold text-gray-800">
            {dashboard ? dashboard.completedTasks : 0}
          </p>
          <p className="text-sm text-gray-500">My completed tasks</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
