import React from 'react'
import { Layout,SidebarContainer,MainContent } from '../../../Components/UI/StyledComponents'
import Sidebar from '../../../Components/Center/Sidebar'
import BonafiedCertificateComponent from '../../../Components/Center/BonafiedCertificateComponent'
import NavComponent from '../../../Components/Center/NavComponent';


const BonafiedCertificate = ({isDuplicate}) => {
  return (
    <>
    <NavComponent/>
    <Layout>
    <SidebarContainer>
      <Sidebar/>
    </SidebarContainer>
    <MainContent>
      <BonafiedCertificateComponent isDuplicate={isDuplicate}/>
    </MainContent>
  </Layout>
   </>
  )
}

export default BonafiedCertificate
