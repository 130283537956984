import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchSubject, editSubject } from "../../Api/admin";
import {
  PageWrapper,
  PageHeader,
  PageSubtitle,
  PageTitle,
  DividerLine,
  AddComponentForm
} from "../UI/StyledComponents";

const EditSubjectComponent = () => {
  const [subject_name, setSubjectName] = useState("");
  const [subject_code, setSubjectCode] = useState("");
  const [description, setDescription] = useState("");
  const [max_marks, setMaxMarks] = useState(0);
  const [min_marks, setMinMarks] = useState(0);
  const [practical_marks, setPracticalMarks] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubjectData = async () => {
      try {
        const res = await fetchSubject(id);
        if (res.data.success) {
          setSubjectName(res.data.data.subject.subject_name);
          setSubjectCode(res.data.data.subject.subject_code);
          setDescription(res.data.data.subject.description);
          setMaxMarks(res.data.data.subject.max_marks);
          setMinMarks(res.data.data.subject.min_marks);
          setPracticalMarks(res.data.data.subject.practical_marks);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubjectData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const res = await editSubject(
          id,
          subject_name,
          subject_code,
          description,
          max_marks,
          min_marks,
          practical_marks
        );
        if (res.data.success) {
          toast.success("Updation successfull...");
          navigate("/admin/subject");
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle> Edit Subject Details</PageTitle>
        <PageSubtitle>Edit a subject details</PageSubtitle>
      </PageHeader>
     
        <AddComponentForm className="mt-4" onSubmit={handleSubmit}>
        <h2 className="text-2xl  mb-4">Edit Subject </h2>

           <DividerLine/>
          <div className="mb-4">
            <label
              htmlFor="subjectname"
            >
              Subject Name
            </label>
            <input
              type="text"
              id="subjectname"
              name="subjectname"
              value={subject_name}
              onChange={(e) => setSubjectName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="subjectcode"
            >
              Subject Code
            </label>
            <input
              type="text"
              id="subjectcode"
              name="subjectcode"
              value={subject_code}
              onChange={(e) => setSubjectCode(e.target.value)}
              required
            />
          </div>
          
          <div className="mb-4">
            <label
              htmlFor="maxmarks"
            >
              Total Marks
            </label>
            <input
              type="number"
              id="maxmarks"
              name="maxmarks"
              value={max_marks}
              onChange={(e) => setMaxMarks(parseInt(e.target.value))}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="minmarks"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Pass Marks
            </label>
            <input
              type="number"
              id="minmarks"
              name="minmarks"
              value={min_marks}
              onChange={(e) => setMinMarks(parseInt(e.target.value))}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="practicalmarks"
            >
              Practical Marks
            </label>
            <input
              type="number"
              id="practicalmarks"
              name="practicalmarks"
              value={practical_marks}
              onChange={(e) => setPracticalMarks(parseInt(e.target.value))}
              required
            />
          </div>
          <button
            type="submit"
          >
            Submit
          </button>
        </AddComponentForm>
    </PageWrapper>

  );
};

export default EditSubjectComponent;
