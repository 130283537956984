import Sidebar from "../../../Components/Admin/Sidebar";
import PasswordComponent from "../../../Components/Admin/PasswordComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from "../../../Components/Admin/NavComponent";

const Password = () => {
  return (
    <>
      <NavComponent />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <PasswordComponent />
        </MainContent>
      </Layout>
    </>
  );
};

export default Password;
