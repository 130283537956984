import Sidebar from "../../../Components/Staff/Sidebar";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import SessionsComponent from "../../../Components/Staff/SessionsComponent";
import NavComponent from "../../../Components/Staff/NavComponent";

const Sessions = () => {
  return (
    <>
      <NavComponent />
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <SessionsComponent />
      </MainContent>
    </Layout>
    </>
  );
};

export default Sessions;