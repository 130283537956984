import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchSession, editSession } from "../../Api/admin";
import {
    PageWrapper,
    PageHeader,
    PageSubtitle,
    PageTitle,
    DividerLine,
    AddComponentForm,
    Spacing20
} from "../UI/StyledComponents";

const EditSessionComponent = () => {
    const [session_year, setSessionYear] = useState("");
    const [session_month, setSessionMonth] = useState("");
    const [issue_date, setIssueDate] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const handleMonthChange = (e) => {
        setSessionMonth(e.target.value);
    };

    const currentYear = new Date().getFullYear();
    const startYear = currentYear;
    const endYear = 2030;
    const years = Array.from(
        { length: endYear - startYear + 1 },
        (_, i) => startYear + i
    );

    const handleYearChange = (e) => {
        setSessionYear(e.target.value);
    };

    const handleDateChange = (e) => {
        setIssueDate(e.target.value);
    };

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                if (id) {
                    const res = await fetchSession(id);
                    if (res.data.success) {
                        const { session_year, session_month, issue_date } =
                            res.data.data.session;
                        setSessionYear(session_year);
                        setSessionMonth(session_month);
                        setIssueDate(issue_date);
                    } else {
                        toast.error("Failed to fetch session data");
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error("An error occurred while fetching session data");
            }
        };
        fetchSessionData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                const res = await editSession(
                    id,
                    session_month,
                    session_year,
                    issue_date
                );
                if (res.data.success) {
                    toast.success("Updation successfull...");
                    navigate("/admin/session");
                } else if (!res.data.success) {
                    toast.error(res.data.message);
                }
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed to update session");
        }
    };

    return (
        <PageWrapper>
            <PageHeader>
                <PageTitle>Session Details </PageTitle>
                <PageSubtitle>View and manage session details</PageSubtitle>
            </PageHeader>
                
                <AddComponentForm className="mt-4" onSubmit={handleSubmit}>

                <h2 className="text-2xl  mb-4">Edit Session Details</h2>
                <DividerLine></DividerLine>
                <Spacing20 />

                    <div className="mb-4">
                        <label
                            htmlFor="month"
                        >
                            Session Month
                        </label>
                        <select
                            id="month"
                            name="month"
                            value={session_month}
                            onChange={handleMonthChange}
                            required
                        >
                            <option value="" disabled>
                                Select a month
                            </option>
                            {months.map((month) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="year"
                        >
                            Session Year
                        </label>
                        <select
                            id="year"
                            name="year"
                            value={session_year}
                            onChange={handleYearChange}
                            required
                        >
                            <option value="" disabled>
                                Select a year
                            </option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="date"
                        >
                            Hall Ticket Issue Date
                        </label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={issue_date}
                            onChange={handleDateChange}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                    >
                        Submit
                    </button>
                </AddComponentForm>
        </PageWrapper>
    )
}

export default EditSessionComponent