import Sidebar from "../../../Components/Admin/Sidebar";
import ViewStaffComponent from "../../../Components/Admin/ViewStaffComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from "../../../Components/Admin/NavComponent";

const ViewStaff = () => {
  return (
    <>
    <NavComponent/>
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <ViewStaffComponent />
      </MainContent>
    </Layout>
    </>
  );
};

export default ViewStaff;