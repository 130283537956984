import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { addExam } from "../../Api/admin";
import { AddComponentForm, PageHeader, PageSubtitle, PageTitle, SubHeading, PageWrapper, DividerLine, Spacing20 } from "../UI/StyledComponents";

const AddExamComponent = () => {
  const [exam_name, setExamName] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await addExam(exam_name);
      if (res.data.success) {
        toast.success("Successfully added examination...");
        navigate("/admin/exam");
      } else if (!res.data.success) {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Add Exam </PageTitle>
        <PageSubtitle>add exam form</PageSubtitle>
      </PageHeader>
      <AddComponentForm onSubmit={handleSubmit}>
        <SubHeading>Add Exam Form</SubHeading>
        <DividerLine />
        <Spacing20 />
        <div className="mb-4">
          <label htmlFor="examname" className="block text-sm font-medium text-gray-700">
            Exam Name
          </label>
          <input
            type="text"
            id="examname"
            name="examname"
            value={exam_name}
            onChange={(e) => setExamName(e.target.value)}
            required
          />
        </div>
        <button
          type="submit"
        >
          Submit
        </button>
      </AddComponentForm>
    </PageWrapper>
  );
};

export default AddExamComponent;