import React from 'react'
import { Layout, SidebarContainer, MainContent } from '../../../Components/UI/StyledComponents'
import Sidebar from '../../../Components/Center/Sidebar'
import MarksCardComponent from "../../../Components/Center/MarksCardComponent"
import NavComponent from '../../../Components/Center/NavComponent';

const MarksCard = ({isDuplicate}) => {
  return (
    <>
    <NavComponent/>
    <Layout>
    <SidebarContainer>
      <Sidebar/>
    </SidebarContainer>
    <MainContent>
      <MarksCardComponent isDuplicate={isDuplicate}/>
    </MainContent>
  </Layout>
  </>
  )
}

export default MarksCard
