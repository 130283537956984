import Sidebar from "../../../Components/Admin/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import CertificateBonafideComponent from "../../../Components/Admin/CertificateBonafideComponent";
import NavComponent from "../../../Components/Admin/NavComponent";

const CertificateBonafide = () => {
    return (
        <>
            <NavComponent />
            <Layout>
                <SidebarContainer>
                    <Sidebar />
                </SidebarContainer>
                <MainContent>
                    <CertificateBonafideComponent />
                </MainContent>
            </Layout>
        </>
    )
}

export default CertificateBonafide