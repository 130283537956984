import Sidebar from "../../../Components/Staff/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import AddTimetableComponent from "../../../Components/Staff/AddTimetableComponent";
import NavComponent from "../../../Components/Staff/NavComponent";

const AddTimetable = () => {
    return (
        <>
      <NavComponent />
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <AddTimetableComponent />
            </MainContent>
        </Layout>
        </>

    )
}

export default AddTimetable