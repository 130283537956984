import Api from '../Service/axios'
import CenterEndPoint from '../Service/endpoints/centerEndpoints'


export const getStudents = async () => {
    try {
        const res = await Api.get(CenterEndPoint.student);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const courses = async () => {
    try {
        const res = await Api.get(CenterEndPoint.course)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const addStudent = async (formData) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        const res = await Api.post(CenterEndPoint.upload, formData, config);
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const fetchStudent = async (id) => {
    try {
        const res = await Api.get(`${CenterEndPoint.student}/${id}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const editStudent = async (id, name, email, mobile_number, father_name, mother_name, address, admission_status, role_name, course_id) => {
    try {
        const res = await Api.put(`${CenterEndPoint.student}/${id}`, { name, email, mobile_number, father_name, mother_name, address, admission_status, role_name, course_id })
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchStudentsByCenterId = async (centerId) => {
    try {
        const res = await Api.get(`${CenterEndPoint.timetable}/fetchStudentsByCenterId/${centerId}`)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchCenter = async (id) => {
    try {
        const res = await Api.get(`${CenterEndPoint.center}/${id}`)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchOriginalRequests = async (request, centerId) => {
    try {
        const res = await Api.get(`${CenterEndPoint.printRequest}/original/${request}/${centerId}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchDuplicateRequests = async (request, centerId) => {
    try {
        const res = await Api.get(`${CenterEndPoint.printRequest}/duplicate/${request}/${centerId}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const createOriginalCertificate = async (rollNumber, request) => {
    try {
        const res = await Api.post(`${CenterEndPoint.printRequest}/original`, { rollNumber, request })
        return res
    } catch (error) {
        console.log(error)
    }
}

export const createDuplicateCertificate = async (rollNumber, request) => {
    try {
        const res = await Api.post(`${CenterEndPoint.printRequest}/duplicate`, { rollNumber, request })
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchDashboard = async (centerId) => {
    try {
        const res = await Api.get(`${CenterEndPoint.center}/dashboard/${centerId}`)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const newProfileCorrection = async (studentId, correctionField, correctionValue) => {
    try {
        const res = await Api.post(`${CenterEndPoint.correction}/profile`, { studentId, correctionField, correctionValue })
        return res
    } catch (error) {
        console.log(error)
    }
}

export const getProfileCorrections = async (centerId) => {
    try {
        const res = await Api.get(`${CenterEndPoint.correction}/profile/${centerId}`)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchResultsByCenterId = async (centerId) => {
    try {
        const res = await Api.get(`${CenterEndPoint.result}/getByCenterId/${centerId}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchStudentByRollNumber = async (rollNumber) => {
    try {
        const res = await Api.get(`${CenterEndPoint.student}/getStudentByRollNumber/${rollNumber}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchSubjectByCourse = async (courseId) => {
    try {
        const res = await Api.get(`${CenterEndPoint.subject}/getByCourse/${courseId}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchOldMarks = async (subjectId, resultId, rollNumber) => {
    try {
        const res = await Api.get(`${CenterEndPoint.result}/getOldMarks/${subjectId}/${resultId}/${rollNumber}`);
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const createResultCorrection = async (rollNumber, examId, selectedSubject, oldMarks, newMarks, reason) => {
    try {
        const res = await Api.post(`${CenterEndPoint.correction}/result`, { rollNumber, examId, selectedSubject, oldMarks, newMarks, reason })
        return res
    } catch (error) {
        console.log(error)
    }
}

export const getResultCorrectionsByCenterId = async (centerId) => {
    try {
        const res = await Api.get(`${CenterEndPoint.correction}/result/${centerId}`);
        return res
    } catch (error) {
        console.log(error)
    }
}