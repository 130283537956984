import React, { useState, useEffect, useRef } from 'react';
import { timetables, publishResults, checkAllResultsPublished, fetchStudentsByExam } from '../../Api/admin';
import Swal from 'sweetalert2';
import {
  Form,
  SearchWrapper,
  SearchInput,
  PageHeader,
  PageSubtitle,
  PageWrapper,
  PageTitle,
  StyledTable,
  TableWrapper,
  NavButton,
  NavWrapper,
  PageIndicator
} from '../UI/StyledComponents';

const PublishResultsComponent = () => {
  const [timetable, setTimetable] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [updatedCounts, setUpdatedCounts] = useState({});
  const [publishedStatus, setPublishedStatus] = useState({});
  const [itemsPerPage] = useState(20);
  const [loadingStatus, setLoadingStatus] = useState({});
  const [studentsByExam, setStudentsByExam] = useState({});
  const previousResultsRef = useRef([]);

  useEffect(() => {
    const fetchResultData = async () => {
      try {
        const res = await timetables();
        if (res.data.success) {
          const currentDate = new Date();
          const pastTimetables = res.data.data.timetables.filter(
            (timetable) => {
              const hasPastSubject = timetable.subjects.every((subject) => {
                const subjectExamDate = new Date(subject.exam_date);
                return subjectExamDate < currentDate;
              });
              return hasPastSubject;
            }
          );
          setTimetable(pastTimetables);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchResultData();
  }, []);

  useEffect(() => {
    const fetchStudentsData = async () => {
      const newStudentsByExam = {};
      for (const exam of timetable) {
        const examId = exam?.exam?.id;
        if (examId) {
          try {
            const res = await fetchStudentsByExam(examId);
            if (res.data.success) {
              newStudentsByExam[examId] = res.data.data.length;
            }
          } catch (error) {
            console.error(`Error fetching students for exam ${examId}:`, error);
          }
        }
      }
      setStudentsByExam(newStudentsByExam);
    };

    if (timetable.length > 0) {
      fetchStudentsData();
    }
  }, [timetable]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredResults = timetable.filter((val) => {
    return val.exam.exam_name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentResults = filteredResults.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredResults.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (JSON.stringify(previousResultsRef.current) === JSON.stringify(currentResults)) {
      return;
    }

    const fetchUpdatedResults = async () => {
      const newUpdatedCounts = {};
      const newPublishedStatus = {};
      for (const timetable of currentResults) {
        const examId = timetable?.exam_id;
        try {
          const publishStatusRes = await checkAllResultsPublished(examId);
          newUpdatedCounts[examId] = publishStatusRes.data.data.updatedCount;
          newPublishedStatus[examId] = publishStatusRes.data.data.allPublished;
        } catch (error) {
          console.error(`Error fetching results for exam ${examId}`, error);
        }
      }
      setUpdatedCounts(newUpdatedCounts);
      setPublishedStatus(newPublishedStatus);
    };

    fetchUpdatedResults();
    previousResultsRef.current = currentResults;
  }, [currentResults]);

  const handlePublish = async (examId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to publish the results for this exam?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, publish it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoadingStatus((prev) => ({ ...prev, [examId]: true }));
          await publishResults(examId);
          Swal.fire(
            "Published!",
            "The results have been published successfully.",
            "success"
          );
          setPublishedStatus((prevPublishedStatus) => ({
            ...prevPublishedStatus,
            [examId]: true,
          }));
        } catch (error) {
          console.error("Error publishing results:", error);
          Swal.fire(
            "Error!",
            "There was an issue publishing the results.",
            "error"
          );
        } finally {
          setLoadingStatus((prev) => ({ ...prev, [examId]: false }));
        }
      } else {
        Swal.fire("Cancelled", "Publishing was canceled", "error");
      }
    });
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Publish Results</PageTitle>
        <PageSubtitle>Manage and publish results</PageSubtitle>
      </PageHeader>
      <Form>
        <SearchWrapper>
          <SearchInput
            placeholder="Search by exam name"
            required
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchWrapper>
      </Form>
      {timetable.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No Results to display.</p>
        </div>
      ) : currentResults.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">
            No results found for the search query.
          </p>
        </div>
      ) : (
        <>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th>Exam name</th>
                  <th>Session</th>
                  <th>Total students</th>
                  <th>Updated results</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentResults.map((timetable, timetableIndex) => {
                  const examId = timetable?.exam?.id;
                  const totalStudents = studentsByExam[examId] || 0;
                  const updatedCount = updatedCounts[examId] || 0;
                  const isAllUpdated = totalStudents === updatedCount;
                  const isPublished = publishedStatus[examId];

                  return (
                    <tr key={timetableIndex}>
                      <td>{timetable?.exam?.exam_name}</td>
                      <td>
                        {timetable?.session?.session_month} -{" "}
                        {timetable?.session?.session_year}
                      </td>
                      <td>{totalStudents}</td>
                      <td>{updatedCount}</td>
                      <td>
                        {isAllUpdated ? (
                          isPublished ? (
                            <span style={{ color: "green" }}>Published</span>
                          ) : (
                            <button
                              onClick={() => handlePublish(examId)}
                              disabled={loadingStatus[examId]}
                              style={{
                                padding: '6px 12px',
                                backgroundColor: loadingStatus[examId] ? '#ccc' : '#fbd6bd',
                                color: loadingStatus[examId] ? '#666' : 'black',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                              }}
                            >
                              {loadingStatus[examId] ? "Publishing..." : "Publish"}
                            </button>
                          )
                        ) : (
                          <span style={{ color: "gray" }}>Incomplete</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>

            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default PublishResultsComponent;