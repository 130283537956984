import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { addCenter } from "../../Api/staff";
import {
  AddComponentForm,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  Spacing20,
  SubHeading,
  DividerLine,
} from "../UI/StyledComponents";

const AddCenterComponent = () => {
  const [center_name, setCenterName] = useState("");
  const [center_code, setCenterCode] = useState("");
  const [address, setAddress] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [coordinator_name, setCoordinatorName] = useState("");
  const [address_line1, setAddressLine1] = useState("");
  const [address_line2, setAddressLine2] = useState("");
  const [address_line3, setAddressLine3] = useState("");
  const [idProof, setIdProof] = useState(null);
  const [registrationCopy, setRegistrationCopy] = useState(null);
  const [centerPhotos, setCenterPhotos] = useState([]);

  const [isPasswordDisabled, setIsPasswordDisabled] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const role_name = "CENTER_MANAGER";
      const formData = new FormData();
      formData.append("center_name", center_name);
      formData.append("center_code", center_code);
      formData.append("address", address);
      formData.append("phone_number", phone_number);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("address_line1", address_line1);
      formData.append("address_line2", address_line2);
      formData.append("address_line3", address_line3);
      formData.append("coordinator_name", coordinator_name);
      formData.append("role_name", role_name);
      if (idProof) formData.append("idProof", idProof);
      if (registrationCopy) formData.append("registrationCopy", registrationCopy);
      centerPhotos.forEach(photo => formData.append(`centerPhotos`, photo));
      const res = await addCenter(formData);
      if (res.data.success) {
        toast.success("Successfully added center...");
        navigate("/staff/center");
      } else if (!res.data.success) {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    setPassword(value);
    setIsPasswordDisabled(value !== "");
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Center Registration</PageTitle>
        <PageSubtitle>Register a center</PageSubtitle>
      </PageHeader>

      <AddComponentForm onSubmit={handleSubmit}>
        <SubHeading>Center Registration Form</SubHeading>
        <DividerLine />
        <Spacing20 />

        <label htmlFor="center_name">Center Name</label>
        <input
          type="text"
          id="center_name"
          name="center_name"
          value={center_name}
          onChange={(e) => setCenterName(e.target.value)}
          required
        />

        <label htmlFor="center_code">Center Code</label>
        <input
          type="text"
          id="center_code"
          name="center_code"
          value={center_code}
          onChange={(e) => setCenterCode(e.target.value)}
          required
        />

        <label htmlFor="address">Address</label>
        <input
          type="text"
          id="address"
          name="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />

        <label htmlFor="phone_number">Phone</label>
        <input
          type="text"
          id="phone_number"
          name="phone_number"
          value={phone_number}
          onChange={handlePhoneNumberChange}
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isPasswordDisabled}
        />

        <label htmlFor="address_line1">Address For Marks Card</label>
        <input
          type="text"
          id="address_line1"
          name="address_line1"
          value={address_line1}
          onChange={(e) => setAddressLine1(e.target.value)}
          placeholder="Enter First Line Address"
        />
        <input
          type="text"
          id="address_line2"
          name="address_line2"
          value={address_line2}
          onChange={(e) => setAddressLine2(e.target.value)}
          placeholder="Enter Second Line Address"
        />
        <input
          type="text"
          id="address_line3"
          name="address_line3"
          value={address_line3}
          onChange={(e) => setAddressLine3(e.target.value)}
          placeholder="Enter Third Line Address"
        />

        <label htmlFor="coordinator_name">Co-Ordinator</label>
        <input
          type="text"
          id="coordinator_name"
          name="coordinator_name"
          value={coordinator_name}
          onChange={(e) => setCoordinatorName(e.target.value)}
          required
        />

        <label htmlFor="role_name">Role Name</label>
        <input
          type="text"
          id="role_name"
          name="role_name"
          value="CENTER MANAGER"
          disabled
        />

        <label htmlFor="idProof">Coordinator ID Proof</label>
        <input
          type="file"
          id="idProof"
          onChange={(e) => setIdProof(e.target.files[0])}
          required
        />

        <label htmlFor="registrationCopy">Registration Copy</label>
        <input
          type="file"
          id="registrationCopy"
          onChange={(e) => setRegistrationCopy(e.target.files[0])}
          required
        />

        <label htmlFor="centerPhotos">Center Photos (you can upload multiple)</label>
        <input
          type="file"
          id="centerPhotos"
          multiple
          onChange={(e) => setCenterPhotos([...e.target.files])}
          required
        />

        <button type="submit">Submit</button>
      </AddComponentForm>
    </PageWrapper>
  );
};

export default AddCenterComponent;
