import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchExam, editExam } from "../../Api/admin";
import {
  PageWrapper,
  PageHeader,
  PageSubtitle,
  PageTitle,
  DividerLine,
  Spacing20,
  AddComponentForm
} from "../UI/StyledComponents";

const EditExamComponent = () => {
  const [exam_name, setName] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        if (id) {
          const res = await fetchExam(id);
          if (res.data.success) {
            setName(res.data.data.exam.exam_name);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const res = await editExam(id, exam_name);
        if (res.data.success) {
          toast.success("Updation successful...");
          navigate("/admin/exam");
        } else if (!res.data.success) {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Exam Details</PageTitle>
        <PageSubtitle>View and manage exam details</PageSubtitle>
      </PageHeader>
        <AddComponentForm className="mt-4" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">Edit Exam Details</h2>
        <DividerLine/>
        <Spacing20 />
          <div className="mb-4">
            <label
              htmlFor="examname"
            >
              Exam Name
            </label>
            <input
              type="text"
              id="examname"
              name="examname"
              value={exam_name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
          >
            Submit
          </button>
        </AddComponentForm>
    </PageWrapper>
  );
};

export default EditExamComponent;