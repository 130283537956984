import React, { useState, useEffect } from 'react';
import { timetables, fetchStudentsByExam } from '../../Api/admin';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  PageHeader,
  PageWrapper,
  PageTitle,
  PageSubtitle,
  Form,
  SearchInput,
  SearchWrapper,
  TableWrapper,
  StyledTable,
  NavButton,
  NavWrapper,
  PageIndicator
} from "../UI/StyledComponents";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { fetchCourse, fetchSubjectsOfCourses } from '../../Api/student';
import { updateResults, fetchOverallStatus } from '../../Api/admin';

const ResultsComponent = () => {
  const [timetable, setTimetables] = useState([]);
  const [studentsByExam, setStudentsByExam] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedExam, setSelectedExam] = useState(null);
  const [studentResults, setStudentResults] = useState({});
  const [resultStatuses, setResultStatuses] = useState({});

  const [course_id, setCourseId] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);

  useEffect(() => {
    const fetchCourseData = async () => {
      if (course_id) {
        const res = await fetchCourse(course_id);
        if (res.data.success) {
          const courseData = res.data.data.course;
          const subjectIds = courseData?.subjectIds;
          const matchedSubjects = subjectIds
            .map((id) => allSubjects.find((subject) => subject.id === id))
            .filter((subject) => subject);

          setSubjects(matchedSubjects);
        }
      }
    };
    fetchCourseData();
  }, [course_id, allSubjects]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        if (course_id) {
          const res = await fetchSubjectsOfCourses(course_id);
          if (res.data.success) {
            setAllSubjects(res.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubjects();
  }, [course_id]);

  const checkResultStatus = async (examId, rollNumber) => {
    try {
      const res = await fetchOverallStatus(examId, rollNumber);
      if (res?.data?.success) {
        setResultStatuses((prevStatuses) => ({
          ...prevStatuses,
          [`${examId}-${rollNumber}`]: !!res.data.data,
        }));
      }
    } catch (error) {
      toast.error("An error occurred while checking result status.");
    }
  };

  useEffect(() => {
    const loadTimetables = async () => {
      try {
        const res = await timetables();
        if (res.data.success) {
          const pastTimetables = res.data.data.timetables.filter(
            (timetable) => {
              const examDate = new Date(timetable.subjects[0].exam_date);
              return examDate < new Date();
            }
          );
          setTimetables(pastTimetables);
        }
      } catch (error) {
        console.error('Error fetching timetables:', error);
      }
    };

    loadTimetables();
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      const studentsData = {};
      for (const item of timetable) {
        try {
          const res = await fetchStudentsByExam(item.exam.id);
          if (res.data.success) {
            studentsData[item.exam.id] = res.data.data;
            res.data.data.forEach(student => {
              checkResultStatus(item.exam.id, student.roll_number);
            });
          }
        } catch (error) {
          console.error(`Error fetching students for exam ${item.exam.id}:`, error);
        }
      }
      setStudentsByExam(studentsData);
    };
    if (timetable.length > 0) {
      fetchStudents();
    }
  }, [timetable]);

  const handleAdd = (student, examId) => {
    setSelectedStudent(student);
    setCourseId(student.course_id)
    setSelectedExam(examId);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
    setSelectedExam(null);
    setStudentResults({});
  }

  const handleResultChange = (e, subject, type) => {
    const value = type === "absent" ? e.target.checked : e.target.value;
    setStudentResults((prevResults) => {
      const updatedResults = { ...prevResults, [`${subject}_${type}`]: value };
      if (type === "absent" && value) {
        updatedResults[`${subject}_theory`] = 0;
        updatedResults[`${subject}_practical`] = 0;
      }
      return updatedResults;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const timetableForStudent = timetable.find(
        (t) => t.exam.id === selectedExam
      );
      if (!timetableForStudent || !timetableForStudent.exam?.id) {
        toast.error("Exam ID not found for the selected student.");
        return;
      }
      const examId = selectedExam;
      const resultsData = subjects.map((subject) => {
        const theoryMarks = parseInt(studentResults[`${subject.subject_name}_theory`], 10) || 0;
        const practicalMarks = parseInt(studentResults[`${subject.subject_name}_practical`], 10) || 0;
        const totalMarks = theoryMarks + practicalMarks;
        const isAbsent = studentResults[`${subject.subject_name}_absent`] || false;
        return {
          subjectId: subject.id,
          theoryMarks,
          practicalMarks,
          totalMarks,
          isAbsent,
          status: isAbsent || totalMarks < subject.min_marks ? "Fail" : "Pass",
        };
      });
      const overallStatus = resultsData.every((result) => result.status === "Pass") ? "Pass" : "Fail";
      const payload = {
        roll_number: selectedStudent.roll_number,
        examId: examId,
        results: resultsData,
        overallStatus: overallStatus,
      };
      const res = await updateResults(payload);
      if (res) {
        toast.success("Results updated successfully!");
      } else {
        toast.error("Failed to update results.");
      }
      handleClose();
    } catch (error) {
      console.error("Error submitting results:", error);
      toast.error("An error occurred while submitting results.");
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredResults = timetable.filter((val) => {
    return val.exam.exam_name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentResults = filteredResults.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredResults.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Update Results</PageTitle>
        <PageSubtitle>Update the results of students </PageSubtitle>
      </PageHeader>
      <Form>
        <SearchWrapper>
          <SearchInput
            placeholder="Search by exam"
            required
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchWrapper>
      </Form>
      {
        timetable.length === 0 ? (
          <div className="text-center">
            <p className="text-gray-700 mb-4">No timetables to display.</p>
          </div>
        ) : currentResults.length === 0 ? (
          <div className="text-center">
            <p className="text-gray-700 mb-4">No results found.</p>
          </div>
        ) : (
          <>
            <TableWrapper>
              <StyledTable>
                <thead>
                  <tr>
                    <th>Roll Number</th>
                    <th>Name</th>
                    <th>Exam</th>
                    <th>Session</th>
                    <th>Course</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentResults.map((val) => {
                    const examId = val.exam.id;
                    const students = studentsByExam[examId] || [];
                    return students.map((student, studentIndex) => (
                      <tr key={studentIndex}>
                        <td>{student.roll_number}</td>
                        <td>{student.name}</td>
                        <td>{val?.exam?.exam_name}</td>
                        <td>{val?.session?.session_month} - {val?.session?.session_year}</td>
                        <td>{student?.course?.course_name}</td>
                        <td>
                          {resultStatuses[`${examId}-${student.roll_number}`] ? (
                            'Updated'
                          ) : (
                            <button onClick={() => handleAdd(student, examId)}>
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          )}
                        </td>
                      </tr>
                    ));
                  })}
                </tbody>
              </StyledTable>
            </TableWrapper>
            {totalPages > 1 && (
              <NavWrapper>
                <NavButton
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </NavButton>
                <PageIndicator>
                  Page {currentPage} of {totalPages}
                </PageIndicator>
                <NavButton
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </NavButton>
              </NavWrapper>
            )}
          </>
        )
      }

      <Dialog open={isModalOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Update Marks for {selectedStudent?.name}</DialogTitle>
        <DialogContent>
          {selectedStudent && (
            <div>
              <Typography variant="h6">
                Roll Number: {selectedStudent.roll_number}
              </Typography>
              <table
                style={{
                  width: "100%",
                  marginTop: "1em",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Subject
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Absent
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Theory Marks
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Practical Marks
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {subjects.map((subject) => {
                    const theoryMarks = parseInt(studentResults[`${subject.subject_name}_theory`], 10) || 0;
                    const practicalMarks = parseInt(studentResults[`${subject.subject_name}_practical`], 10) || 0;
                    const totalMarks = theoryMarks + practicalMarks;
                    const isFailed = totalMarks < subject.min_marks;

                    return (
                      <tr key={subject.subject_name}>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {subject.subject_name.charAt(0).toUpperCase() + subject.subject_name.slice(1)}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={studentResults[`${subject.subject_name}_absent`] || false}
                                onChange={(e) =>
                                  handleResultChange(e, subject.subject_name, "absent")
                                }
                                color="primary"
                              />
                            }
                            label="Absent"
                          />
                        </td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                          <TextField
                            label={`Max: ${subject.max_marks - subject.practical_marks}`}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            type="number"
                            value={theoryMarks}
                            onChange={(e) =>
                              handleResultChange(e, subject.subject_name, "theory")
                            }
                            disabled={studentResults[`${subject.subject_name}_absent`]}
                            inputProps={{ max: subject.max_marks - subject.practical_marks }}
                          />
                        </td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {subject.practical_marks > 0 ? (
                            <TextField
                              label={`Max: ${subject.practical_marks}`}
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              type="number"
                              value={practicalMarks}
                              onChange={(e) =>
                                handleResultChange(e, subject.subject_name, "practical")
                              }
                              disabled={
                                studentResults[`${subject.subject_name}_absent`] ||
                                subject.practical_marks === 0
                              }
                              inputProps={{ max: subject.practical_marks }}
                            />
                          ) : (
                            <span>N/A</span>
                          )}
                        </td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {totalMarks} {isFailed && "(Fail)"}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Total Marks
                    </td>
                    <td
                      colSpan={4}
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {subjects.reduce(
                        (acc, subject) =>
                          acc +
                          (parseInt(studentResults[`${subject.subject_name}_theory`], 10) || 0) +
                          (parseInt(studentResults[`${subject.subject_name}_practical`], 10) || 0),
                        0
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Total Percentage
                    </td>
                    <td
                      colSpan={4}
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {(
                        (subjects.reduce(
                          (acc, subject) =>
                            acc +
                            (parseInt(
                              studentResults[`${subject.subject_name}_theory`],
                              10
                            ) || 0) +
                            (parseInt(
                              studentResults[`${subject.subject_name}_practical`],
                              10
                            ) || 0),
                          0
                        ) /
                          subjects.reduce(
                            (acc, subject) => acc + subject.max_marks,
                            0
                          )) *
                        100
                      ).toFixed(2)}%
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Overall Status
                    </td>
                    <td
                      colSpan={4}
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {subjects.every(
                        (subject) =>
                          (parseInt(studentResults[`${subject.subject_name}_theory`], 10) || 0) +
                          (parseInt(studentResults[`${subject.subject_name}_practical`], 10) || 0) >=
                          subject.min_marks
                      )
                        ? "Pass"
                        : "Fail"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

    </PageWrapper>
  )
}

export default ResultsComponent
