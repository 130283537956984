const adminRoutes = {
    staff: "/staff",
    center: "/centers",
    student: "/students",
    subject: "/subjects",
    course: "/courses",
    exam: "/exams",
    session: "/sessions",
    timetable: "/timetables",
    result: "/results",
    printRequest:"/printRequests",
    admin:"/admin",
    correction:"/corrections",
    task:"/tasks",
    upload:'/upload'

}

export default adminRoutes