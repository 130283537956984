import Sidebar from "../../../Components/Admin/Sidebar";
import EditTimetableComponent from "../../../Components/Admin/EditTimetableComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from '../../../Components/Admin/NavComponent'

const EditTimetable = () => {
  return (
    <>
      <NavComponent />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <EditTimetableComponent />
        </MainContent>
      </Layout>
    </>
  );
};

export default EditTimetable;
