import { Route, Routes } from 'react-router-dom';
import Login from '../Components/Common/Login';
import Dashboard from '../Pages/Admin/Dashboard/Dashboard';
import Staff from '../Pages/Admin/Staff/Staff';
import AddStaff from '../Pages/Admin/Staff/AddStaff';
import EditStaff from '../Pages/Admin/Staff/EditStaff';
import Center from '../Pages/Admin/Center/Center';
import EditCenter from '../Pages/Admin/Center/EditCenter';
import AddCenter from '../Pages/Admin/Center/AddCenter';
import Student from '../Pages/Admin/Student/Student';
import Subject from '../Pages/Admin/Subject/Subject';
import AddSubject from '../Pages/Admin/Subject/AddSubject';
import EditSubject from '../Pages/Admin/Subject/EditSubject';
import Course from '../Pages/Admin/Course/Course';
import AddCourse from '../Pages/Admin/Course/AddCourse';
import EditCourse from '../Pages/Admin/Course/EditCourse';
import Session from '../Pages/Admin/Session/Session';
import EditSession from '../Pages/Admin/Session/EditSession';
import AddSession from '../Pages/Admin/Session/AddSession';
import Exam from '../Pages/Admin/Exam/Exam';
import AddExam from '../Pages/Admin/Exam/AddExam';
import EditExam from '../Pages/Admin/Exam/EditExam';
import Timetable from '../Pages/Admin/Timetable/Timetable';
import AddTimetable from '../Pages/Admin/Timetable/AddTimetable';
import EditTimetable from '../Pages/Admin/Timetable/EditTimetable';
import ViewTimetable from '../Pages/Admin/Timetable/ViewTimetable';
import UpdateResults from '../Pages/Admin/Result/UpdateResults';
import ViewResult from '../Pages/Admin/Result/ViewResult';
import PublishResult from '../Pages/Admin/Result/PublishResult';
import Task from '../Pages/Admin/Task/Task';
import Password from '../Pages/Admin/Password/Password';
import AdminLoggedIn from '../Components/Admin/AdminLoggedIn';
import AdminLoggedOut from '../Components/Admin/AdminLoggedOut';
import Error from '../Pages/Admin/Error/Error';
import ProfileCorrection from '../Pages/Admin/Correction/ProfileCorrection';
import PrintRequest from '../Pages/Admin/Request/PrintRequest';
import DuplicatePrintRequest from '../Pages/Admin/Request/DuplicatePrintRequest';
import CertificateBonafide from '../Pages/Admin/Certificate/CertificateBonafide';
import CertificateMigration from '../Pages/Admin/Certificate/CertificateMigration';
import CertificatePUC from '../Pages/Admin/Certificate/CertificatePUC';
import CertificateSSLC from '../Pages/Admin/Certificate/CertificateSSLC';
import CertificateStudy from '../Pages/Admin/Certificate/CertificateStudy';
import ViewCenter from '../Pages/Admin/Center/ViewCenter';
import ViewStudent from '../Pages/Admin/Student/ViewStudent';
import ViewStaff from '../Pages/Admin/Staff/ViewStaff';
import AddStudent from '../Pages/Admin/Student/AddStudent';

const adminRoute = () => {
  return (
    <Routes>
      <Route path="" element={<AdminLoggedOut />}>
        <Route path="/" element={<Login />} />
      </Route>
      <Route path='' element={<AdminLoggedIn />}>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='staff' element={<Staff />} />
        <Route path='addStaff' element={<AddStaff />} />
        <Route path='editStaff/:id' element={<EditStaff />} />
        <Route path='viewstaff/:id' element={<ViewStaff />} />
        <Route path='center' element={<Center />} />
        <Route path='addCenter' element={<AddCenter />} />
        <Route path='editCenter/:id' element={<EditCenter />} />
        <Route path='viewcenter/:id' element={<ViewCenter />} />
        <Route path='student' element={<Student />} />
        <Route path='addstudent' element={<AddStudent />} />
        <Route path='viewStudent/:id' element={<ViewStudent />} />
        <Route path='subject' element={<Subject />} />
        <Route path='addSubject' element={<AddSubject />} />
        <Route path='editSubject/:id' element={<EditSubject />} />
        <Route path='course' element={<Course />} />
        <Route path='addCourse' element={<AddCourse />} />
        <Route path='editCourse/:id' element={<EditCourse />} />
        <Route path='session' element={<Session />} />
        <Route path='addSession' element={<AddSession />} />
        <Route path='editSession/:id' element={<EditSession />} />
        <Route path='exam' element={<Exam />} />
        <Route path='addExam' element={<AddExam />} />
        <Route path='editExam/:id' element={<EditExam />} />
        <Route path='timetable' element={<Timetable />} />
        <Route path='editTimetable/:id' element={<EditTimetable />} />
        <Route path='addTimetable' element={<AddTimetable />} />
        <Route path='viewTimetable/:id' element={<ViewTimetable />} />
        <Route path='corrections' element={<ProfileCorrection />} />
        <Route path='result/updateResult' element={<UpdateResults />} />
        <Route path='viewResults/:examId/:rollNumber' element={<ViewResult />} />
        <Route path='result/publishResult' element={<PublishResult />} />
        <Route path='requests/original' element={<PrintRequest />} />
        <Route path='requests/duplicate' element={<DuplicatePrintRequest />} />
        <Route path='viewMigrationCertificate/:id/:printNumber' element={<CertificateMigration />} />
        <Route path='viewStudyCertificate/:id/:printNumber' element={<CertificateStudy />} />
        <Route path='viewBonafideCertificate/:id/:printNumber' element={<CertificateBonafide />} />
        <Route path='viewSslcCertificate/:id/:printNumber' element={<CertificateSSLC />} />
        <Route path='viewPucCertificate/:id/:printNumber' element={<CertificatePUC />} />
        <Route path='taskManager' element={<Task />} />
        <Route path='password' element={<Password />} />
      </Route>
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default adminRoute;