const centerRoute = {
    studentByCenter: '/students/studentsByCenter',
    course: "/courses",
    student: "/students",
    getCenterId:'/centers/getById',
    upload:'/upload',
    timetable: "/timetables",
    center: "/centers",
    printRequest:"/printRequests",
    correction:"/corrections",
    result: "/results",
    subject: "/subjects",
}

export default centerRoute;