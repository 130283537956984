import Sidebar from "../../../Components/Staff/Sidebar"
import EditTimetableComponent from "../../../Components/Staff/EditTimetableComponent"
import { SidebarContainer, MainContent, Layout } from "../../../Components/UI/StyledComponents"
import NavComponent from "../../../Components/Staff/NavComponent"

const EditTimetable = () => {
    return (
        <>
            <NavComponent />
            <Layout>
                <SidebarContainer>
                    <Sidebar />
                </SidebarContainer>
                <MainContent>
                    <EditTimetableComponent />
                </MainContent>
            </Layout>
        </>
    )
}

export default EditTimetable