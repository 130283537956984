import React, { useRef, useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../Assets/logo.png";
import { useParams } from "react-router-dom";
import { fetchTimetable } from "../../Api/admin";

const ViewTimetableComponent = () => {
  const [timetable, setTimetable] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const hallTicketRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    const fetchTimetableData = async () => {
      try {
        if (id) {
          const res = await fetchTimetable(id);
          if (res.data.success) {
            const timetableData = res.data.data.timetable;
            setTimetable(timetableData);
            setSubjects(timetableData.subjects);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTimetableData();
  }, [id]);

  const formatTime = (time) => {
    if (!time) return "";
    const [hour, minute] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const handlePrint = () => {
    const input = hallTicketRef.current;
    const customStyles = `
      <style>
        body {
          font-family: Arial, sans-serif;
        }
        .pdf-container {
          width: 210mm;
          padding: 20px;
          box-sizing: border-box;
        }
        .pdf-header {
          text-align: center;
          margin-bottom: 10px;
        }
        .pdf-header img {
          width: 50px;
          height: 50px;
        }
        .pdf-title {
          font-size: 16px;
          font-weight: bold;
          margin-top: 5px;
        }
        .pdf-subtitle {
          font-size: 14px;
          margin-top: 5px;
        }
        .pdf-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }
        .pdf-table th, .pdf-table td {
          border: 1px solid #ccc;
          padding: 8px;
          text-align: center;
        }
        .pdf-table th {
          background-color: #f0f0f0;
        }
      </style>
    `;
    console.log(customStyles)
    html2canvas(input, {
      backgroundColor: "#ffffff",
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
      pdf.save("timetable.pdf");
    });
  };


  return (
    <div className="flex justify-center items-center flex-col p-5 bg-white">
      <div
        ref={hallTicketRef}
        className="bg-white border border-gray-400 w-[210mm] max-h-[500px] p-8 shadow-none overflow-auto"
      >
        <div className="flex justify-between items-center">
          <img src={logo} alt="Logo" className="w-16 h-16" />
          <div className="text-center">
            <h1 className="text-lg font-bold uppercase">
              Karnataka State Council of Intermediate and Higher Education
            </h1>
            <h2 className="text-md font-semibold uppercase">Timetable</h2>
            <h3 className="text-md">
              {timetable?.session?.session_month} - {timetable?.session?.session_year}
            </h3>
          </div>
        </div>

        <table className="w-full border-collapse mt-4">
          <thead>
            <tr>
              <th className="border border-gray-400 px-4 py-2">Subject</th>
              <th className="border border-gray-400 px-4 py-2">Date</th>
              <th className="border border-gray-400 px-4 py-2">Start time</th>
              <th className="border border-gray-400 px-4 py-2">
                End time
              </th>
            </tr>
          </thead>
          <tbody>
            {subjects.map((val, index) => (
              <tr key={index}>
                <td className="border border-gray-400 px-4 py-2 text-center">
                  {index + 1}
                </td>
                <td className="border border-gray-400 px-4 py-2 text-center">
                  {val.exam_date}
                </td>
                <td className="border border-gray-400 px-4 py-2 text-center">
                  {formatTime(val.from_time)}
                </td>
                <td className="border border-gray-400 px-4 py-2 text-center">
                  {formatTime(val.to_time)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        onClick={handlePrint}
        className="mt-5 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
      >
        Print Timetable
      </button>
    </div>
  );
};

export default ViewTimetableComponent;