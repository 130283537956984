import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { fetchSession, editSession } from "../../Api/admin";
import {
  PageWrapper,
  PageHeader,
  PageSubtitle,
  PageTitle,
  DividerLine,
  AddComponentForm,
} from "../UI/StyledComponents";

const EditSessionComponent = () => {
  const [session_year, setSessionYear] = useState("");
  const [session_month, setSessionMonth] = useState("");
  const [issue_date, setIssueDate] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const startYear = currentYear;
  const endYear = 2030;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );

  const handleYearChange = (e) => {
    setSessionYear(e.target.value);
  };

  const handleDateChange = (e) => {
    setIssueDate(e.target.value);
  };

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        if (id) {
          const res = await fetchSession(id);
          if (res.data.success) {
            const { session_year, session_month, issue_date } = res.data.data.session;
            setSessionYear(session_year);
            setSessionMonth(session_month);
            setIssueDate(issue_date);
          } else {
            toast.error("Failed to fetch session data");
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("An error occurred while fetching session data");
      }
    };
    fetchSessionData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const res = await editSession(id, session_month, session_year, issue_date);
        if (res.data.success) {
          toast.success("Updation successful...");
          navigate("/admin/session");
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to update session");
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Session Details </PageTitle>
        <PageSubtitle>View and manage session details</PageSubtitle>
      </PageHeader>

      <AddComponentForm className="mt-4" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">Edit Session Details</h2>
        <DividerLine />

        <div className="mb-4">
          <label htmlFor="month" >
            Session Month
          </label>
          <input
            type="text"
            id="month"
            name="month"
            value={session_month}
            onChange={(e) => setSessionMonth(e.target.value)}
            placeholder="Enter month (e.g., January)"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="year" >
            Session Year
          </label>
          <select
            id="year"
            name="year"
            value={session_year}
            onChange={handleYearChange}
            required
          >
            <option value="" disabled>
              Select a year
            </option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="date" >
            Marks card Issue Date
          </label>
          <input
            type="date"
            id="date"
            name="date"
            value={issue_date}
            onChange={handleDateChange}
            required
          />
        </div>

        <button
          type="submit"
        >
          Submit
        </button>
      </AddComponentForm>
    </PageWrapper>
  );
};

export default EditSessionComponent;
