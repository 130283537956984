import Sidebar from "../../../Components/Staff/Sidebar";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import ViewTimetableComponent from "../../../Components/Staff/ViewTimetableComponent";
import NavComponent from "../../../Components/Staff/NavComponent";

const ViewTimetable = () => {
  return (
    <>
      <NavComponent />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <ViewTimetableComponent />
        </MainContent>
      </Layout>
    </>
  )
}

export default ViewTimetable