import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { fetchExam, fetchOverallStatus, fetchStudent, fetchSubject } from '../../Api/admin';
import logo from "../../Assets/logo.png";

const ViewResultComponent = () => {
  const { examId, rollNumber } = useParams();
  const [results, setResults] = useState();
  const [student, setStudent] = useState();
  const [exam, setExam] = useState();
  const [subjectNames, setSubjectNames] = useState({});
  const resultRef = useRef();

  const fetchSubjectNames = useCallback(async (subjectId) => {
    const subjectData = await getSubjectData(subjectId);
    if (subjectData) {
      setSubjectNames((prevNames) => ({
        ...prevNames,
        [subjectId]: {
          name: subjectData.subject_name,
          maxMarks: subjectData.max_marks,
        },
      }));
    }
  }, []);

  useEffect(() => {
    if (results?.results?.length > 0) {
      results.results.forEach((subject) => {
        if (!subjectNames[subject.subjectId]) {
          fetchSubjectNames(subject.subjectId);
        }
      });
    }
  }, [results, subjectNames, fetchSubjectNames]);

  useEffect(() => {
    const fetchResultData = async () => {
      try {
        if (examId && rollNumber) {
          const res = await fetchOverallStatus(examId, rollNumber);
          if (res?.data?.success) {
            const resultData = res.data.data.result;
            const allSubjectsPassed = resultData.results.every(
              (subject) => subject.status === 'Pass'
            );
            const overallStatus = allSubjectsPassed ? 'Pass' : 'Fail';

            setResults({
              ...resultData,
              overallStatus: overallStatus,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchResultData();
  }, [examId, rollNumber]);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        if (rollNumber) {
          const res = await fetchStudent(rollNumber);
          if (res.data.success) {
            setStudent(res.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudentData();
  }, [rollNumber]);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        if (examId) {
          const res = await fetchExam(examId);
          if (res.data.success) {
            setExam(res.data.data.exam);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamData();
  }, [examId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getSubjectData = async (subjectId) => {
    try {
      const res = await fetchSubject(subjectId);
      return res.data.data.subject;
    } catch (error) {
      console.log(error);
    }
  };

  const getClassObtained = (percentage) => {
    if (percentage >= 85) return "Distinction";
    if (percentage >= 60) return "First Class";
    if (percentage >= 50) return "Second Class";
    return "Pass";
  };

  return (
    <div
      ref={resultRef}
      className="bg-white border border-gray-400 w-[80%] max-w-[900px] mx-auto p-8 shadow-none"
    >
      <div className="flex justify-between items-center mb-4">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        <div className="text-center flex-grow">
          <h1 className="text-lg font-bold uppercase">
            Karnataka State Council of Intermediate and Higher Education
          </h1>
          <h2 className="text-md font-semibold uppercase">Exam Results</h2>
        </div>
      </div>
      <div className="mb-4">
        <p className="text-md">Student Name : {student?.name}</p>
        <p className="text-md">Roll Number : {rollNumber}</p>
        <p className="text-md">Date of Birth : {formatDate(student?.date_of_birth)}</p>
        <p className="text-md">Center : {student?.center?.center_name}</p>
        <p className="text-md">Exam : {exam?.exam_name}, {exam?.session?.session_month}-{exam?.session?.session_year}</p>
      </div>

      <table className="w-full border-collapse mt-4 text-center">
        <thead>
          <tr>
            <th className="border border-gray-400 px-4 py-2">Subject</th>
            <th className="border border-gray-400 px-4 py-2">Theory Marks</th>
            <th className="border border-gray-400 px-4 py-2">Practical Marks</th>
            <th className="border border-gray-400 px-4 py-2">Total Marks</th>
            <th className="border border-gray-400 px-4 py-2">Maximum Marks</th>
            <th className="border border-gray-400 px-4 py-2">Status</th>
          </tr>
        </thead>
        <tbody>
          {results?.results?.map((subject, index) => (
            <tr key={index}>
              <td className="border border-gray-400 px-4 py-2">
                {subjectNames[subject.subjectId]?.name || subject.subjectId}
              </td>
              <td className="border border-gray-400 px-4 py-2">{subject.theoryMarks}</td>
              <td className="border border-gray-400 px-4 py-2">{subject.practicalMarks}</td>
              <td className="border border-gray-400 px-4 py-2">{subject.totalMarks}</td>
              <td className="border border-gray-400 px-4 py-2">
                {subjectNames[subject.subjectId]?.maxMarks || 'N/A'}
              </td>
              <td
                className="border border-gray-400 px-4 py-2 font-bold"
                style={{
                  color: subject.status === 'Pass' ? 'green' : subject.status === 'Fail' ? 'red' : 'black',
                }}
              >
                {subject.status}
              </td>
            </tr>
          ))}
          {results?.results?.length > 0 && (
            <>
              <tr>
                <td colSpan="4" className="border border-gray-400 px-4 py-2 font-bold">
                  Total Marks:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  {results?.results?.reduce((acc, subject) => acc + (subject.totalMarks || 0), 0)}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="4" className="border border-gray-400 px-4 py-2 font-bold">
                  Percentage:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  {(() => {
                    const totalMarksObtained = results?.results?.reduce((acc, subject) => acc + (subject.totalMarks || 0), 0);
                    const totalMaxMarks = results?.results?.reduce(
                      (acc, subject) => acc + (subjectNames[subject.subjectId]?.maxMarks || 100), 0
                    );

                    return ((totalMarksObtained / totalMaxMarks) * 100).toFixed(2);
                  })()}%
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="4" className="border border-gray-400 px-4 py-2 font-bold">
                  Class Obtained:
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  {(() => {
                    const totalMarksObtained = results?.results?.reduce((acc, subject) => acc + (subject.totalMarks || 0), 0);
                    const totalMaxMarks = results?.results?.reduce(
                      (acc, subject) => acc + (subjectNames[subject.subjectId]?.maxMarks || 100), 0
                    );
                    const percentage = (totalMarksObtained / totalMaxMarks) * 100;
                    return getClassObtained(percentage);
                  })()}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="4" className="border border-gray-400 px-4 py-2 font-bold">
                  Overall Status:
                </td>
                <td
                  className="border border-gray-400 px-4 py-2 font-bold"
                  style={{
                    color: results.overallStatus === 'Pass' ? 'green' : 'red',
                  }}
                >
                  {results.overallStatus}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ViewResultComponent;

