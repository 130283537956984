import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faPrint } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import {
  PageWrapper,
  PageHeader,
  PageIndicator,
  PageSubtitle,
  PageTitle,
  Form,
  SearchWrapper,
  SearchInput,
  StyledTable,
  TableWrapper,
  NavButton,
  NavWrapper,
} from "../UI/StyledComponents";
import Swal from "sweetalert2";
import { fetchDuplicateRequests, updateRequestStatus } from "../../Api/admin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DuplicatePrintRequestComponent = () => {
  const [requests, setRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate()

  useEffect(() => {
    fetchRequestData();
  }, []);

  const fetchRequestData = async () => {
    try {
      const res = await fetchDuplicateRequests();
      if (res.data.success) {
        setRequests(res.data.data.requests);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredRequests = requests.filter((val) => {
    const matchesSearchQuery =
      val.Student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.roll_number.toString().includes(searchQuery);
    return matchesSearchQuery;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRequests = filteredRequests.slice(
    indexOfFirstItem,
    indexOfFirstItem + itemsPerPage
  );
  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleApproveOrReject = async (request) => {
    const result = await Swal.fire({
      title: "What would you like to do?",
      text: `Request by ${request.Student.name}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Approve",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await updateRequestStatus(request.id, 'approved', 'duplicate');
        Swal.fire(
          'Approved!',
          `The request for ${request.Student.name} has been approved.`,
          'success'
        );
        fetchRequestData();
      } catch (error) {
        Swal.fire('Error', 'Failed to approve the request.', 'error');
      }
    }
  };

  const handlePrint = async (val) => {
    try {
      if (val.request === "Migration Certificate") {
        navigate(`/admin/viewMigrationCertificate/${val.id}/${val.printNumber}`);
      } else if (val.request === "Bonafide Certificate") {
        navigate(`/admin/viewBonafideCertificate/${val.id}/${val.printNumber}`);
      } else if (val.request === "Marks Card") {
        navigate(`/admin/viewSslcCertificate/${val.id}/${val.printNumber}`);
        navigate(`/admin/viewPucCertificate/${val.id}/${val.printNumber}`);
      } else if (val.request === "Study Certificate") {
        navigate(`/admin/viewStudyCertificate/${val.id}/${val.printNumber}`);
      } else {
        toast.error('Invalid request type !!')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Duplicate Print Request</PageTitle>
        <PageSubtitle>
          View and Manage all duplicate certificate printing requests
        </PageSubtitle>
      </PageHeader>
      <Form>
        <SearchWrapper>
          <SearchInput
            placeholder="Search by name or roll number"
            required
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchWrapper>
      </Form>
      {/* Check if there are no matching results */}
      {filteredRequests.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No matching results found.</p>
        </div>
      ) : currentRequests.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No duplicate print requests to display.</p>
        </div>
      ) : (
        <div>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th>Roll Number</th>
                  <th>Student Name</th>
                  <th>Printing request</th>
                  <th>Status</th>
                  <th>Print Number</th>
                  <th>Date applied</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentRequests.map((val, index) => (
                  <tr key={index}>
                    <td>{val.roll_number}</td>
                    <td>{val.Student.name}</td>
                    <td>{val.request}</td>
                    <td>{val.status}</td>
                    <td>
                      {val.printNumber === 0
                        ? "Original"
                        : `Duplicate (${val.printNumber})`}
                    </td>
                    <td>{formatDate(val.createdAt)}</td>
                    <td>
                      {val.status === "pending" ? (
                        <button
                          onClick={() => handleApproveOrReject(val)}
                          className="text-red-500"
                        >
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        </button>
                      ) : val.status === "approved" ? (
                        <button
                          onClick={() => handlePrint(val)}
                          className="text-green-500"
                        >
                          <FontAwesomeIcon icon={faPrint} />
                        </button>
                      ) : (
                        <button disabled>
                          <FontAwesomeIcon
                            icon={faBan}
                            className="text-red-500"
                          />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </div>
      )}
    </PageWrapper>
  );
};

export default DuplicatePrintRequestComponent;