import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getCertificate, fetchStudent, fetchSessionByCourseId } from '../../Api/admin';

const CertificateMigrationComponent = () => {
    const { id, printNumber } = useParams();
    const printRef = useRef();
    const [certificate, setCertificate] = useState();
    const [rollNumber, setRollNumber] = useState('');
    const [student, setStudent] = useState();
    const [courseId, setCourseId] = useState('');
    const [session, setSession]=useState();

    const handlePrint = () => {
        const printIframe = document.createElement('iframe');
        printIframe.style.display = 'none';
        document.body.appendChild(printIframe);
        const content = printRef.current.innerHTML;
        const printDocument = printIframe.contentWindow.document;

        printDocument.open();
        printDocument.write(`
            <html>
                <head>
                    <title>Print Certificate</title>
                    <style>
                        @media print {
                            @page {
                                margin: 0; /* Remove margins */
                            }
                            body {
                                font-family: Arial, sans-serif;
                                margin: 0;
                                padding: 20px; /* Optional padding */
                                display: flex; /* Use flexbox for centering */
                                align-items: center; /* Center vertically */
                                justify-content: center; /* Center horizontally */
                            }
                            .content {
                                max-width: 800px;
                                width: 100%; /* Ensure it uses full width available */
                                box-shadow: none; /* No shadow for printing */
                                border: none; /* No border for printing */
                                margin:50px;
                            }
                            h2 {
                                text-decoration: underline;
                                font-weight: bold;
                                text-align: center;
                                font-family: Georgia, serif; /* Serif font */
                            }
                            p {
                                margin: 10px 0;
                                text-align: center; /* Default center alignment for paragraphs */
                            }
                            .left-align {
                                text-align: left; /* Left align specific paragraphs */
                            }
                            /* Hide print metadata */
                            footer, header, nav, aside {
                                display: none !important; /* Hide any headers or footers */
                            }
                        }
                    </style>
                </head>
                <body>
                    <div class="content">
                        ${content}
                    </div>
                    <script>
                        window.onload = function() {
                            window.print();
                            document.body.removeChild(printIframe); // Remove iframe after printing
                        };
                    </script>
                </body>
            </html>
        `);
        printDocument.close();
    };

    useEffect(() => {
        const fetchPrintRequest = async () => {
            try {
                const numPrintNumber = Number(printNumber);
                let requestType;
                if (numPrintNumber === 0) {
                    requestType = "Original";
                } else if (numPrintNumber > 0) {
                    requestType = "Duplicate";
                }
                if (id) {
                    const res = await getCertificate(id, requestType);
                    if (res.data.success) {
                        setCertificate(res.data.data.certificate);
                        setRollNumber(res.data.data.certificate.roll_number);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchPrintRequest();
    }, [id, printNumber]);

    useEffect(() => {
        const fetchStudentData = async () => {
            try {
                if (rollNumber) {
                    const res = await fetchStudent(rollNumber);
                    if (res.data.success) {
                        setStudent(res.data.data);
                        setCourseId(res.data.data.course_id)
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchStudentData();
    }, [rollNumber]);

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                if (courseId) {
                    const res = await fetchSessionByCourseId(courseId)
                    if(res.data.success){
                        setSession(res.data.data.session)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchSessionData()
    }, [courseId])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

    return (
        <div style={{ padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div
                ref={printRef}
                style={{
                    border: "2px solid #000",
                    padding: "20px",
                    maxWidth: "800px",
                    margin: "auto",
                    textAlign: "center",
                    position: "relative",
                    fontFamily: "Arial, sans-serif",
                }}
            >
                <h2 style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    margin: "20px 0",
                    textAlign: "center",
                    fontFamily: "Georgia, serif"
                }}>
                    <strong>
                        {certificate?.printNumber === 0 ? "MIGRATION CERTIFICATE" : "DUPLICATE MIGRATION CERTIFICATE"}
                    </strong>

                </h2>
                <div style={{ lineHeight: "1.5", textAlign: "center" }}>
                    <p style={{ margin: "10px 0", textAlign: "left" }}>
                        Roll No. : <strong>{certificate?.roll_number}</strong>
                    </p>
                    <p style={{ margin: "10px 0", textAlign: "left" }}>
                        Session : <strong>{session?.session_month?.toUpperCase()} - {session?.session_year}</strong>
                    </p>
                    <p style={{ margin: "10px 0" }}>
                        This is to certify that this Board has no objection to
                    </p>
                    <p style={{ margin: "10px 0" }}>
                        Shri/Smt./Kumari <strong>{student?.name?.toUpperCase()}</strong>
                    </p>
                    <p style={{ margin: "10px 0" }}>
                        S/o, D/o <strong>{student?.father_name?.toUpperCase()}</strong>
                    </p>
                    <p style={{ margin: "10px 0" }}>
                        ex-student of <strong>{student?.center?.center_name?.toUpperCase()}</strong>
                    </p>
                    <p style={{ margin: "10px 0" }}>
                        recognised by the <strong>KARNATAKA STATE COUNCIL OF INTERMEDIATE AND HIGHER EDUCATION, MYSORE</strong> prosecuting his/her further studies by any other Board/University.
                    </p>
                </div>
                <div style={{ marginTop: "40px", display: "flex", justifyContent: "space-between", textAlign: "left" }}>
                    <div style={{ textAlign: "left" }}>
                        <p>Place: Mysore</p>
                        <p>Date: {formatDate(session?.issue_date)}</p>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <p>SECRETARY</p>
                        <p>KSCIHE</p>
                    </div>
                </div>
            </div>

            <div style={{ textAlign: "center", marginTop: "20px" }}>
                <button
                    onClick={handlePrint}
                    style={{
                        padding: "10px 20px",
                        backgroundColor: "#FBD6BD",
                        color: "black",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                    }}
                >
                    Print
                </button>
            </div>
        </div>
    );
};

export default CertificateMigrationComponent;