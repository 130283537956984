import Sidebar from "../../../Components/Staff/Sidebar";
import ViewCenterComponent from "../../../Components/Staff/ViewCenterComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from "../../../Components/Staff/NavComponent";


const ViewCenter = () => {
  return (
    <>
      <NavComponent />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <ViewCenterComponent />
        </MainContent>
      </Layout>
    </>
  )
}

export default ViewCenter