import Api from '../Service/axios'
import StudentEndPoint from '../Service/endpoints/studentEndpoints'

export const fetchCourse = async (id) => {
    try {
        const res = await Api.get(`${StudentEndPoint.course}/${id}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchSubjectsOfCourses = async (courseId) => {
    try {
        const res = await Api.get(`${StudentEndPoint.subject}/getByCourse/${courseId}`)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchCenter = async (id) => {
    try {
        const res = await Api.get(`${StudentEndPoint.center}/${id}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchTimetableByCourseId = async (id) => {
    try {
        const res = await Api.get(`${StudentEndPoint.timetable}/getTimetableByCourseId/${id}`)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchExam = async () => {
    try {

    } catch (error) {
        console.log(error)
    }
}

export const fetchTimetable = async (id) => {
    try {
        const res = await Api.get(`${StudentEndPoint.timetable}/${id}`)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchSubject = async (id) => {
    try {
        const res = await Api.get(`${StudentEndPoint.subject}/${id}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchResultsByRollNumber = async (rollNumber) => {
    try {
        const res = await Api.get(`${StudentEndPoint.result}/getResultByRollNumber/${rollNumber}`)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchResultById = async (id) => {
    try {
        const res = await Api.get(`${StudentEndPoint.result}/getResultById/${id}`);
        return res
    } catch (error) {
        console.log(error)
    }
}

export const fetchTimetableByExamId=async(examId)=>{
    try{
        const res=await Api.get(`${StudentEndPoint.timetable}/getByExamId/${examId}`)
        return res
    }catch(error){
        console.log(error)
    }
}