import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { fetchCourse, fetchResultsByRollNumber, fetchTimetableByExamId } from "../../Api/student";
import {
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  StyledTable,
  NavButton,
  PageIndicator,
  TableWrapper,
  NavWrapper,
} from "../UI/StyledComponents";

const ResultsComponent = () => {
  const { studentInfo } = useSelector((state) => state.auth);
  const [result, setResult] = useState([]);
  const [rollNumber, setRollNumber] = useState("");
  const [courseId, setCourseId] = useState("");
  const [course, setCourse] = useState();
  const [timetableSessions, setTimetableSessions] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const decoding = () => {
      if (studentInfo) {
        const decodedToken = jwtDecode(studentInfo);
        if (decodedToken && decodedToken.user && decodedToken.user.course_id) {
          setRollNumber(decodedToken.user.roll_number);
          setCourseId(decodedToken.user.course_id);
        }
      }
    };
    decoding();
  }, [studentInfo]);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        if (courseId) {
          const res = await fetchCourse(courseId);
          if (res.data.success) {
            setCourse(res.data.data.course);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourseData();
  }, [courseId]);

  useEffect(() => {
    const fetchResultData = async () => {
      try {
        if (rollNumber) {
          const res = await fetchResultsByRollNumber(rollNumber);
          if (res.data.success) {
            setResult(res.data.data.result);
            const sessions = {};
            for (const item of res.data.data.result) {
              const examId = item.Exam?.id;
              if (examId) {
                const timetableRes = await fetchTimetableByExamId(examId);
                if (timetableRes.data.success) {
                  const timetableArray = timetableRes.data.data.timetable;
                  if (timetableArray && timetableArray.length > 0) {
                    const sessionDetails = timetableArray[0].session;
                    sessions[examId] = sessionDetails;
                  }
                }
              }
            }
            setTimetableSessions(sessions);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchResultData();
  }, [rollNumber]);

  const handleView = async (id) => {
    try {
      navigate(`/viewResults/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentResults = result.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(result.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Results </PageTitle>
        <PageSubtitle>View and print results</PageSubtitle>
      </PageHeader>
      {result.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No results to display.</p>
        </div>
      ) : (
        <div>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th>Exam Name</th>
                  <th>Session</th>
                  <th>Course name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentResults.map((val, index) => {
                  const examId = val?.Exam?.id;
                  const session = timetableSessions[examId];
                  return (
                    <tr key={index}>
                      <td>{val?.Exam?.exam_name}</td>
                      <td>
                        {session
                          ? `${session?.session_month} - ${session?.session_year}`
                          : "N/A"}
                      </td>
                      <td>{course?.course_name}</td>
                      <td>
                        <button onClick={() => handleView(val.id)}>
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </div>
      )}
    </PageWrapper>
  );
};

export default ResultsComponent;
