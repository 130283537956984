import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "tailwindcss/tailwind.css";
import {
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  StyledTable,
  TableWrapper,
  Form,
  SearchWrapper,
  SearchInput,
  NavButton,
  NavWrapper,
  PageIndicator
} from "../UI/StyledComponents";
import {
  fetchProfileCorrections,
  approveProfileCorrection,
  rejectProfileCorrection,
} from "../../Api/admin";

const MySwal = withReactContent(Swal);

const CorrectionsComponent = () => {
  const [corrections, setCorrections] = useState([]);
  const [status, setStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchProfileCorrectionsData = async () => {
      try {
        const res = await fetchProfileCorrections();
        if (res.data.success) {
          setCorrections(res.data.data.corrections);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProfileCorrectionsData();
  }, [status]);

  const handleOpen = async (correction) => {
    const result = await MySwal.fire({
      title: <p>Edit Correction</p>,
      html: (
        <div>
          <p>
            Do you want to change the{" "}
            <strong>
              {correctionFieldLabels[correction.correction_field] ||
                correction.correction_field}
            </strong>{" "}
            of
            <strong> {correction.Student.name} </strong>
            from{" "}
            <strong>
              {correction.correction_field === "date_of_birth"
                ? formatDate(correction.old_value)
                : correction.old_value}
            </strong>{" "}
            to
            <strong>
              {correction.correction_field === "date_of_birth"
                ? formatDate(correction.correction_value)
                : correction.correction_value}
            </strong>
            ?
          </p>
        </div>
      ),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Approve",
      cancelButtonText: "Reject",
    });

    if (result.isConfirmed) {
      try {
        const res = await approveProfileCorrection(correction.id);
        if (res.data.success) {
          setStatus(!status);
          Swal.fire("Approved!", res.data.message, "success");
        } else {
          Swal.fire("Error!", res.data.message, "error");
        }
      } catch (error) {
        Swal.fire(
          "Error!",
          "There was a problem approving the correction.",
          "error"
        );
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      try {
        const res = await rejectProfileCorrection(correction.id);
        if (res.data.success) {
          setStatus(!status);
          Swal.fire("Rejected!", res.data.message, "success");
        } else {
          Swal.fire("Error!", res.data.message, "error");
        }
      } catch (error) {
        console.error(error);
        Swal.fire(
          "Error!",
          "There was a problem approving the correction.",
          "error"
        );
      }
      Swal.fire("Rejected!", "The correction has been rejected.", "error");
    }
  };

  const correctionFieldLabels = {
    name: "Student Name",
    father_name: "Father's Name",
    mother_name: "Mother's Name",
    date_of_birth: "Date of Birth",
    address: "Address",
    email: "Email",
    mobile_number: "Mobile Number",
  };

  const renderActionButtons = (correction) => {
    return correction.status === "Pending" ? (
      <button
        className="text-white font-bold py-2 px-4 rounded"
        onClick={() => handleOpen(correction)}
      >
        View
      </button>
    ) : (
      <button
        className="bg-gray-400 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
        disabled
      >
        {correction.status}
      </button>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const filteredCorrections = corrections.filter((val) => {
    const matchesSearchQuery =
      val.Student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.Student.roll_number.toString().includes(searchQuery.toLowerCase());
    return matchesSearchQuery;
  })

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCorrections = filteredCorrections.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredCorrections.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Profile Correction</PageTitle>
        <PageSubtitle>View and Manage all corrections</PageSubtitle>
      </PageHeader>
      <Form>
        <SearchWrapper>
          <SearchInput
            placeholder="Search"
            required
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchWrapper>
      </Form>
      {corrections.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No students to display.</p>
        </div>
      ) : currentCorrections.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No results found.</p>
        </div>
      ) : (
        <>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th>Roll No</th>
                  <th>Student Name</th>
                  <th>Correction field</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentCorrections.map((correction) => (
                  <tr key={correction.id}>
                    <td>{correction.Student.roll_number}</td>
                    <td>{correction.Student.name}</td>
                    <td className="px-4 py-2">
                      {correctionFieldLabels[correction.correction_field] ||
                        correction.correction_field}
                    </td>
                    <td className="px-4 py-2">
                      {correction.correction_field === "date_of_birth"
                        ? formatDate(correction.old_value)
                        : correction.old_value}
                    </td>
                    <td className="px-4 py-2">
                      {correction.correction_field === "date_of_birth"
                        ? formatDate(correction.correction_value)
                        : correction.correction_value}
                    </td>
                    <td>{renderActionButtons(correction)}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </>
      )}
    </PageWrapper>
  )
}

export default CorrectionsComponent
