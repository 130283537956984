import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { timetables } from "../../Api/admin";
import {
  Form,
  SearchWrapper,
  SearchInput,
  PageHeader,
  PageSubtitle,
  PageIndicator,
  PageTitle,
  PageWrapper,
  AddButton,
  StyledTable,
  NavWrapper,
  NavButton,
  TableWrapper,
} from "../UI/StyledComponents";

const TimetableComponent = () => {
  const [timetable, setTimetable] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTimetableData = async () => {
      try {
        const res = await timetables();
        if (res.data.success) {
          setTimetable(res.data.data.timetables);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTimetableData();
  }, []);

  const handleView = async (id) => {
    try {
      navigate(`/admin/viewTimetable/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = async () => {
    try {
      navigate("/admin/addTimetable");
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (id) => {
    try {
      navigate(`/admin/editTimetable/${id}`);
    } catch (error) {
      console.log(error);
    }
  };  

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredTimetables = timetable.filter((val) => {
    const matchesSearchQuery = val.exam.exam_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return matchesSearchQuery;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTimetable = filteredTimetables.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredTimetables.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Timetable</PageTitle>
        <PageSubtitle>View and Manage Timetables</PageSubtitle>
      </PageHeader>

      {timetable.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No timetables to display.</p>
          <AddButton type="button" onClick={handleAdd}>
            Add Timetable
          </AddButton>
        </div>
      ) : (
        <>
          <Form>
            <SearchWrapper>
              <SearchInput
                placeholder="Search by Exam Name"
                required
                value={searchQuery}
                onChange={handleSearch}
              />
            </SearchWrapper>
            <AddButton type="button" onClick={handleAdd}>
              Add Timetable
            </AddButton>
          </Form>

          {filteredTimetables.length === 0 ? (
            <div className="text-center">
              <p className="text-gray-700 mb-4">No matching timetables found.</p>
            </div>
          ) : (
            <>
              <TableWrapper>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>Sl No</th>
                      <th>Exam Name</th>
                      <th>Session</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTimetable
                      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                      .map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{val.exam.exam_name}</td>
                          <td>{val?.session?.session_month} - {val?.session?.session_year}</td>
                          <td>
                            <button onClick={() => handleView(val.id)}>
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                            <button onClick={() => handleEdit(val.id)} style={{ marginLeft: '8px' }}>
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </StyledTable>
              </TableWrapper>

              {totalPages > 1 && (
                <NavWrapper>
                  <NavButton
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </NavButton>
                  <PageIndicator>
                    Page {currentPage} of {totalPages}
                  </PageIndicator>
                  <NavButton
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </NavButton>
                </NavWrapper>
              )}
            </>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default TimetableComponent;
