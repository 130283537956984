import { Route, Routes } from "react-router-dom";
import Login from "../Components/Common/Login";
// import Dashboard from "../Pages/Center/Dashboard/Dashboard";
import Student from "../Pages/Center/Student/Student";
import AddStudent from "../Pages/Center/Student/AddStudent";
import UpdateStudents from "../Pages/Center/Student/UpdateStudents";
import CenterLoggedIn from "../Components/Center/CenterLoggedIn";
import CenterLoggedOut from "../Components/Center/CenterLoggedOut";
import Error from "../Pages/Admin/Error/Error";
import ChangePassword from "../Pages/Center/Password/ChangePassword.jsx";
import HallTicket from "../Pages/Center/HallTicket/HallTicket";
import ViewHallticket from "../Pages/Center/HallTicket/ViewHallticket.jsx";
import ProfileCorrections from "../Pages/Center/Corrections/ProfileCorrections.jsx";
// import ResultCorrections from "../Pages/Center/Corrections/ResultCorrections.jsx";
import MarksCard from "../Pages/Center/Print/MarksCard.jsx";
import BonafiedCertificate from "../Pages/Center/Print/BonafiedCertificate.jsx";
import StudyCertificate from "../Pages/Center/Print/StudyCertificate.jsx";
import MigrationCertificate from "../Pages/Center/Print/MigrationCertificate.jsx";
import AddProfileCorrection from "../Pages/Center/Corrections/AddProfileCorrection.jsx";
// import AddResultCorrection from "../Pages/Center/Corrections/AddResultCorrection.jsx";

const centerRoute = () => {
  return (
    <Routes>
      <Route path="" element={<CenterLoggedOut />}>
        <Route path="/" element={<Login />} />
      </Route>
      <Route path="" element={<CenterLoggedIn />}>
        {/* <Route path="dashboard" element={<Dashboard />} /> */}
        <Route path="student" element={<Student />} />
        <Route path="addStudent" element={<AddStudent />} />
        <Route path="editStudent/:id" element={<UpdateStudents />} />
        <Route path="hallticket" element={<HallTicket />} />
        <Route path="viewHallticket/:timetableId/:userId" element={<ViewHallticket />} />
        <Route path="changePassword" element={<ChangePassword />} />
        <Route path="corrections" element={<ProfileCorrections/>}/>
        <Route path='addCorrection' element={<AddProfileCorrection/>}/>
        <Route path="print">
          <Route path="marksCard" element={<MarksCard />} />
          <Route path="bonafiedCertificate" element={<BonafiedCertificate />} />
          <Route path="studyCertificate" element={<StudyCertificate />} />
          <Route path="migrationCertificate" element={<MigrationCertificate />} />
          <Route path="marksCard" element={<MarksCard isDuplicate={false} />} />
          <Route path="bonafiedCertificate" element={<BonafiedCertificate isDuplicate={false} />} />
          <Route path="studyCertificate" element={<StudyCertificate isDuplicate={false} />} />
          <Route path="migrationCertificate" element={<MigrationCertificate isDuplicate={false} />} />
        </Route>
        <Route path="duplicatePrint">
          <Route path="duplicatemarksCard" element={<MarksCard isDuplicate={true} />} />
          <Route path="duplicatebonafiedCertificate" element={<BonafiedCertificate isDuplicate={true} />} />
          <Route path="duplicatestudyCertificate" element={<StudyCertificate isDuplicate={true} />} />
          <Route path="duplicatemigrationCertificate" element={<MigrationCertificate isDuplicate={true} />} />
        </Route>
        {/* <Route path="corrections">
          <Route path="profileCorrection" element={<ProfileCorrections />}/>
          <Route path="addProfileCorrection" element={<AddProfileCorrection/>}/>
          <Route path="resultCorrection" element={<ResultCorrections />} />
          <Route path="addResultCorrection" element={<AddResultCorrection/>}/>
        </Route> */}
      </Route>
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default centerRoute;
