import Sidebar from "../../../Components/Admin/Sidebar";
import TimetableComponent from "../../../Components/Admin/TimetableComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from '../../../Components/Admin/NavComponent'

const Timetable = () => {
  return (
    <>
      <NavComponent />
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <TimetableComponent />
      </MainContent>
    </Layout>
    </>
  );
};

export default Timetable;
