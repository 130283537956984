import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { fetchCenter, editCenter } from "../../Api/admin";
import {
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  Spacing20,
  DividerLine,
  AddComponentForm,
} from "../UI/StyledComponents";

const EditCenterComponent = () => {
  const [center_name, setCenterName] = useState("");
  const [center_code, setCenterCode] = useState("");
  const [address, setAddress] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [coordinator_name, setCoordinatorName] = useState("");
  const [address_line1, setAddressLine1] = useState("");
  const [address_line2, setAddressLine2] = useState("");
  const [address_line3, setAddressLine3] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCenterData = async () => {
      try {
        if (id) {
          const res = await fetchCenter(id);
          if (res.data.success) {
            const data = res.data.data;
            setCenterName(data.center_name);
            setCenterCode(data.center_code);
            setEmail(data.email);
            setAddress(data.address);
            setMobileNumber(data.mobile_number);
            setCoordinatorName(data.coordinator_name);
            setAddressLine1(data.address_line1);
            setAddressLine2(data.address_line2);
            setAddressLine3(data.address_line3);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCenterData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const res = await editCenter(
          id,
          center_name,
        center_code, 
        address,
        mobile_number, 
        email,
        address_line1, 
        address_line2,
        address_line3,
        coordinator_name, 
        );
        if (res.data.success) {
          toast.success("Updation successful...");
          navigate("/admin/center");
        } else if (!res.data.success) {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Center Details</PageTitle>
        <PageSubtitle>Edit center details</PageSubtitle>
      </PageHeader>
        <AddComponentForm className="mt-4" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">Edit Center Details</h2>
        <DividerLine />
        <Spacing20 />
          <div className="mb-4">
            <label
              htmlFor="center_name"
            >
              Center Name
            </label>
            <input
              type="text"
              id="center_name"
              name="center_name"
              value={center_name}
              onChange={(e) => setCenterName(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="center_code"
            >
              Center Code
            </label>
            <input
              type="text"
              id="center_code"
              name="center_code"
              value={center_code}
              onChange={(e) => setCenterCode(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="address"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="mobile_number"
            >
              Mobile Number
            </label>
            <input
              type="text"
              id="mobile_number"
              name="mobile_number"
              value={mobile_number}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="address_line1"
            >
              Address for Marks card
            </label>
            <input
              type="text"
              id="address_line1"
              name="address_line1"
              value={address_line1}
              onChange={(e) => setAddressLine1(e.target.value)}
              placeholder="Enter first line of address"
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              id="address_line2"
              name="address_line2"
              value={address_line2}
              onChange={(e) => setAddressLine2(e.target.value)}
              placeholder="Enter second line of address"
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              id="address_line3"
              name="address_line3"
              value={address_line3}
              onChange={(e) => setAddressLine3(e.target.value)}
              placeholder="Enter third line of address"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="coordinator_name"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Co-Ordinator Name
            </label>
            <input
              type="text"
              id="coordinator_name"
              name="coordinator_name"
              value={coordinator_name}
              onChange={(e) => setCoordinatorName(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
          >
            Submit
          </button>
        </AddComponentForm>
    </PageWrapper>
  );
};

export default EditCenterComponent;
