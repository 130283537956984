import Sidebar from "../../../Components/Admin/Sidebar";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import CertificateMigrationComponent from "../../../Components/Admin/CertificateMigrationComponent";
import NavComponent from "../../../Components/Admin/NavComponent";

const CertificateMigration = () => {
  return (
    <>
      <NavComponent />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <CertificateMigrationComponent />
        </MainContent>
      </Layout>
    </>
  )
}

export default CertificateMigration