import Sidebar from "../../../Components/Admin/Sidebar";
import AddTimetableComponent from "../../../Components/Admin/AddTimetableComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from '../../../Components/Admin/NavComponent'

const AddTimetable = () => {
  return (
    <>
      <NavComponent />
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <AddTimetableComponent />
      </MainContent>
    </Layout>
     </>
  );
};

export default AddTimetable;
