import Sidebar from "../../../Components/Admin/Sidebar"
import PublishResultsComponent from "../../../Components/Admin/PublishResultsComponent"
import { SidebarContainer, MainContent, Layout } from '../../../Components/UI/StyledComponents'
import NavComponent from '../../../Components/Admin/NavComponent'

const PublishResult = () => {
    return (
        <>
      <NavComponent />
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <PublishResultsComponent />
            </MainContent>
        </Layout>
        </>
    )
}

export default PublishResult