import Sidebar from "../../../Components/Admin/Sidebar"
import { SidebarContainer, MainContent, Layout } from '../../../Components/UI/StyledComponents'
import ViewResultComponent from "../../../Components/Admin/ViewResultComponent"
import NavComponent from '../../../Components/Admin/NavComponent'

const ViewResult = () => {
    return (
        <>
      <NavComponent />
        <Layout>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContent>
                <ViewResultComponent />
            </MainContent>
        </Layout>
        </>
    )
}

export default ViewResult