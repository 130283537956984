import React from "react";
import welcomeBackImage from "../../Assets/welcome-back.png";
import {
  WelcomeCard,
  WelcomeContent,
  WelcomeHeading,
  WelcomeParagraph,
  WelcomeImageContainer,
  WelcomeStyledImage,
} from "../UI/StyledComponents";

const Welcome = () => {
  return (
    <WelcomeCard>
      <WelcomeContent>
        <WelcomeHeading>So Glad You're Back to the Dashboard!</WelcomeHeading>
        <WelcomeParagraph>
          "Education is the ability to listen to almost anything without losing
          your temper or your self-confidence."
        </WelcomeParagraph>
      </WelcomeContent>
      <WelcomeImageContainer>
        <WelcomeStyledImage
          src={welcomeBackImage}
          alt="Person working on a computer"
        />
      </WelcomeImageContainer>
    </WelcomeCard>
  );
};

export default Welcome;
