import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { DividerLine } from '../UI/StyledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { addSubject } from '../../Api/admin';
import { toast } from 'react-toastify';

const DragAndDrop = ({ selectedSubjects, setSelectedSubjects, sortedSubjects, onSubjectChange }) => {
    const [showForm, setShowForm] = useState(false);
    const [newSubjectName, setNewSubjectName] = useState("");
    const [newSubjectCode, setNewSubjectCode] = useState("");
    const [totalMarks, setTotalMarks] = useState(0);
    const [passMarks, setPassMarks] = useState(0);
    const [practicalMarks, setPracticalMarks] = useState(0);

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        if (source.index === destination.index) return;
        const reorderedSubjects = Array.from(selectedSubjects);
        const [movedSubject] = reorderedSubjects.splice(source.index, 1);
        reorderedSubjects.splice(destination.index, 0, movedSubject);
        setSelectedSubjects(reorderedSubjects);
        onSubjectChange(reorderedSubjects);
    };

    const handleAddNewSubject = async () => {
        if (newSubjectName && newSubjectCode && totalMarks && passMarks) {
            try {
                const res = await addSubject(
                    newSubjectCode,
                    newSubjectName,
                    totalMarks,
                    passMarks,
                    practicalMarks
                );
                if (res.data.success) {
                    toast.success("New subject added successfully.");
                    setNewSubjectName("");
                    setNewSubjectCode("");
                    setTotalMarks(0);
                    setPassMarks(0);
                    setPracticalMarks(0);
                    setShowForm(false);
                } else {
                    toast.error(res.data.message);
                }
            } catch (error) {
                toast.error("An error occurred while adding the subject.");
            }
        } else {
            toast.error("Please fill out all required fields.");
        }
    };

    return (
        <div className="bg-white shadow-lg rounded-lg p-4 ml-4" style={{ width: '700px', flexShrink: 0 }}>
            <h3 className="text-lg font-bold">Selected Subjects</h3>
            <DividerLine />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="selectedSubjects">
                    {(provided) => (
                        <table
                            className="mt-2 w-full"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-2"></th>
                                    <th className="p-2 text-left">#</th>
                                    <th className="p-2 text-left">Subject Code</th>
                                    <th className="p-2 text-left">Subject Name</th>
                                    <th className="p-2 text-left">Total Marks</th>
                                    <th className="p-2 text-left">Pass Marks</th>
                                    <th className="p-2 text-left">Practical Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedSubjects.length === 0 && (
                                    <tr>
                                        <td colSpan="7" className="p-2 text-center">No subjects selected</td>
                                    </tr>
                                )}
                                {selectedSubjects.map((subjectId, index) => {
                                    const subject = sortedSubjects.find((s) => s.id === subjectId);
                                    if (!subject) return null;
                                    return (
                                        <Draggable key={subjectId} draggableId={subjectId.toString()} index={index}>
                                            {(provided) => (
                                                <tr
                                                    className="bg-gray-100 hover:bg-gray-200 border-b border-gray-300 transition duration-200"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <td className="p-2 text-center" {...provided.dragHandleProps}>
                                                        <FontAwesomeIcon icon={faGripVertical} className="text-gray-600 cursor-grab" />
                                                    </td>
                                                    <td className="p-2">{index + 1}</td>
                                                    <td className="p-2">{subject.subject_code}</td>
                                                    <td className="p-2">{subject.subject_name}</td>
                                                    <td className="p-2">{subject.max_marks}</td>
                                                    <td className="p-2">{subject.min_marks}</td>
                                                    <td className="p-2">{subject.practical_marks}</td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
            <DividerLine />
            <button
                onClick={() => setShowForm(!showForm)}
                style={{ backgroundColor: "#fbd6bd" }}
                className="px-4 py-2 mt-4 text-black rounded-lg"
            >
                {showForm ? "Cancel" : "Add New Subject"}
            </button>
            {showForm && (
                <div className="mt-4">
                    <h3 className="text-lg font-bold mb-4">Add New Subject</h3>
                    <DividerLine />
                    <label htmlFor="newSubjectName">Subject Name</label>
                    <input
                        type="text"
                        id="newSubjectName"
                        value={newSubjectName}
                        onChange={(e) => setNewSubjectName(e.target.value)}
                        required
                        className="w-full p-2 mt-2 mb-4 border rounded-lg"
                    />
                    <label htmlFor="newSubjectCode">Subject Code</label>
                    <input
                        type="text"
                        id="newSubjectCode"
                        value={newSubjectCode}
                        onChange={(e) => setNewSubjectCode(e.target.value)}
                        required
                        className="w-full p-2 mt-2 mb-4 border rounded-lg"
                    />
                    <label htmlFor="totalMarks">Total Marks</label>
                    <input
                        type="number"
                        id="totalMarks"
                        value={totalMarks}
                        onChange={(e) => setTotalMarks(e.target.value)}
                        required
                        className="w-full p-2 mt-2 mb-4 border rounded-lg"
                    />
                    <label htmlFor="passMarks">Pass Marks</label>
                    <input
                        type="number"
                        id="passMarks"
                        value={passMarks}
                        onChange={(e) => setPassMarks(e.target.value)}
                        required
                        className="w-full p-2 mt-2 mb-4 border rounded-lg"
                    />
                    <label htmlFor="practicalMarks">Practical Marks</label>
                    <input
                        type="number"
                        id="practicalMarks"
                        value={practicalMarks}
                        onChange={(e) => setPracticalMarks(e.target.value)}
                        className="w-full p-2 mt-2 mb-4 border rounded-lg"
                    />
                    <button
                        onClick={handleAddNewSubject}
                        style={{ backgroundColor: "#fbd6bd" }}
                        className="px-4 py-2 text-black rounded-lg"
                    >
                        Add Subject
                    </button>

                </div>
            )}
        </div>
    );
};

export default DragAndDrop;