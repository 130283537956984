import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStudentById } from "../../Api/admin";
import UserImage from "../../Assets/userImage.png";
import {
    ProfileCard,
    ProfileImageWrapper,
    ProfileInfo,
    ProfileName,
    StudentDetailsCard,
    InfoCard,
    InfoTitle,
    InfoList,
    PageTitle,
    PageSubtitle,
    InfoListItem,
    PageHeader,
    PageWrapper,
} from "../UI/StyledComponents";

const ViewStudentComponent = () => {
    const [students, setStudent] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchStudentData = async () => {
            try {
                if (id) {
                    const res = await getStudentById(id);
                    if (res.data.success) {
                        setStudent(res.data.data.student)
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchStudentData();
    }, [id]);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <PageWrapper>
            <PageHeader>
                <PageTitle>Student Profile</PageTitle>
                <PageSubtitle>View personal and academic details</PageSubtitle>
            </PageHeader>
            {students && (
                <>
                    <ProfileCard>
                        <div className="relative mb-5 mt-6">
                            <ProfileImageWrapper>
                                <img src={students?.image || UserImage} alt="Profile" />
                            </ProfileImageWrapper>
                        </div>
                        <ProfileInfo>
                            <div>
                                <ProfileName>{students?.name}</ProfileName>
                            </div>
                        </ProfileInfo>
                    </ProfileCard>
                    <StudentDetailsCard>
                        <InfoCard>
                            <InfoTitle>Personal Details</InfoTitle>
                            <InfoList>
                                <InfoListItem>Student Name : {students?.name}</InfoListItem>
                                <InfoListItem>
                                    Father's Name : {students?.father_name}
                                </InfoListItem>
                                <InfoListItem>
                                    Mother's Name : {students?.mother_name}
                                </InfoListItem>
                                <InfoListItem>
                                    Date of Birth : {formatDate(students?.date_of_birth)}
                                </InfoListItem>
                                <InfoListItem>Gender : {students?.gender}</InfoListItem>
                                <InfoListItem>Address : {students?.address}</InfoListItem>
                                <InfoListItem>
                                    Admission Status : {students?.admission_status}
                                </InfoListItem>
                                <InfoListItem>
                                    Mobile number : {students?.mobile_number}
                                </InfoListItem>
                                <InfoListItem>Email : {students?.email}</InfoListItem>
                            </InfoList>
                        </InfoCard>
                        <InfoCard>
                            <InfoTitle>Academic Details</InfoTitle>
                            <InfoList>
                                <InfoListItem>Center Name : {students?.center?.center_name}</InfoListItem>
                                <InfoListItem>Course : {students?.course?.course_name}</InfoListItem>
                                <InfoListItem>
                                    Roll No :{" "}
                                    {students?.roll_number
                                        ? students?.roll_number
                                        : "Not assigned yet"}
                                </InfoListItem>
                                <InfoListItem>
                                    Admission Status : {students?.admission_status}
                                </InfoListItem>
                            </InfoList>
                        </InfoCard>
                    </StudentDetailsCard>
                </>
            )}
        </PageWrapper>
    );
};
export default ViewStudentComponent;