import Sidebar from "../../../Components/Student/Sidebar";
import ChangePasswordComponent from "../../../Components/Student/ChangePasswordComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";

const ChangePassword = () => {
  return (
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <ChangePasswordComponent />
      </MainContent>
    </Layout>
  );
};

export default ChangePassword;
