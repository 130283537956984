import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import {
  PageWrapper,
  PageHeader,
  PageIndicator,
  PageSubtitle,
  PageTitle,
  Form,
  SearchWrapper,
  SearchInput,
  StyledTable,
  TableWrapper,
  NavButton,
  NavWrapper,
  FilterWrapper,
  FilterLabel,
  FilterSelect
} from "../UI/StyledComponents";
import { fetchOriginalRequests, updateRequestStatus, getExamByRollNumber } from "../../Api/admin";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PrintRequestComponent = () => {
  const [requests, setRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    fetchRequestData();
  }, []);

  const fetchRequestData = async () => {
    try {
      const res = await fetchOriginalRequests();
      if (res.data.success) {
        setRequests(res.data.data.requests);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredRequests = requests.filter((val) => {
    const matchesSearchQuery =
      val.Student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.roll_number.toString().includes(searchQuery);
    const matchesType =
      selectedType === "" ||
      (val.request && val.request.toString() === selectedType);
    return matchesSearchQuery && matchesType;
  });

  useEffect(() => {
    setIsSearchEmpty(filteredRequests.length === 0 && searchQuery !== "");
    setShowPagination(filteredRequests.length > itemsPerPage);
  }, [filteredRequests, searchQuery, itemsPerPage]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRequests = filteredRequests.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleApproveOrReject = async (request) => {
    const result = await Swal.fire({
      title: "What would you like to do?",
      text: `Request by ${request.Student.name}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Approve",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await updateRequestStatus(request.id, "approved", 'original');
        Swal.fire(
          "Approved!",
          `The request for ${request.Student.name} has been approved.`,
          "success"
        );
        fetchRequestData();
      } catch (error) {
        Swal.fire("Error", "Failed to approve the request.", "error");
      }
    }
  };

  const handleTypeFilter = (type) => {
    setSelectedType(type);
    setCurrentPage(1);
  };

  const handlePrint = async (val) => {
    try {
      if (val.request === "Migration Certificate") {
        navigate(`/staff/viewMigrationCertificate/${val.id}/${val.printNumber}`);
      } else if (val.request === "Bonafide Certificate") {
        navigate(`/staff/viewBonafideCertificate/${val.id}/${val.printNumber}`);
      } else if (val.request === "Marks Card") {
        if (val.roll_number) {
          const res = await getExamByRollNumber(val.roll_number)
          if (res.data.success && res.data.data.exam_name === "SECONDARY EXAMINATION / EQUIVALENT TO 10TH") {
            navigate(`/staff/viewSslcCertificate/${val.id}/${val.printNumber}`);
          } else if (res.data.success && res.data.data.exam_name === "SENIOR SECONDARY EXAMINATION / EQUIVALENT TO 10+2") {
            navigate(`/staff/viewPucCertificate/${val.id}/${val.printNumber}`);
          }
        }
      } else if (val.request === "Study Certificate") {
        navigate(`/staff/viewStudyCertificate/${val.id}/${val.printNumber}`);
      } else {
        toast.error('Invalid request type !!')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Print Request</PageTitle>
        <PageSubtitle>
          View and Manage all certificate printing requests
        </PageSubtitle>
      </PageHeader>
      <Form>
        <FilterWrapper>
          <FilterLabel>Filter by Type</FilterLabel>
          <FilterSelect
            id="centerFilter"
            onChange={(e) => handleTypeFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value='Marks Card'>Marks Card</option>
            <option value='Migration Certificate'>Migration Certificate</option>
            <option value='Study Certificate'>Study Certificate</option>
            <option value='Bonafide Certificate'>Bonafide Certificate</option>
          </FilterSelect>
        </FilterWrapper>
        <SearchWrapper>
          <SearchInput
            placeholder="Search by name or roll number"
            required
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchWrapper>
      </Form>
      {isSearchEmpty ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No matching results found.</p>
        </div>
      ) : currentRequests.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No requests to display.</p>
        </div>
      ) : (
        <div>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th>Roll Number</th>
                  <th>Student Name</th>
                  <th>Printing request</th>
                  <th>Status</th>
                  <th>Date applied</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentRequests.map((val, index) => (
                  <tr key={index}>
                    <td>{val.roll_number}</td>
                    <td>{val.Student.name}</td>
                    <td>{val.request}</td>
                    <td
                      className={`${val.status === "pending"
                        ? "text-blue-500"
                        : val.status === "rejected"
                          ? "text-red-500"
                          : val.status === "approved"
                            ? "text-green-500"
                            : "text-gray-500"
                        }`}
                    >
                      {val.status}
                    </td>
                    <td>{new Date(val.createdAt).toLocaleDateString()}</td>
                    <td>
                      {val.status === "pending" ? (
                        <button
                          title="approve or reject certificate"
                          onClick={() => handleApproveOrReject(val)}
                          className="text-red-500"
                        >
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        </button>
                      ) : val.status === "approved" ? (
                        <button
                          title="print certificate"
                          onClick={() => handlePrint(val)}
                          className="text-blue-500"
                        >
                          <FontAwesomeIcon icon={faPrint} />
                        </button>
                      ) : (
                        <button title="rejected" disabled>
                          <FontAwesomeIcon icon={faBan} className="text-red-500" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </TableWrapper>

          {showPagination && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </div>
      )}
    </PageWrapper>
  )
}

export default PrintRequestComponent