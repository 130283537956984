import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom";

const StaffLoggedIn = () => {
    const { staffInfo } = useSelector((state) => state.auth);
    return (
        staffInfo ? <Outlet /> : < Navigate to='/' />
    )
}

export default StaffLoggedIn