import Sidebar from "../../../Components/Staff/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import ViewStudentComponent from "../../../Components/Staff/ViewStudentComponent";
import NavComponent from "../../../Components/Staff/NavComponent";

const ViewStudent = () => {
    return (
        <>
            <NavComponent />
            <Layout>
                <SidebarContainer>
                    <Sidebar />
                </SidebarContainer>
                <MainContent>
                    <ViewStudentComponent />
                </MainContent>
            </Layout>
        </>
    );
};
export default ViewStudent;