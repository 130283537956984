import React, { useState, useEffect } from "react";
import UserImage from "../../Assets/userImage.png";
import {
  ProfileCard,
  ProfileImageWrapper,
  ProfileInfo,
  ProfileName,
  StudentDetailsCard,
  InfoCard,
  InfoTitle,
  InfoList,
  PageTitle,
  PageSubtitle,
  InfoListItem,
  PageHeader,
  PageWrapper,
} from "../UI/StyledComponents";
import { useParams } from "react-router-dom";
import { fetchStaff } from "../../Api/admin";

const ViewStaffComponent = () => {
  const { id } = useParams();
  const [staff, setStaff]=useState([])

  useEffect(()=>{
    const fetchStaffData=async()=>{
      if(id){
        const res=await fetchStaff(id)
        if(res.data.success){
          setStaff(res.data.data.staff)
        }
      }
    }
    fetchStaffData()
  },[id])

  const privilegesArray = staff.privileges ? staff.privileges.split(", ") : [];

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Staff Profile</PageTitle>
        <PageSubtitle>View personal and Privilege details</PageSubtitle>
      </PageHeader>
      <ProfileCard>
        <div className="relative mb-5 mt-6">
          <ProfileImageWrapper>
            <img src={staff?.image || UserImage} alt="Profile" />
          </ProfileImageWrapper>
        </div>
        <ProfileInfo>
          <div>
            <ProfileName>{staff.full_name}</ProfileName>
          </div>
        </ProfileInfo>
      </ProfileCard>
      <StudentDetailsCard>
        <InfoCard>
          <InfoTitle>Personal Details</InfoTitle>
          <InfoList>
            <InfoListItem>Name: {staff.full_name}</InfoListItem>
            <InfoListItem>Mobile Number: {staff.mobile_number}</InfoListItem>
            <InfoListItem>Email: {staff.email}</InfoListItem>
          </InfoList>
        </InfoCard>
        <InfoCard>
          <InfoTitle>Privileges</InfoTitle>
          <InfoList>
            {privilegesArray.map((privilege, index) => (
              <InfoListItem key={index}>{privilege}</InfoListItem>
            ))}
          </InfoList>
        </InfoCard>
      </StudentDetailsCard>
    </PageWrapper>
  );
};

export default ViewStaffComponent;