import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getCertificate, fetchStudent, fetchSessionByCourseId } from '../../Api/admin';

const StudyCertificate = () => {
    const { id, printNumber } = useParams();
    const printRef = useRef();
    const [certificate, setCertificate] = useState();
    const [rollNumber, setRollNumber] = useState('');
    const [student, setStudent] = useState();
    const [courseId, setCourseId] = useState('');
    const [session, setSession] = useState();

    const handlePrint = () => {
        const printIframe = document.createElement("iframe");
        printIframe.style.display = "none";
        document.body.appendChild(printIframe);
        const content = printRef.current.innerHTML;
        const printDocument = printIframe.contentWindow.document;
        printDocument.open();
        printDocument.write(`
          <html>
            <head>
              <title>Print Certificate</title>
              <style>
                @media print {
                  @page {
                    margin: 0;
                  }
                  body {
                    font-family: 'Times New Roman', Times, serif;
                    margin: 0;
                    padding: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .content {
                    width: 100%;
                    max-width: 800px;
                    padding: 80px; /* Increased padding */
                    box-shadow: none;
                    border-radius: 0;
                  }
                  h2 {
                    font-size: 28px; /* Slightly larger heading */
                    text-decoration: underline;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 40px; /* Added margin below the heading */
                  }
                  p {
                    margin: 25px 0; /* Increased margin for spacing */
                    font-size: 18px;
                    text-align: center; /* Center aligned paragraphs */
                  }
                  .left-align {
                    text-align: left;
                  }
                  .right-align {
                    text-align: right;
                  }
                }
              </style>
            </head>
            <body>
              <div class="content">
                ${content}
              </div>
              <script>
                window.onload = function() {
                  window.print();
                  document.body.removeChild(printIframe);
                };
              </script>
            </body>
          </html>
        `);
        printDocument.close();
    };

    useEffect(() => {
        const fetchPrintRequest = async () => {
            try {
                const numPrintNumber = Number(printNumber);
                let requestType;
                if (numPrintNumber === 0) {
                    requestType = "Original";
                } else if (numPrintNumber > 0) {
                    requestType = "Duplicate";
                }
                if (id) {
                    const res = await getCertificate(id, requestType);
                    if (res.data.success) {
                        setCertificate(res.data.data.certificate);
                        setRollNumber(res.data.data.certificate.roll_number);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchPrintRequest();
    }, [id, printNumber]);

    useEffect(() => {
        const fetchStudentData = async () => {
            try {
                if (rollNumber) {
                    const res = await fetchStudent(rollNumber);
                    if (res.data.success) {
                        setStudent(res.data.data);
                        setCourseId(res.data.data.course_id)
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchStudentData();
    }, [rollNumber]);

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                if (courseId) {
                    const res = await fetchSessionByCourseId(courseId)
                    if(res.data.success){
                        setSession(res.data.data.session)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchSessionData()
    }, [courseId])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const convertDateToText = (dateString) => {
        const date = new Date(dateString);
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const numberToWords = (num) => {
            const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
            const teens = ["ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen",
                "sixteen", "seventeen", "eighteen", "nineteen"];
            const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy",
                "eighty", "ninety"];
            const thousands = ["", "thousand"];
            if (num === 0) return "zero";
            let words = "";
            if (num >= 1000) {
                words += units[Math.floor(num / 1000)] + " " + thousands[1] + " ";
                num %= 1000;
            }
            if (num >= 100) {
                words += units[Math.floor(num / 100)] + " hundred ";
                num %= 100;
            }
            if (num >= 20) {
                words += tens[Math.floor(num / 10)] + " ";
                num %= 10;
            } else if (num >= 10) {
                words += teens[num - 10] + " ";
                return words.trim();
            }
            if (num > 0) {
                words += units[num] + " ";
            }
            return words.trim();
        };
        const dayToOrdinal = (day) => {
            const suffix = (day) => {
                if (day > 3 && day < 21) return 'th';
                switch (day % 10) {
                    case 1: return 'st';
                    case 2: return 'nd';
                    case 3: return 'rd';
                    default: return 'th';
                }
            };
            return numberToWords(day) + suffix(day);
        };
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const dayInWords = dayToOrdinal(day);
        const yearInWords = numberToWords(year);
        return `${dayInWords} ${month?.toLowerCase()}, ${yearInWords}`;
    };

    return (
        <div style={{ padding: "20px", textAlign: "center" }}>
            <div
                ref={printRef}
                style={{
                    border: "2px solid #000",
                    padding: "50px",
                    maxWidth: "800px",
                    margin: "auto",
                    textAlign: "left",
                    fontFamily: "'Times New Roman', Times, serif",
                    backgroundColor: "#fff",
                    position: "relative"
                }}
            >
                <h2
                    style={{
                        textAlign: "center",
                        textDecoration: "underline",
                        margin: "22px",
                        fontWeight: "bolder",
                        fontSize: "20px"
                    }}
                >
                    <strong>
                        {certificate?.printNumber === 0 ? "STUDY CERTIFICATE" : "DUPLICATE STUDY CERTIFICATE"}
                    </strong>
                </h2>
                <p style={{ textAlign: "center", fontSize: "20px", marginBottom: "30px" }}>
                    This is to certify that <strong>
                        <span style={{ marginRight: "4px" }}>
                            {student?.gender === "Female" ? "Miss" : "Mr."}
                        </span>
                        {student?.name.toUpperCase()}
                    </strong> is a  of
                    this institute studying Senior Secondary Examination (Equivalent to
                    10 + 2) in the Academic Year {session?.session_year}. {student?.gender === "Female" ? "Her" : "His"} <strong>Registration Number: {student?.roll_number}</strong> and the
                    student's Date of Birth is <strong>{formatDate(student?.date_of_birth)}</strong> ({convertDateToText(student?.date_of_birth)}).
                </p>
                <p style={{ textAlign: "center", fontSize: "20px" }}>
                    The Conduct of <strong>
                        <span style={{ marginRight: "4px" }}>
                            {student?.gender === "Female" ? "Miss" : "Mr."}
                        </span>
                        {student?.name.toUpperCase()}
                    </strong> during the period in our
                    Institution is good.
                </p>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "40px", fontSize: "18px" }}>
                    <div>
                        <p><strong>Place: </strong> Mysore</p>
                        <p><strong>Date: </strong> {formatDate(session?.issue_date)}</p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <p style={{ marginTop: "30px" }}><strong>PRINCIPAL</strong></p>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
                <button
                    onClick={handlePrint}
                    style={{
                        padding: "10px 20px",
                        backgroundColor: "#FBD6BD",
                        color: "black",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                    }}
                >
                    Print
                </button>
            </div>
        </div>
    );
};

export default StudyCertificate;