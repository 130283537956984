import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { addSession } from "../../Api/admin";
import { AddComponentForm, PageHeader, PageSubtitle, PageTitle, SubHeading, PageWrapper, DividerLine, Spacing20 } from "../UI/StyledComponents";

const AddSessionComponent = () => {
    const [session_year, setSessionYear] = useState("");
    const [session_month, setSessionMonth] = useState("");
    const [issue_date, setIssueDate] = useState(new Date().toISOString().split("T")[0]); // Format date to "yyyy-MM-dd"
    const navigate = useNavigate();

    const currentYear = new Date().getFullYear();
    const startYear = currentYear;
    const endYear = startYear + 6;

    const years = Array.from(
        { length: endYear - startYear + 1 },
        (_, i) => startYear + i
    );

    const handleYearChange = (e) => {
        setSessionYear(e.target.value);
    };

    const handleDateChange = (e) => {
        setIssueDate(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await addSession(session_month, session_year, issue_date);
            if (res.data.success) {
                toast.success("Successfully added session...");
                navigate("/admin/session");
            } else if (!res.data.success) {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <PageWrapper>
            <PageHeader>
                <PageTitle>Add Sessions</PageTitle>
                <PageSubtitle>Add and manage session details</PageSubtitle>
            </PageHeader>

            <AddComponentForm onSubmit={handleSubmit}>
                <SubHeading>Add Session Form</SubHeading>
                <DividerLine />
                <Spacing20 />

                <div className="mb-4">
                    <label htmlFor="month">Session Month</label>
                    <input
                        type="text"
                        id="month"
                        name="month"
                        value={session_month}
                        onChange={(e) => setSessionMonth(e.target.value)}
                        placeholder="Enter month (e.g., January)"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="year">Session Year</label>
                    <select
                        id="year"
                        name="year"
                        value={session_year}
                        onChange={handleYearChange}
                        required
                    >
                        <option value="" disabled>
                            Select a year
                        </option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label htmlFor="date">Marks issue date</label>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        value={issue_date}
                        onChange={handleDateChange}
                        required
                    />
                </div>

                <button type="submit">Submit</button>
            </AddComponentForm>
        </PageWrapper>
    );
};

export default AddSessionComponent;
