import React from 'react'
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import AddProfileCorrectionComponent from '../../../Components/Center/AddProfileCorrectionComponent';
import Sidebar from '../../../Components/Center/Sidebar';
import NavComponent from '../../../Components/Center/NavComponent';

const AddProfileCorrection = () => {
  return (
    <>
      <NavComponent />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <AddProfileCorrectionComponent />
        </MainContent>
      </Layout>
    </>
  )
}

export default AddProfileCorrection
