import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  PageHeader,
  PageSubtitle,
  PageWrapper,
  PageTitle,
  StyledTable,
  TableWrapper,
  SearchWrapper,
  SearchInput,
  Form,
  NavWrapper,
  NavButton,
 
  PageIndicator,
} from "../UI/StyledComponents";
import { getTasksByStaffId, updateTaskStatus } from "../../Api/staff";

const TaskComponent = () => {
  const { staffInfo } = useSelector((state) => state.auth);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [staffId, setStaffId] = useState("");
  const [status, setStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);


  useEffect(() => {
    const decoding = async () => {
      if (staffInfo) {
        const decodedToken = jwtDecode(staffInfo);
        if (decodedToken && decodedToken.id) {
          setStaffId(decodedToken.id);
        }
      }
    };
    decoding();
  }, [staffInfo]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        if (staffId) {
          const res = await getTasksByStaffId(staffId);
          if (res.data.success) {
            setTasks(res.data.data);
          }
        }
      } catch (error) {
        setError("Failed to fetch tasks");
      } finally {
        setLoading(false);
      }
    };
    fetchTasks();
  }, [staffId, status]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      const res = await updateTaskStatus(taskId, newStatus);
      if (res.data.success) {
        setStatus(!status);
        const updatedTasks = tasks.map((task) =>
          task.id === taskId ? { ...task, status: newStatus } : task
        );
        setTasks(updatedTasks);
      }
    } catch (error) {
      console.error("Error updating task status:", error);
      setError("Failed to update task status");
    }
  };

  if (loading) {
    return <p>Loading tasks...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const getPriorityColor = (priority) => {
    if (priority === "High") return "text-red-500";
    if (priority === "Normal") return "text-gray-500";
    if (priority === "Low") return "text-green-500";
    return "";
  };

  const getStatusColor = (status) => {
    if (status === "Completed") return "text-green-500 ";
    if (status === "In Progress") return "text-blue-500 ";
    return "text-grey-500";
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Define filteredTasks based on the search query
  const filteredTasks = tasks.filter((task) =>
    task.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Your Tasks</PageTitle>
        <PageSubtitle>View and manage tasks assigned to you</PageSubtitle>
      </PageHeader>
      {tasks.length > 0 ? (
        <>
          <Form>
            <SearchWrapper>
              <SearchInput
                placeholder="Search"
                required
                value={searchQuery}
                onChange={handleSearch}
              />
            </SearchWrapper>
          </Form>

             {filteredTasks.length === 0 ? (
            <p className=" text-grey-500 text-center">
              No results found for searched Task.
            </p>

             ):(
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th>Task</th>
                  <th>Priority</th>
                  <th>Due Date</th>
                  <th>Assigned Date</th>
                  <th>Change Status</th>
                </tr>
              </thead>
              <tbody>
                {currentTasks
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((task) => (
                    <tr key={task.id} className="border-b">
                      <td>{task.title}</td>
                      <td
                        className={`py-2 px-4 ${getPriorityColor(
                          task.priority
                        )}`}
                      >
                        {task.priority}
                      </td>
                      <td>{task.due_date}</td>
                      <td>{formatDate(task.created_at)}</td>
                      <td>
                        <select
                          value={task.status}
                          onChange={(e) =>
                            handleStatusChange(task.id, e.target.value)
                          }
                          className={`border-none focus:outline-none ${getStatusColor(
                            task.status
                          )}`}
                        >
                          <option value="Assigned">Assigned</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </StyledTable>
          </TableWrapper>

             )}


          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </>
      ) : (
        <p className="text-grey-500 text-center">
          No tasks assigned to you.
        </p>
      )}
    </PageWrapper>
  );
};

export default TaskComponent;
