import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faBan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { courses, disableCourse } from "../../Api/admin";
import {
  SearchInput,
  SearchWrapper,
  Form,
  FilterWrapper,
  FilterLabel,
  FilterSelect,
  PageWrapper,
  PageSubtitle,
  PageHeader,
  PageTitle,
  AddButton,
  NavButton,
  NavWrapper,
  PageIndicator,
  StyledTable,
  TableWrapper,
} from "../UI/StyledComponents";

const CoursesComponent = () => {
  const [course, setCourse] = useState([]);
  const [status, setStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const res = await courses();
        if (res.data.success) {
          setCourse(res.data.data.courses);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourseData();
  }, [status]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleEdit = (id) => async () => {
    try {
      navigate(`/staff/editCourse/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisable = (id, currentStatus, courseName) => {
    const isCurrentlyActive = currentStatus === "active";
    const actionText = isCurrentlyActive ? "Disable" : "Enable";
    const successMessage = isCurrentlyActive
      ? "The course has been successfully disabled."
      : "The course has been successfully enabled.";

    Swal.fire({
      text: `Are you sure you want to ${actionText.toLowerCase()} the course ${courseName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: isCurrentlyActive ? "#d33" : "#3085d6",
      cancelButtonColor: "#3085d6",
      confirmButtonText: `Yes, ${actionText.toLowerCase()} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await disableCourse(id);
          if (res.data.success) {
            setStatus(!status);
            Swal.fire(actionText, successMessage, "success");
          }
        } catch (error) {
          Swal.fire("Error!", "There was an issue changing the course status.", "error");
          console.log(error);
        }
      }
    });
  };

  const handleAdd = async () => {
    try {
      navigate("/staff/addCourse");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  const filteredCourses = course.filter((val) => {
    const matchesSearchQuery = val.course_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesStatus =
      selectedStatus === "" || val.status === selectedStatus;
    return matchesSearchQuery && matchesStatus;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCourses = filteredCourses.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredCourses.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Courses</PageTitle>
        <PageSubtitle>View and Manage all courses</PageSubtitle>
      </PageHeader>

      {course.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No courses to display.</p>
          <AddButton type="button" onClick={handleAdd}>
            Add Course
          </AddButton>
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center">
            <Form>
              <FilterWrapper>
                <FilterLabel>Filter by Status</FilterLabel>
                <FilterSelect
                  id="statusFilter"
                  onChange={(e) => handleStatusFilter(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </FilterSelect>
              </FilterWrapper>
              <SearchWrapper>
                <SearchInput
                  placeholder="Search"
                  required
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </SearchWrapper>

              <AddButton type="button" onClick={handleAdd}>
                Add Course
              </AddButton>
            </Form>
          </div>

          {filteredCourses.length === 0 ? (
            <div className="text-center">
              <p className="text-gray-700 mb-4">No matching courses found.</p>
            </div>
          ) : (
            <>
              <TableWrapper>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>Sl No</th>
                      <th>Course Name</th>
                      <th>Exam type</th>
                      <th>Date Added</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCourses
                      .sort(
                        (a, b) => new Date(b.created_at) - new Date(a.created_at)
                      )
                      .map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{val.course_name}</td>
                          <td>{val.exam_type}</td>
                          <td>{formatDate(val.created_at)}</td>
                          <td
                            className={`px-4 py-2 border-b ${val.status === "active"
                              ? "text-green-500"
                              : "text-red-500"
                              } w-1/12`}
                          >
                            {val.status}
                          </td>
                          <td>
                            <button onClick={handleEdit(val.id)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button
                              onClick={() => handleDisable(val.id, val.status, val.course_name)}
                            >
                              <FontAwesomeIcon
                                icon={faBan}
                                className={`text-lg ${
                                  val.status === "active"
                                    ? "text-green-500"
                                    : "text-red-500"
                                }`}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </StyledTable>
              </TableWrapper>

              {totalPages > 1 && (
                <NavWrapper>
                  <NavButton
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </NavButton>
                  <PageIndicator>
                    Page {currentPage} of {totalPages}
                  </PageIndicator>
                  <NavButton
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </NavButton>
                </NavWrapper>
              )}
            </>
          )}
        </>
      )}
    </PageWrapper>
  )
}

export default CoursesComponent
