import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../Assets/logo.png";
import dashboardLogo from "../../Assets/dashboardLogo.svg";
import staffLogo from "../../Assets/staffLogo.svg";
import centerLogo from "../../Assets/centerLogo.svg";
import studentLogo from "../../Assets/studentLogo.svg";
import courseLogo from "../../Assets/courseLogo.svg";
import examLogo from "../../Assets/examLogo.svg";
import resultLogo from "../../Assets/resultLogo.svg";
import passwordLogo from "../../Assets/passwordLogo.svg";
import logoutLogo from "../../Assets/logoutLogo.svg";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { adminLogout } from "../../Store/slice/authSlice";
import { logout } from "../../Api/auth";
import SubjectLogo from "../../Assets/subjectLogo.svg";
import sessionLogo from "../../Assets/sessionLogo.svg";
import {
  SidebarButton,
  SidebarLink,
  SidebarWrapper,
  ToggleButton,
  LogoWrapper,
} from "../UI/StyledComponents";
import PrintIcon from "../../Assets/printIcon.svg";
import correctionIcon from "../../Assets/correctionIcon.svg";
import timeTableIcon from "../../Assets/timeTableIcon.svg";

const Sidebar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isPrintMenuOpen, setIsPrintMenuOpen] = useState(false)

  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname.startsWith("/admin/requests")) {
      setIsPrintMenuOpen(true);
    }
  }, [location.pathname]);

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleLogout = async () => {
    try {
      const res = await logout();
      if (res?.data.success) {
        dispatch(adminLogout());
        toast.success("Logged out successfully..");
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const togglePrintSubMenu = () => {
    setIsPrintMenuOpen(!isPrintMenuOpen)
  }

  return (
    <>
      <ToggleButton
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        onClick={() => setOpenSidebar(!openSidebar)}
      >
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </ToggleButton>
      <SidebarWrapper id="logo-sidebar" ref={sidebarRef} aria-label="Sidebar" openSidebar={openSidebar} onClick={handleCloseSidebar}>

        <LogoWrapper to="/center/dashboard" >
          <img src={logo} alt="ksc Logo" />
          <h1>Karnataka State Council</h1>
          <h2>ಕರ್ನಾಟಕ ಸ್ಟೇಟ್ ಕೌನ್ಸಿಲ್</h2>
        </LogoWrapper>
        <ul>
          <li>
            <SidebarLink to="/admin/dashboard/" activeClassName="active">
              <img src={dashboardLogo} alt="Dashboard Icon" />
              <span>Dashboard</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/admin/center" activeClassName="active">
              <img src={centerLogo} alt="Center Icon" />
              <span>Centers</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/admin/student" activeClassName="active">
              <img src={studentLogo} alt="Student Icon" />
              <span>Students</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/admin/staff" activeClassName="active">
              <img src={staffLogo} alt="Staff Icon" />
              <span>Staffs</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/admin/exam" activeClassName="active">
              <img src={examLogo} alt="Exam Icon" />
              <span>Exams</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/admin/course" activeClassName="active">
              <img src={courseLogo} alt="Course Icon" />
              <span>Courses</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/admin/subject" activeClassName="active">
              <img src={SubjectLogo} alt="Course Icon" />
              <span>Subjects</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/admin/session" activeClassName="active">
              <img src={sessionLogo} alt="session icon" />
              <span>Sessions</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/admin/timetable" activeClassName="active">
              <img src={timeTableIcon} alt="timetable Icon" />
              <span>Timetables</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/admin/corrections" activeClassName="active">
              <img src={correctionIcon} alt="timetable Icon" />
              <span>Corrections</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink as="button" onClick={togglePrintSubMenu} activeClassName="active">
              <img src={PrintIcon} alt="Certificate Icon" />
              <span>Printing requests</span>
            </SidebarLink>
          </li>
          {isPrintMenuOpen && (
            <ul>
              <SidebarButton>
                <SidebarLink to="/admin/requests/original/" activeClassName="active">
                  <img src={correctionIcon} alt="Correction Icon" />
                  <span>Original certificate</span>
                </SidebarLink>
              </SidebarButton>
              <SidebarButton>
                <SidebarLink to="/admin/requests/duplicate/" activeClassName="active">
                  <img src={resultLogo} alt="Result Icon" />
                  <span>Duplicate certificate</span>
                </SidebarLink>
              </SidebarButton>
            </ul>
          )}
          <li>
            <SidebarLink to="/admin/result/updateResult" activeClassName="active">
              <img src={resultLogo} alt="Result icon" />
              <span>Update Results</span>
            </SidebarLink>
          </li>
          {/* <li>
            <SidebarLink as="button" onClick={toggleSubMenu} activeClassName="active">
              <img src={resultLogo} alt="Result Icon" />
              <span>Results</span>
            </SidebarLink>
          </li> */}
          {/* {isSubMenuOpen && (
            <ul>
              <SidebarButton>
                <SidebarLink to="/admin/result/updateResult" >
                  <img src={resultLogo} alt="Result Icon" />
                  <span>Update results</span>
                </SidebarLink>
              </SidebarButton>
              <SidebarButton>
                <SidebarLink to="/admin/result/publishResult" activeClassName="active">
                  <img src={resultLogo} alt="Result Icon" />
                  <span>Publish results</span>
                </SidebarLink>
              </SidebarButton>
            </ul>
          )}
           */}
          {/* <li>
              <SidebarLink  as="button" onClick={toggleCorrectionSubMenu}  activeClassName="active">
                <img src={correctionIcon} alt="Result correction Icon" />
                <span>Corrections</span>
              </SidebarLink>
            </li>
            {isCorrectionMenuOpen && (
              <ul>
                <SidebarButton>
                  <SidebarLink to="/admin/corrections/profileCorrection/"  activeClassName="active">
                    <img src={correctionIcon} alt="Correction Icon" />
                    <span>profile Correction</span>
                  </SidebarLink>
                </SidebarButton>
                <SidebarButton>
                  <SidebarLink to="/admin/corrections/resultCorrection/"  activeClassName="active">
                    <img src={resultLogo} alt="Result Icon" />
                    <span>Result Correction</span>
                  </SidebarLink>
                </SidebarButton>
              </ul>
            )} */}
          {/* <li>
            <SidebarLink to="/admin/taskManager" activeClassName="active">
              <img src={reportsIcon} alt="Task Icon" />
              <span>Task Manager</span>
            </SidebarLink>
          </li> */}
          <li>
            <SidebarLink to="/admin/password" activeClassName="active">
              <img src={passwordLogo} alt="Password Icon" />
              <span>Change password</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/" onClick={handleLogout} activeClassName="active">
              <img src={logoutLogo} alt="Logout Icon" />
              <span>Logout</span>
            </SidebarLink>
          </li>
        </ul>
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
