import React, { useState } from "react";
import { addStaff } from "../../Api/admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  AddComponentForm,
  PageHeader,
  PageSubtitle,
  PageTitle,
  SubHeading,
  PageWrapper,
  DividerLine,
  Spacing20,
} from "../UI/StyledComponents";

const AddStaffComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [full_name, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [privileges, setPrivileges] = useState([
    { id: 1, name: "Center Management", selected: false },
    { id: 2, name: "Student Management", selected: false },
    { id: 3, name: "Exam Management", selected: false },
    { id: 4, name: "Course Management", selected: false },
    { id: 5, name: "Subject Management", selected: false },
    { id: 6, name: "Session Management", selected: false },
    { id: 7, name: "Timetable Management", selected: false },
    { id: 8, name: "Corrections", selected: false },
    { id: 9, name: "Printing Requests", selected: false },
    { id: 10, name: "Result Updation", selected: false },

  ]);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const handleMobileChange = (e) => {
    setMobileNumber(e.target.value);
    setPassword(e.target.value); 
  };

  const handlePrivilegeChange = (id) => {
    setPrivileges((prevPrivileges) =>
      prevPrivileges.map((privilege) =>
        privilege.id === id
          ? { ...privilege, selected: !privilege.selected }
          : privilege
      )
    );
  };

  const filteredPrivileges = privileges.filter((privilege) =>
    privilege.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const role_name = "STAFF";
      const selectedPrivileges = privileges
        .filter((privilege) => privilege.selected)
        .map((privilege) => privilege.name);
      const privilegesString = selectedPrivileges.join(", ");
      const res = await addStaff(
        username,
        password,
        mobileNumber,
        email,
        full_name,
        role_name,
        privilegesString
      );
      if (res.data.success) {
        toast.success("Successfully added staff...");
        navigate("/admin/staff");
      } else if (!res.data.success) {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to add staff. Please try again.");
    }
  };

  return (
    <>
      <PageWrapper>
        <PageHeader>
          <PageTitle>Staff Registration</PageTitle>
          <PageSubtitle>Registration and managing staff form</PageSubtitle>
        </PageHeader>
        <AddComponentForm onSubmit={handleSubmit}>
          <SubHeading>Staff Registration Form</SubHeading>
          <DividerLine></DividerLine>
          <Spacing20></Spacing20>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <label htmlFor="full_name">Full Name</label>
          <input
            type="text"
            id="full_name"
            name="full_name"
            value={full_name}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="mobileNumber">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={mobileNumber}
            onChange={handleMobileChange}
            required
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            disabled
            required
          />
          <label htmlFor="privileges">Privileges</label>
          <form>
            <div>
              <input
                type="search"
                id="search-dropdown"
                placeholder="Search privileges"
                required
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </form>
          <DividerLine></DividerLine>
          <div className="max-h-40 overflow-y-auto border border-gray-300 p-2 rounded-lg">
            {filteredPrivileges.length > 0 ? (
              filteredPrivileges.map((privilege) => (
                <div
                  key={privilege.id}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    id={privilege.id}
                    name="privileges"
                    checked={privilege.selected}
                    onChange={() => handlePrivilegeChange(privilege.id)}
                  />
                  <label htmlFor={privilege.id} style={{ marginLeft: "8px" }}>
                    {privilege.name}
                  </label>
                </div>
              ))
            ) : (
              <div style={{ padding: "8px", color: "red" }}>
                No such privilege found.
              </div>
            )}
          </div>
          <DividerLine></DividerLine>
          <button type="submit">Submit</button>
        </AddComponentForm>
      </PageWrapper>
    </>
  );
};

export default AddStaffComponent;