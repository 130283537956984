import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { changePassword } from "../../Api/admin";
import {
  PageWrapper,
  PageHeader,
  PageTitle,
  PageSubtitle,
  SubHeading,
  AddButton,
  FormContainer,
  FormGroup,
  Label,
  Input,
} from "../UI/StyledComponents";

const ChangePasswordComponent = () => {
  const { centerInfo } = useSelector((state) => state.auth);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [centerId, setCenterId] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const decoding = async () => {
      if (centerInfo) {
        const decodedToken = jwtDecode(centerInfo);
        if (decodedToken && decodedToken.id) {
          setCenterId(decodedToken.id);
        }
      }
    };
    decoding();
  }, [centerInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (centerId) {
        if (newPassword !== confirmPassword) {
          return toast.error("Entered passwords do not match!!");
        }
        const role = "CENTER_MANAGER";
        const res = await changePassword(centerId, oldPassword, newPassword, role);
        if (res.data.success) {
          toast.success("Successfully changed the password");
          navigate("/center/dashboard");
        } else if (!res.data.success) {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Change your Password</PageTitle>
        <PageSubtitle>Reset your password</PageSubtitle>
      </PageHeader>
      <div className="w-full p-6 bg-white mx-auto rounded-md shadow md:mt-0 sm:max-w-md sm:p-8">
        <SubHeading>Change Password</SubHeading>
        <hr />

        <FormContainer onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="old-password">Old Password</Label>
            <Input
              type="password"
              name="old-password"
              id="old-password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              placeholder="Enter your old password"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="new-password">New Password</Label>
            <div className="relative">
              <Input
                type={passwordShown ? "text" : "password"}
                name="new-password"
                id="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="••••••••"
                required
              />
              <span
                className="absolute right-3 top-3 cursor-pointer text-gray-500"
                onClick={togglePasswordVisibility}
              >
                {passwordShown ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="confirm-password">Confirm Password</Label>
            <div className="relative">
              <Input
                type={confirmPasswordShown ? "text" : "password"}
                name="confirm-password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="••••••••"
                required
              />
              <span
                className="absolute right-3 top-3 cursor-pointer text-gray-500"
                onClick={toggleConfirmPasswordVisibility}
              >
                {confirmPasswordShown ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </FormGroup>

          <div className="flex justify-center mt-4">
            <AddButton type="submit">Submit</AddButton>
          </div>
        </FormContainer>
      </div>
    </PageWrapper>
  );
};

export default ChangePasswordComponent;
