import React, { useState, useEffect } from 'react';
import {
  PageHeader, PageSubtitle, PageWrapper, PageTitle,
  TableWrapper, StyledTable, AddButton, NoDataText, NoDataWrapper,
  Form, SearchWrapper, SearchInput, NavWrapper, NavButton, PageIndicator
} from "../UI/StyledComponents";
import StudentSelectModal from './StudentSelectModal';
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchOriginalRequests, getStudents, createOriginalCertificate,
  fetchDuplicateRequests, createDuplicateCertificate
} from '../../Api/center';

const MarksCardComponent = ({ isDuplicate }) => {
  const { centerInfo } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const [approvedRollNumbers, setApprovedRollNumbers] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [centerId, setCenterId] = useState("");
  const [certificateRequests, setCertificateRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [status, setStatus] = useState(false);
  const [isSubmit, setIsSubmit]=useState(false);

  useEffect(() => {
    const decoding = async () => {
      if (centerInfo) {
        const decodedToken = jwtDecode(centerInfo);
        if (decodedToken && decodedToken.id) {
          setCenterId(decodedToken.id);
        }
      }
    };
    decoding();
  }, [centerInfo]);

  useEffect(() => {
    const fetchApprovedRequests = async () => {
      try {
        if (centerId) {
          const requestType = 'Marks Card';
          const res = await fetchOriginalRequests(requestType, centerId);
          if (res.data.success) {
            const approvedRequests = isDuplicate ? res.data.data.requests.filter(request => request.status === 'approved') : res.data.data.requests;
            const approvedRollNumbersList = approvedRequests.map(request => request.roll_number);
            setApprovedRollNumbers(approvedRollNumbersList);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchApprovedRequests();
  }, [centerId, isSubmit, isDuplicate]);

  useEffect(() => {
    const fetchStudentData = async () => {
      if (centerId) {
        try {
          const res = await getStudents();
          if (res.data.success) {
            const filteredStudents = res.data.data
              .filter(student => student.center_id === centerId)
              .filter(student =>
                isDuplicate
                  ? approvedRollNumbers.includes(student.roll_number)
                  : !approvedRollNumbers.includes(student.roll_number) || !approvedRollNumbers.includes(student.roll_number)
              );
            setStudents(filteredStudents);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchStudentData();
  }, [centerId, isDuplicate, approvedRollNumbers, isSubmit]);

  useEffect(() => {
    const fetchCertificateData = async () => {
      try {
        if (centerId) {
          const requestType = 'Marks Card';
          const res = isDuplicate ? await fetchDuplicateRequests(requestType, centerId) : await fetchOriginalRequests(requestType, centerId);
          if (res.data.success) {
            setStatus(!status)
            setCertificateRequests(res.data.data.requests);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCertificateData();
  }, [isDuplicate, status, centerId, isSubmit]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, "0")}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`;
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    if (selectedStudent) {
      const request = 'Marks Card';
      const res = isDuplicate
        ? await createDuplicateCertificate(selectedStudent.roll_number, request)
        : await createOriginalCertificate(selectedStudent.roll_number, request);

      if (res.data.success) {
        setIsSubmit(!isSubmit);
        toast.success('Successfully requested...');
      } else {
        toast.error(res.data.message);
      }
    }
    handleClose();
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredRequests = certificateRequests.filter((val) => {
    const matchesSearchQuery =
      val.Student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.roll_number.toString().includes(searchQuery.toString());
    return matchesSearchQuery;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRequests = filteredRequests.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{isDuplicate ? 'Duplicate Marks Card Certificate' : 'Marks Card Certificate'}</PageTitle>
        <PageSubtitle>{isDuplicate ? 'Manage duplicate Marks card certificate print requests' : 'Manage Marks card certificate print requests'}</PageSubtitle>
      </PageHeader>
      <Form>
        <SearchWrapper>
          <SearchInput
            placeholder="Search"
            required
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchWrapper>
        <AddButton type="button" onClick={handleOpen}>
          Apply for Marks Card
        </AddButton>
      </Form>
      {certificateRequests.length === 0 ? (
        <NoDataWrapper>
          <NoDataText>No request to display.</NoDataText>
        </NoDataWrapper>
      ) : currentRequests.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No requests found.</p>
        </div>
      ) : (
        <>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th>Roll No</th>
                  <th>Student Name</th>
                  <th>Printing Request</th>
                  {isDuplicate && <th>Print Number</th>}
                  <th>Date Applied</th>
                </tr>
              </thead>
              <tbody>
                {currentRequests.map((val) => (
                  <tr key={val.id}>
                    <td>{val.roll_number}</td>
                    <td>{val?.Student.name}</td>
                    <td>{val.request}</td>
                    {isDuplicate && <td>{val.printNumber}</td>}
                    <td>{formatDate(val.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </>
      )}
      <StudentSelectModal
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        students={students}
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
      />
    </PageWrapper>
  );
};

export default MarksCardComponent;