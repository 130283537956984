import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import UserImage from "../../Assets/userImage.png";
import {
  ProfileCard,
  ProfileImageWrapper,
  ProfileInfo,
  ProfileName,
  StudentDetailsCard,
  InfoCard,
  InfoTitle,
  InfoList,
  PageTitle,
  PageSubtitle,
  InfoListItem,
  PageHeader,
  PageWrapper,
} from "../UI/StyledComponents";
import { fetchCourse, fetchCenter } from "../../Api/student";

const DashboardComponent = () => {
  const { studentInfo } = useSelector((state) => state.auth);
  const [student, setStudent] = useState("");
  const [courseId, setCourseId] = useState("");
  const [course, setCourse] = useState();
  const [centerId, setCenterId] = useState("");
  const [center, setCenter] = useState();

  useEffect(() => {
    const decoding = () => {
      if (studentInfo) {
        const decodedToken = jwtDecode(studentInfo);
        if (decodedToken && decodedToken.id) {
          setStudent(decodedToken.user);
          setCourseId(decodedToken.user.course_id);
          setCenterId(decodedToken.user.center_id);
        }
      }
    };
    decoding();
  }, [studentInfo]);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        if (courseId) {
          const res = await fetchCourse(courseId);
          if (res.data.success) {
            setCourse(res.data.data.course);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourseData();
  }, [courseId]);

  useEffect(() => {
    const fetchCenterData = async () => {
      try {
        if (centerId) {
          const res = await fetchCenter(centerId);
          if (res.data.success) {
            setCenter(res.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCenterData();
  }, [centerId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Student Profile</PageTitle>
        <PageSubtitle>View personal and academic details</PageSubtitle>
      </PageHeader>

      <ProfileCard>
        <div className="relative mb-5 mt-6">
          <ProfileImageWrapper>
            <img src={student?.image || UserImage} alt="Profile" />
          </ProfileImageWrapper>
        </div>
        <ProfileInfo>
          <div>
            <ProfileName>{student.name}</ProfileName>
          </div>
        </ProfileInfo>
      </ProfileCard>

      <StudentDetailsCard>
        <InfoCard>
          <InfoTitle>Personal Details</InfoTitle>
          <InfoList>
            <InfoListItem>Student Name : {student.name}</InfoListItem>
            <InfoListItem>Father's Name : {student.father_name}</InfoListItem>
            <InfoListItem>Mother's Name : {student.mother_name}</InfoListItem>
            <InfoListItem>
              Date of Birth : {formatDate(student.date_of_birth)}
            </InfoListItem>
            <InfoListItem>Gender : {student.gender}</InfoListItem>
            <InfoListItem>Address : {student.address}</InfoListItem>
            <InfoListItem>
              Admission Status : {student.admission_status}
            </InfoListItem>
            <InfoListItem>Mobile number : {student.mobile_number}</InfoListItem>
            <InfoListItem>Email : {student.email}</InfoListItem>
          </InfoList>
        </InfoCard>

        <InfoCard>
          <InfoTitle>Academic Details</InfoTitle>
          <InfoList>
            <InfoListItem>Center Name : {center?.center_name}</InfoListItem>
            <InfoListItem>Course : {course?.course_name}</InfoListItem>
            <InfoListItem>Roll No : {student.roll_number ? student.roll_number : 'Not assigned yet'}</InfoListItem>
            <InfoListItem>
              Admission Status : {student.admission_status}
            </InfoListItem>
          </InfoList>
        </InfoCard>
      </StudentDetailsCard>
    </PageWrapper>
  );
};

export default DashboardComponent;
