import React, { useState, useEffect } from "react";
import { dashboardData } from '../../Api/admin';

const DashboardComponent = () => {
  const [dashboard, setDashboard] = useState()

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const res = await dashboardData()
        if (res.data.success) {
          setDashboard(res.data.data.dashboard)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchDashboardData()
  }, [])

  return (
    <>
      <div className="p-6 grid grid-cols-4 gap-6">
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold text-gray-500">Total Students</h3>
            <p className="text-2xl font-bold text-gray-800">{dashboard?.totalStudent}</p>
            <p className="text-sm text-gray-500">Total active students</p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold text-gray-500">Total Courses</h3>
            <p className="text-2xl font-bold text-gray-800">{dashboard?.totalCourse}</p>
            <p className="text-sm text-gray-500">Total offered courses</p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold text-gray-500">Total Staff</h3>
            <p className="text-2xl font-bold text-gray-800">{dashboard?.totalStaff}</p>
            <p className="text-sm text-gray-500">Active staff in board</p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold text-gray-500">Total Centers</h3>
            <p className="text-2xl font-bold text-gray-800">{dashboard?.totalCenter}</p>
            <p className="text-sm text-gray-500">Active centers in board</p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold text-gray-500">Original Certificate Request</h3>
            <p className="text-2xl font-bold text-gray-800">{dashboard?.pendingPrintRequests}</p>
            <p className="text-sm text-gray-500">Request for approval</p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold text-gray-500">Duplicate Certificate Request</h3>
            <p className="text-2xl font-bold text-gray-800">{dashboard?.pendingDuplicatePrintRequests}</p>
            <p className="text-sm text-gray-500">Request for approval</p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold text-gray-500">Result Corrections</h3>
            <p className="text-2xl font-bold text-gray-800">{dashboard?.pendingResultCorrections}</p>
            <p className="text-sm text-gray-500">Requests yet to be approved</p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold text-gray-500">Profile Corrections</h3>
            <p className="text-2xl font-bold text-gray-800">{dashboard?.pendingProfileCorrections}</p>
            <p className="text-sm text-gray-500">Requests yet to be approved</p>
          </div>
        </div>
      </div>
    </>
  )
};

export default DashboardComponent;
