import React, { useState, useRef, useEffect } from "react";
import logo from "../../Assets/logo.png";
import dashboardLogo from "../../Assets/dashboardLogo.svg";
import logoutLogo from "../../Assets/logoutLogo.svg";
import courseLogo from "../../Assets/courseLogo.svg";
import hallticketLogo from "../../Assets/hallticketLogo.svg";
import resultLogo from "../../Assets/resultLogo.svg";
import passwordLogo from "../../Assets/passwordLogo.svg";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { studentLogout } from "../../Store/slice/authSlice";
import { logout } from "../../Api/auth";
import {
  LogoWrapper,
  SidebarLink,
  SidebarWrapper,
  ToggleButton,
} from "../UI/StyledComponents";

const Sidebar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleLogout = async () => {
    const res = await logout();
    if (res?.data.success) {
      dispatch(studentLogout());
      toast.success("Logged out successfully..");
      navigate("/login");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  return (
    <>
      <ToggleButton
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        onClick={() => setOpenSidebar(!openSidebar)}
      >
        <span>Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </ToggleButton>
      <SidebarWrapper id="logo-sidebar" ref={sidebarRef} aria-label="Sidebar">
        <div onClick={handleCloseSidebar}>
          <LogoWrapper to="/student/dashboard">
            <img src={logo} alt="ksc Logo" />
            <h1>Karnataka State Council</h1>
            <h2>ಕರ್ನಾಟಕ ಸ್ಟೇಟ್ ಕೌನ್ಸಿಲ್</h2>
          </LogoWrapper>
          <ul>
            <li>
              <SidebarLink to="/profile">
                <img src={dashboardLogo} alt="Dashboard Icon" />
                <span>Profile</span>
              </SidebarLink>
            </li>
            <li>
              <SidebarLink to="/course">
                <img src={courseLogo} alt="Course Icon" />
                <span>Course overview</span>
              </SidebarLink>
            </li>
            {/* <li>
              <SidebarLink to="/certificate">
                <img src={certificateLogo} alt="Certificate Icon" />
                <span>Certificates</span>
              </SidebarLink>
            </li> */}
            <li>
              <SidebarLink to="/hallticket">
                <img src={hallticketLogo} alt="Hallticket Icon" />
                <span>Hall ticket</span>
              </SidebarLink>
            </li>
            <li>
              <SidebarLink to="/results">
                <img src={resultLogo} alt="Result Icon" />
                <span>Results</span>
              </SidebarLink>
            </li>
            <li>
              <SidebarLink to="/changePassword">
                <img src={passwordLogo} alt="Password Icon" />
                <span>Change password</span>
              </SidebarLink>
            </li>
            <li>
              <SidebarLink to="/" onClick={handleLogout}>
                <img src={logoutLogo} alt="Logout Icon" />
                <span>Logout</span>
              </SidebarLink>
            </li>
          </ul>
        </div>
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
