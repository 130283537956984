import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../../Api/auth";
import { useDispatch } from "react-redux";
import { adminLogout } from "../../Store/slice/authSlice";
import { useNavigate } from "react-router-dom";
import adminLogo from '../../Assets/adminLogo.svg';

const NavComponent = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "Good Afternoon";
    } else if (currentHour >= 17 && currentHour < 21) {
      return "Good Evening";
    } else {
      return "Good Night";
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.closest("#user-menu-button")) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLogout = async () => {
    try {
      const res = await logout();
      if (res?.data.success) {
        dispatch(adminLogout());
        toast.success("Logged out successfully..");
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <nav className="bg-[#F9FAFB] border-b-[1px] border-[#E5E7EB] sticky top-0 z-50 ml-[255px]">
      <div className="max-w-screen-3xl flex flex-wrap items-center justify-between mx-auto p-2 relative shadow-b-md">
        <div className="flex items-center">
          <h1 className="text-sm font-semibold text-gray-800">
            {getGreeting()}, Admin!
          </h1>
        </div>
        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse relative">
          <button
            type="button"
            className="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300"
            id="user-menu-button"
            aria-expanded={isDropdownOpen ? "true" : "false"}
            onClick={toggleDropdown}
          >
            <span className="sr-only">Open user menu</span>
            <img className="w-6 h-6 rounded-full" src={adminLogo} alt="Admin logo" />
          </button>
          <div
            ref={dropdownRef}
            className={`z-50 ${isDropdownOpen ? "block" : "hidden"} absolute right-0 top-6 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow`}
            id="user-dropdown"
          >
            <div className="px-4 py-3">
              <span className="block text-sm text-gray-900">
                Admin
              </span>
              <span className="block text-sm text-gray-500 truncate">
                admin@ksc.com
              </span>
            </div>
            <ul className="py-2" aria-labelledby="user-menu-button">
              <li>
                <NavLink to="/" onClick={handleLogout}>
                  <span className="p-4 font-semibold hover:text-red-500 cursor-pointer">Logout</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default NavComponent;