import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faBan,
  faPrint,
  faUnlockAlt,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { centers, disableCenter, changePasswordByAdmin, fetchCenter } from "../../Api/admin";
import {
  AddButton,
  FilterLabel,
  FilterWrapper,
  FilterSelect,
  PageHeader,
  PageTitle,
  PageSubtitle,
  NavButton,
  NavWrapper,
  PageIndicator,
  PageWrapper,
  StyledTable,
  TableWrapper,
  SearchWrapper,
  SearchInput,
  Form,
} from "../UI/StyledComponents";

const CenterComponent = () => {
  const [center, setCenter] = useState([]);
  const [status, setStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [academicYearFrom, setAcademicYearFrom] = useState(2020);
  const [academicYearTo, setAcademicYearTo] = useState(2021);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCenterData = async () => {
      try {
        setLoading(true);
        const res = await centers();
        if (res.data.success) {
          setCenter(res.data.data);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchCenterData();
  }, [status]);

  const getCenterDetails = async (id) => {
    try {
      const res = await fetchCenter(id);
      if (res.data.success) {
        return res.data.data;
      }
    } catch (error) {
      console.log("Error fetching center details:", error);
    }
    return null;
  };

  const handlePrint = async (id) => {
    try {
      const centerData = await getCenterDetails(id);
      if (centerData) {
        setSelectedCenter(centerData);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log("Error fetching center details:", error);
    }
  };

  const handleDisable = (id, currentStatus, centerName) => async () => {
    const isCurrentlyActive = currentStatus === "active";
    const actionText = isCurrentlyActive ? "Disable" : "Enable";
    const successMessage = isCurrentlyActive
      ? "The center has been successfully disabled."
      : "The center has been successfully enabled.";

    Swal.fire({
      text: `Are you sure you want to ${actionText.toLowerCase()} the center ${centerName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: isCurrentlyActive ? "#d33" : "#3085d6",
      cancelButtonColor: "#3085d6",
      confirmButtonText: `Yes, ${actionText.toLowerCase()} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await disableCenter(id);
          if (res.data.success) {
            setStatus(!status);
            Swal.fire(actionText, successMessage, "success");
          }
        } catch (error) {
          Swal.fire(
            "Error!",
            "There was an issue changing the center status.",
            "error"
          );
          console.log(error);
        }
      }
    });
  };

  const handleAdd = async () => {
    try {
      navigate("/staff/addCenter");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  const handleEdit = (id) => async () => {
    try {
      navigate(`/staff/editCenter/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePasswordChange = async (centerId, centerName) => {
    const { value: formValues } = await Swal.fire({
      title: `<span class="text-sm">Change Password for ${centerName}?</span>`,
      html: `
        <input id="newPassword" class="swal2-input border border-gray-300 rounded px-2 py-1 text-sm" type="password" placeholder="New Password" required>
        <input id="confirmPassword" class="swal2-input border border-gray-300 rounded px-2 py-1 text-sm" type="password" placeholder="Confirm Password" required>
      `,
      focusConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Update",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton:
          "bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600",
        cancelButton:
          "bg-gray-300 text-gray-800 rounded px-4 py-2 hover:bg-gray-400",
      },
      width: "400px",
      padding: "20px",
      preConfirm: () => {
        const newPassword = document.getElementById("newPassword").value;
        const confirmPassword =
          document.getElementById("confirmPassword").value;

        if (newPassword !== confirmPassword) {
          Swal.showValidationMessage("Passwords do not match!");
          return false;
        }
        return { newPassword };
      },
    });

    if (formValues) {
      try {
        await changePasswordByAdmin(
          centerId,
          formValues.newPassword,
          "CENTER_MANAGER"
        );
        Swal.fire("Success!", "Password updated successfully!", "success");
      } catch (error) {
        Swal.fire("Error!", "Unable to update the password.", "error");
      }
    }
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const filteredCenters = center.filter((val) => {
    const matchesSearchQuery =
      val.center_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.mobile_number.toString().includes(searchQuery.toString());
    const matchesStatus =
      selectedStatus === "" || val.status === selectedStatus;
    return matchesSearchQuery && matchesStatus;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCenters = filteredCenters.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredCenters.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleView = (id) => {
    navigate(`/staff/viewcenter/${id}`);
  };

  const handlePrintAuthorizationLetter = () => {
    const printIframe = document.createElement("iframe");
    printIframe.style.display = "none";
    document.body.appendChild(printIframe);
    const { center_name, address, coordinator_name, address_line1, address_line2, address_line3, center_code } = selectedCenter;
    const content = `
      <div class="content">
        <h2>AUTHORIZATION LETTER</h2>
        <p>This is to certify that</p>
        <p class="font-bold"><strong>${center_name}, ${address}</strong></p>
        <p class="font-bold"><strong>Co-Ordinator: ${coordinator_name}</strong></p>
        <p>Office Address:</p>
        <p><strong>${address_line1}, ${address_line2}, ${address_line3}</strong></p>
        <p>Appointed as authorized <strong>ADMISSION & EXAMINATION</strong> Center of the <strong>Karnataka State Council of Intermediate and Higher Education, Mysore</strong></p>
        <p>for the Academic Year ${academicYearFrom} - ${academicYearTo}</p>
        <p class="font-bold"><strong>Center Code: ${center_code}</strong></p>
        <div class="signature">
          <p style="text-align: left;">Authorized Signatory</p>
          <p style="text-align: left; margin-top: 80px;">KSCIHE, Mysore</p> <!-- Increased margin-top -->
        </div>
      </div>
    `;
    const printDocument = printIframe.contentWindow.document;
    printDocument.open();
    printDocument.write(`
      <html>
        <head>
          <title>Print Authorization Letter</title>
          <style>
            @media print {
              @page {
                margin: 0;
              }
              body {
                font-family: 'Times New Roman', Times, serif;
                margin: 0;
                padding: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .content {
                width: 100%;
                max-width: 800px;
                padding: 80px; /* Increased padding */
                box-shadow: none;
                border-radius: 0;
              }
              h2 {
                font-size: 28px; /* Slightly larger heading */
                text-decoration: underline;
                font-weight: bold;
                text-align: center;
                margin-bottom: 40px; /* Added margin below the heading */
              }
              p {
                margin: 25px 0; /* Increased margin for spacing */
                font-size: 18px;
                text-align: center; /* Center aligned paragraphs */
              }
              .signature p {
                text-align: left; /* Left-align signature paragraphs */
              }
            }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
              document.body.removeChild(printIframe);
            };
          </script>
        </body>
      </html>
    `);
    printDocument.close();
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Centers</PageTitle>
        <PageSubtitle>View and Manage all Centers</PageSubtitle>
      </PageHeader>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </div>
      ) : center.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No centers to display.</p>
          <AddButton type="button" onClick={handleAdd}>
            Add Center
          </AddButton>
        </div>
      ) : (
        <div>
          <Form>
            <FilterWrapper>
              <FilterLabel>Filter by Status</FilterLabel>
              <FilterSelect
                id="statusFilter"
                onChange={(e) => handleStatusFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </FilterSelect>
            </FilterWrapper>
            <SearchWrapper>
              <SearchInput
                placeholder="Search"
                required
                value={searchQuery}
                onChange={handleSearch}
              />
            </SearchWrapper>
            <AddButton type="button" onClick={handleAdd}>
              Add Center
            </AddButton>
          </Form>

          {filteredCenters.length === 0 ? (
            <div className="text-center">
              <p className="text-gray-700 mb-4">No matching centers found.</p>
            </div>
          ) : (
            <>
              <TableWrapper>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>Sl No</th>
                      <th>Center Name</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Status </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCenters.map((val, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{val.center_name}</td>
                        <td>{val.address}</td>
                        <td>{val.mobile_number}</td>
                        <td>{val.email}</td>
                        <td
                          className={`px-4 py-2 border-b ${val.status === "active"
                              ? "text-green-500"
                              : "text-red-500"
                            } w-1/12`}
                        >
                          {val.status}
                        </td>
                        <td>
                          <div className="flex space-x-2">
                            <button
                              className="relative bg-blue-500 text-white py-1 px-3 rounded"
                              onClick={() => handleEdit(val.id)}
                            >
                              <FontAwesomeIcon icon={faEdit} title="edit" />
                            </button>
                            <button
                              title={
                                val.status === "active" ? "Disable" : "Enable"
                              }
                              onClick={() => handleDisable(val.id, val.status, val.center_name)}
                            >
                              <FontAwesomeIcon
                                icon={faBan}
                                className={`text-lg ${val.status === "active"
                                  ? "text-green-500"
                                  : "text-red-500"
                                  }`}
                              />
                            </button>
                            <button
                              title="Print Authorization Letter"
                              className="relative bg-teal-500 text-white py-1 px-3 rounded"
                              onClick={() => handlePrint(val.id)}
                            >
                              <FontAwesomeIcon icon={faPrint} />
                            </button>
                            <button
                              title="Change Password"
                              className="relative bg-teal-500 text-white py-1 px-3 rounded"
                              onClick={() =>
                                handlePasswordChange(val.id, val.center_name)
                              }
                            >
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </button>
                            <button
                              title="View Center"
                              className="relative bg-purple-500 text-white py-1 px-3 rounded"
                              onClick={() => handleView(val.id)}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              </TableWrapper>
              {totalPages > 1 && (
                <NavWrapper>
                  <NavButton
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </NavButton>
                  <PageIndicator>
                    Page {currentPage} of {totalPages}
                  </PageIndicator>
                  <NavButton
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </NavButton>
                </NavWrapper>
              )}
            </>
          )}
        </div>
      )}

      {isModalOpen && selectedCenter && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl relative print:border-none print:shadow-none print:p-0 print:m-0 print:w-[210mm] print:h-[297mm] print:flex print:items-center print:justify-center min-h-screen flex items-center justify-center">
            <button
              className="absolute top-2 right-2 bg-red-500 text-white px-4 py-2 rounded print:hidden"
              onClick={onClose}
            >
              Close
            </button>

            <div className="text-center w-full print:max-w-none print:w-full">
              <div className="flex justify-between mb-4 print:hidden">
                <div className="w-1/2 mr-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    Academic Year From
                  </label>
                  <input
                    type="number"
                    value={academicYearFrom}
                    onChange={(e) => setAcademicYearFrom(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded"
                    min="2015"
                    max="2030"
                    step="1"
                  />
                </div>
                <div className="w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    Academic Year To
                  </label>
                  <input
                    type="number"
                    value={academicYearTo}
                    onChange={(e) => setAcademicYearTo(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded"
                    min="2015"
                    max="2030"
                    step="1"
                  />
                </div>
              </div>

              <div className="text-center mt-8 mb-8 print:mt-0 print:mb-4">
                <h2 className="font-bold text-lg mb-4 underline">
                  AUTHORIZATION LETTER
                </h2>
                <p>This is to certify that</p>
                <p className="font-bold mt-4">
                  {selectedCenter?.center_name},{selectedCenter?.address_line1},{" "}
                  {selectedCenter?.address_line2}, {selectedCenter?.address_line3}
                </p>

                <p className="mt-4 font-bold">
                  Co-Ordinator: {selectedCenter?.coordinator_name}
                </p>
                <p className="mt-4">Office Address</p>
                <p className="mt-4 font-bold">{selectedCenter?.address}</p>

                <p className="mt-6">
                  Appointed as authorized <strong>ADMISSION & EXAMINATION</strong>{" "}
                  Center of the
                  <strong>
                    {" "}
                    Karnataka State Council of Intermediate and Higher
                    Education, Mysore
                  </strong>
                </p>
                <p>
                  for the Academic Year {academicYearFrom} - {academicYearTo}
                </p>

                <p className="mt-4 font-bold">
                  Center Code: {selectedCenter?.center_code}
                </p>
              </div>

              <div className="text-left mt-16 mb-16 print:mt-24 print:mb-24">
                <p>Authorized Signatory</p>
              </div>

              <div className="text-left mt-4">
                <p>KSCIHE, Mysore</p>
              </div>

              <div className="flex justify-end mt-8 print:hidden">
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded"
                  onClick={handlePrintAuthorizationLetter}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default CenterComponent;