import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { sessions } from "../../Api/admin";
import {
  PageWrapper,
  PageHeader,
  PageIndicator,
  PageSubtitle,
  PageTitle,
  AddButton,
  Form,
  SearchWrapper,
  SearchInput,
  StyledTable,
  TableWrapper,
  NavButton,
  NavWrapper,
} from "../UI/StyledComponents";

const SessionComponent = () => {
  const [session, setSession] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const res = await sessions();
        if (res.data.success) {
          setSession(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSessionData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleEdit = (id) => async () => {
    try {
      navigate(`/admin/editSession/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = async () => {
    try {
      navigate("/admin/addSession");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredSessions = session.filter((val) => {
    const matchesSearchQuery = val.session_month
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return matchesSearchQuery;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSessions = filteredSessions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredSessions.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Sessions</PageTitle>
        <PageSubtitle>View and Manage all sessions</PageSubtitle>
      </PageHeader>

      {session.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No sessions to display.</p>
          <AddButton type="button" onClick={handleAdd}>
            Add Session
          </AddButton>
        </div>
      ) : (
        <div>
          <Form>
            <SearchWrapper>
              <SearchInput
                placeholder="Search by Session Month"
                required
                value={searchQuery}
                onChange={handleSearch}
              />
            </SearchWrapper>
            <AddButton type="button" onClick={handleAdd}>
              Add Session
            </AddButton>
          </Form>

          {filteredSessions.length === 0 ? (
            <div className="text-center">
              <p className="text-gray-700 mb-4">No matching sessions found.</p>
            </div>
          ) : (
            <>
              <TableWrapper>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Session Month</th>
                      <th>Session Year</th>
                      <th>Marks Card Issue Date	</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentSessions
                      .sort(
                        (a, b) => new Date(b.created_at) - new Date(a.created_at)
                      )
                      .map((val, index) => (
                        <tr key={index}>
                          <td>{val.id}</td>
                          <td>{val.session_month}</td>
                          <td>{val.session_year}</td>
                          <td>{formatDate(val.issue_date)}</td>
                          <td>
                            <button onClick={handleEdit(val.id)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </StyledTable>
              </TableWrapper>

              {totalPages > 1 && (
                <NavWrapper>
                  <NavButton
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </NavButton>
                  <PageIndicator>
                    Page {currentPage} of {totalPages}
                  </PageIndicator>
                  <NavButton
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </NavButton>
                </NavWrapper>
              )}
            </>
          )}
        </div>
      )}
    </PageWrapper>
  );
};

export default SessionComponent;
