import Sidebar from "../../../Components/Admin/Sidebar";
import {
    SidebarContainer,
    MainContent,
    Layout,
} from "../../../Components/UI/StyledComponents";
import CertificatePUCComponent from "../../../Components/Admin/CertificatePUCComponent";
import NavComponent from "../../../Components/Admin/NavComponent";

const CertificatePUC = () => {
    return (
        <>
            <NavComponent />
            <Layout>
                <SidebarContainer>
                    <Sidebar />
                </SidebarContainer>
                <MainContent>
                    <CertificatePUCComponent />
                </MainContent>
            </Layout>
        </>
    )
}

export default CertificatePUC