import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminRoute from "./Routes/adminRoute";
import CenterRoute from "./Routes/centerRoute";
import StaffRoute from "./Routes/staffRoute";
import StudentRoute from "./Routes/studentRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/*" element={<StudentRoute />} />
          <Route path="/admin/*" element={<AdminRoute />} />
          <Route path="/staff/*" element={<StaffRoute />} />
          <Route path="/center/*" element={<CenterRoute />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
