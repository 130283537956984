import Sidebar from "../../../Components/Center/Sidebar";
import AddStudentComponent from "../../../Components/Center/AddStudentComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from '../../../Components/Center/NavComponent';

const AddStudent = () => {
  return (
    <>
    <NavComponent/>
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <AddStudentComponent />
      </MainContent>
    </Layout>
    </>
  );
};

export default AddStudent;
