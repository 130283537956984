import React from "react";
import Sidebar from "../../../Components/Student/Sidebar";
import ResultsComponent from "../../../Components/Student/ResultsComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";

const Results = () => {
  return (
    <Layout>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContent>
        <ResultsComponent />
      </MainContent>
    </Layout>
  );
};

export default Results;
