import Sidebar from "../../../Components/Admin/Sidebar";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import ViewCenterComponent from "../../../Components/Admin/ViewCenterComponent";
import NavComponent from "../../../Components/Admin/NavComponent";
const ViewCenter = () => {
  return (
    <>
      <NavComponent />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <ViewCenterComponent />
        </MainContent>
      </Layout>
    </>
  );
};
export default ViewCenter;