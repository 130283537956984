import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchStudent, courses, editStudent } from "../../Api/center";

const EditStudentComponent = () => {
  const [name, setName] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [father_name, setFatherName] = useState("");
  const [mother_name, setMotherName] = useState("");
  const [address, setAddress] = useState("");
  const [course_id, setCourseId] = useState("");
  const [course, setCourses] = useState([]);
  const [admission_status, setAdmissionStatus] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const res = await courses();
        if (res.data.success) {
          setCourses(res.data.data.courses);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourseData();
  }, []);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        if (id) {
          const res = await fetchStudent(id);
          if (res.data.success) {
            setName(res.data.data.student.name);
            setMobileNumber(res.data.data.student.mobile_number);
            setEmail(res.data.data.student.email);
            setFatherName(res.data.data.student.father_name);
            setMotherName(res.data.data.student.mother_name);
            setAddress(res.data.data.student.address);
            setCourseId(res.data.data.student.course_id);
            setAdmissionStatus(res.data.data.student.admission_status);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudentData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const role_name = "STUDENT";
        const res = await editStudent(
          id,
          name,
          email,
          mobile_number,
          father_name,
          mother_name,
          address,
          admission_status,
          role_name,
          course_id
        );
        if (res.data.success) {
          toast.success("Updation successfull...");
          navigate("/center/student");
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Edit Student Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="mobileNumber"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Mobile Number
          </label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={mobile_number}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="fatherName"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Father's Name
          </label>
          <input
            type="text"
            id="fatherName"
            name="fatherName"
            value={father_name}
            onChange={(e) => setFatherName(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="motherName"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Mother's Name
          </label>
          <input
            type="text"
            id="motherName"
            name="motherName"
            value={mother_name}
            onChange={(e) => setMotherName(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Address
          </label>
          <textarea
            id="address"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            rows="2"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="course"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Course
          </label>
          <select
            id="course"
            name="course"
            value={course_id}
            onChange={(e) => setCourseId(parseInt(e.target.value))}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          >
            <option value="">Select Course</option>
            {course.map((val) => (
              <option key={val.id} value={val.id}>
                {val.course_name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="admissionStatus"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Admission Status
          </label>
          <select
            id="admissionStatus"
            name="admissionStatus"
            value={admission_status}
            onChange={(e) => setAdmissionStatus(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          >
            <option value="">Select Status</option>
            <option value="ACCEPTED">ACCEPTED</option>
            <option value="PENDING">PENDING</option>
            <option value="REJECTED">REJECTED</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="role_name"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Role Name
          </label>
          <input
            type="text"
            id="role_name"
            name="role_name"
            value="STUDENT"
            readOnly
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm bg-gray-100 text-gray-600 cursor-not-allowed focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg shadow-md hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EditStudentComponent;
