import React, { useState, useEffect } from "react";
import { getStudents } from "../../Api/center";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PageWrapper,
  Form,
  SearchWrapper,
  SearchInput,
  FilterWrapper,
  FilterLabel,
  FilterSelect,
  AddButton,
  StyledTable,
  NavButton,
  PageIndicator,
  PageHeader,
  PageTitle,
  PageSubtitle,
  TableWrapper,
  NoDataWrapper,
  NavWrapper,
  NoDataText,
} from "../UI/StyledComponents";

const StudentComponent = () => {
  const { centerInfo } = useSelector((state) => state.auth);
  const [openFilter, setOpenFilter] = useState(false);
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [centerId, setCenterId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const decoding = async () => {
      if (centerInfo) {
        const decodedToken = jwtDecode(centerInfo);
        if (decodedToken && decodedToken.id) {
          setCenterId(decodedToken.id);
        }
      }
    };
    decoding();
  }, [centerInfo]);

  useEffect(() => {
    const fetchStudentData = async () => {
      if (centerId) {
        try {
          const res = await getStudents();
          if (res.data.success) {
            const filteredStudents = res.data.data.filter(
              (student) => student.center_id === centerId
            );
            setStudents(filteredStudents);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchStudentData();
  }, [centerId]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setOpenFilter(!openFilter);
    setCurrentPage(1);
  };

  const filteredStudents = students.filter((val) => {
    const matchesSearchQuery =
      val.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.roll_number.toString().includes(searchQuery.toString()) ||
      val.mobile_number.toString().includes(searchQuery.toString());
    const matchesStatus =
      selectedStatus === "" || val.admission_status === selectedStatus;
    return matchesSearchQuery && matchesStatus;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentStudents = filteredStudents.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleAdd = async () => {
    try {
      navigate("/center/addStudent");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Students</PageTitle>
        <PageSubtitle>View all students details</PageSubtitle>
      </PageHeader>
      <Form>
        <FilterWrapper>
          <FilterLabel>Filter by Status</FilterLabel>
          <FilterSelect
            id="statusFilter"
            onChange={(e) => handleStatusFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="ACCEPTED">Accepted</option>
            <option value="REJECTED">Rejected</option>
            <option value="PENDING">Pending</option>
          </FilterSelect>
        </FilterWrapper>
        <SearchWrapper>
          <SearchInput
            type="search"
            id="search-dropdown"
            placeholder="Search"
            required
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchWrapper>
        <AddButton type="button" onClick={handleAdd}>
          Add Student
        </AddButton>
      </Form>
      {students.length === 0 ? (
        <NoDataWrapper>
          <NoDataText>No students to display.</NoDataText>
        </NoDataWrapper>
      ) : currentStudents.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No students found.</p>
        </div>
      ) : (
        <>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th>Roll Number</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>DOB</th>
                  <th>Address</th>
                  <th>Admission status</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {currentStudents
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((val, index) => (
                    <tr key={index}>
                      <td>{val.roll_number ? val.roll_number : "Not assigned"}</td>
                      <td>{val.name}</td>
                      <td>{val.email}</td>
                      <td>{val.mobile_number}</td>
                      <td>{formatDate(val.date_of_birth)}</td>
                      <td>{val.address}</td>
                      <td
                        className={`${
                          val.admission_status === "PENDING"
                            ? "text-blue-500"
                            : val.admission_status === "ACCEPTED"
                            ? "text-green-500"
                            : val.admission_status === "REJECTED"
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        {val.admission_status}
                      </td>
                      {/* <td>
                        <button onClick={handleEdit(val.id)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default StudentComponent;
